import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Home from './pages/home'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import PanoContextContainer from './contexts/PanoContextContainer.js';
import BackgroundSound from './pages/components/backgroundSound';
import Actionbar from './pages/components/actionBar';
import AboutUs from './pages/components/aboutUs';
import PrivacyPolicy from './pages/components/privacyPolicy';
import PageTransition from './pages/components/pageTransition';

function App() {
  const [soundOn, setSoundOn] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const handlePlaySound = () => {
    if (!initialized) {
      setInitialized(true);
    }
    setSoundOn(!soundOn);
  };

  const location = useLocation();
  return ( 
    <>
      <PageTransition>
        <Switch location={location}>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Redirect to="/index" />  
              )
            }}
          />
          <Route exact path="/index" render={() => {
            return (
              <PanoContextContainer>
                <Home />
              </PanoContextContainer>
            )
          }} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
      </PageTransition>
      {/* <BackgroundSound isPlaying={soundOn} /> */}
      {initialized && <BackgroundSound isPlaying={soundOn} />}
      {location.pathname !== "/about-us" && location.pathname !== "/privacy-policy" && (
        <Actionbar handlePlaySound={handlePlaySound} soundOn={soundOn} />
      )}
    </>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
