import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import MovingProgressBar from '../movingProgressBar';
import Lottie from 'react-lottie';
import drag from '../../../assets/lottie/tutorial/help-animation-mouse-drag.json'
import scroll from '../../../assets/lottie/tutorial/help-animation-mouse-scroll.json'
import marker from '../../../assets/lottie/tutorial/help-animation-marker.json'
import hotspot from '../../../assets/lottie/tutorial/help-animation-hotspot.json'
import map from '../../../assets/lottie/tutorial/help-animation-map.json'
import './style.css';

function Tutorial({hideModal}) {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);

  const handleCircleClick = (index) => {
      setProgress((index)*20);
      setStep(index + 1);
  }

  const renderCircles = (index) => {
    let circles = [];
    [0,1,2,3,4].forEach((i) => {
      circles.push(<div key={i} onClick={() => handleCircleClick(i)} className={`tutorial-loader-items ${index === i ? 'filled-loader' : '' }`}></div>);
    });
    return circles;
  }

  const step1 = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="mouse-click-wrap">
        <Lottie className="mouse-click" options={{
            loop: false,
            autoplay: true, 
            animationData: drag,
            rendererSettings:{
              filterSize: {
                width: '200%',
                height: '200%',
                x: '-50%',
                y: '-50%',
              }
            }
        }}
        />
         </div>
        <p className="info-txt"><span className="font-weight-bold">Click & Drag</span> to look around</p>
        <MovingProgressBar now={progress} index={step} key={0}/>
        <div className="loader-wrap">{renderCircles(0)}</div>
      </div>
    );
  }

  const step2 = () => {
    return (
      <div className="d-flex flex-column align-items-center">
       <div className="mouse-click-wrap">
        <Lottie className="mouse-click" options={{
            loop: false,
            autoplay: true, 
            animationData: scroll,
            filterSize: {
              width: '200%',
              height: '200%',
              x: '-50%',
              y: '-50%',
            }
        }}
        />
         </div>
        <p className="info-txt"><span className="font-weight-bold">Scroll</span> to zoom</p>
        <MovingProgressBar now={progress} index={step} key={1}/>
        <div className="loader-wrap">{renderCircles(1)}</div>
      </div>
    );
  }

  const step3 = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="mouse-click-wrap">
        <Lottie className="mouse-click" options={{
            loop: false,
            autoplay: true, 
            animationData: marker,
            filterSize: {
              width: '200%',
              height: '200%',
              x: '-50%',
              y: '-50%',
            }
        }}
        />
         </div>
        <p className="info-txt"><span className="font-weight-bold">Click markers</span> to change locations</p>
        <MovingProgressBar now={progress} index={step} key={2}/>
        <div className="loader-wrap">{renderCircles(2)}</div>
      </div>
    );
  }

  const step4 = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="mouse-click-wrap">
        <Lottie className="mouse-click" options={{
            loop: false,
            autoplay: true, 
            animationData: hotspot,
            filterSize: {
              width: '200%',
              height: '200%',
              x: '-50%',
              y: '-50%',
            }
        }}
        />
         </div>
        <p className="info-txt"><span className="font-weight-bold">Click hotspots</span> to explore deeper</p>
        <MovingProgressBar now={progress} index={step} key={3}/>
        <div className="loader-wrap">{renderCircles(3)}</div>
      </div>
    );
  }

  const step5 = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="mouse-click-wrap">
        <Lottie className="mouse-click" options={{
            loop: true,
            autoplay: true, 
            animationData: map,
            filterSize: {
              width: '200%',
              height: '200%',
              x: '-50%',
              y: '-50%',
            }
        }}
        />
         </div>
        <p className="info-txt"><span className="font-weight-bold">Hover over Locations on the Map</span><br/> to explore use cases</p>
        <MovingProgressBar now={progress} index={step} key={4}/>
        <div className="loader-wrap">{renderCircles(4)}</div>
      </div>
    );
  }

  const renderSteps = () => {
    switch (step) {
      case 1:
        return step1();
      case 2:
        return step2();
      case 3:
        return step3();
      case 4:
        return step4();
      case 5:
        return step5()
      default:
        return step5();
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setStep(step + 1 === 6 ? 5 : step + 1 );
      setProgress(step*20)
    }, 5005);
    return () => clearTimeout(timer);
  }, [step]);

  return (
    <div className="d-flex flex-column align-items-center fadeIn-welcome" id="tutorial">
      {renderSteps()}
      <X size={30} className="mt-3 cursor-pointer" onClick={hideModal}/>
    </div>
  );
}

export default Tutorial;

