import { React, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import ProductModal from "../productModal";
import productImage from "../../assets/images/product-image.png";
import retailExperience from "../../assets/images/retail-experience.png";
import applicationNote from "../../assets/images/application-note.png";
import saleIcon from "../../assets/icons/sale-icon.svg";
import downloadButton from '../../assets/images/download-button.svg'
import closeButton from '../../assets/images/close-button.svg'
import "./style.scss";
let FileSaver = require('file-saver');

function AttatchmentModal(props) {
  const [show, setShow] = useState(!!props.show);
  const handleClose = () => {
    setShow(false);
    props.closeCallback();
  };

const handleCrossClose=()=>{
  setShow(false);
    props.crossCloseCallback();
}

  const handleShow = () => setShow(true);

  /* Helper function */
  const downloadFile = (fileURL, fileName) => {
    FileSaver.saveAs(fileURL,fileName);
  }


  useEffect(()=>{
    var ifr=document.getElementById('forecast_embed');
    if(ifr){
var interval = setInterval(() => {
  ifr.src = "https://docs.google.com/gview?url=" +
  props.attatchment +
  "&embedded=true"
}, 2000);


    var ifr=document.getElementById('forecast_embed');
    ifr.onload=function(){
        console.log('laod the iframe')
        clearInterval(interval)
    }
  }


  },[])




  return (
    <>
      <Modal size="lg" centered className="special-attachment-modal" show={show} onHide={()=>handleClose()}>
        <ModalBody>
          <div className="row">
            <div className="col-12 py-1 background-white">
              <div className="bread-crumb d-flex align-items-center justify-content-between">
                <span className="color-gray mt-2 font-14 ml-2">
                  {props.breadCrumb}
                </span>

                <div>
                  <button
                    onClick={handleCrossClose}
                    type="button"
                    className="close mr-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src={closeButton}/>
                  </button>

                  {!!!props.isVideo && !!!props.isImage && (
                    <button
                        onClick={() => { downloadFile(props.attatchment,"attatchment.pdf") }}
                        type="button"
                        className="close download mr-4"
                        aria-label="Download PDF"
                      >
                        <img src={downloadButton}/>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              {!!props.isVideo && (<>
                <iframe width="100%" className="border-none" height="500"
                  src={props.attatchment}>
                </iframe></>
              )}
              {!!!props.isVideo && !!!props.isImage && (
                <div>
                <iframe
                id="forecast_embed"
                  className="doc iframe"
                  frameBorder="0"
                  src={
                    "https://docs.google.com/gview?url=" +
                    props.attatchment +
                    "&embedded=true"
                  }
                ></iframe>
                </div>
              )}
              {!!!props.isVideo && !!props.isImage && (
                  <img src={props.attatchment} style={{width:"100%"}}/>
              )}

            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AttatchmentModal;
