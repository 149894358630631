import React, { useState, useEffect } from 'react';
import './style.scss'; // Import your styles
import logo from "../../../assets/images/hajjlogo.png"
import PageTransition from '../pageTransition';
import { useHistory, useLocation, Link } from "react-router-dom";
const MenuBar = ({ isOpen, onClose }) => {
    const history = useHistory();
    const location = useLocation();
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose]);

  const handleRedirect = () => {
    history.push("/index");
    window.location.reload();
  };

  return (
    <PageTransition>
    <div className={`menu-bar ${isOpen ? 'open' : ''}`}>
      {/* <button className="close-btn" onClick={onClose}>
        ×
      </button> */}
      <div>
            <img src={logo} className="menu-logo" alt="Hajj app LOGO" />
      </div>
      <div className="menu-divider"></div> 
        <div>
            <nav>
                <Link onClick={handleRedirect} className="dropdown-item">Home</Link>
                <div className="menu-divider"></div>
                <Link to="/about-us" className="dropdown-item">About Us</Link>
                <div className="menu-divider"></div>
                <Link to="/privacy-policy" className="dropdown-item">Policy</Link> 
            </nav>
        </div>
    </div>
    </PageTransition>
  );
};

export default MenuBar;
