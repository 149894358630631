import { React, useState, useEffect,useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ProductModal from "../productModal";
import AttatchmentModal from "../attatchmentModal";
import "./style.scss";
import closeButton from '../../assets/images/continue button.png'
import {TweenMax} from 'gsap'

var width = window.innerWidth

var imageAdjust = ["Apple HomeKit","SmartThings","Amazon Sidewalk","Google Assistant","Ring","Amazon Alexa"]

function SolutionModal(props) {

  const node = useRef();

  const [show, setShow] = useState(!!props.show);
  const [productModal, showProductModal] = useState(undefined);
  const [attatchmentModal, showAttatchmentModal] = useState(undefined);

  const [crousalSlideIndex, setCrousalSlideIndex] = useState(0);
  const [bigScreen, setBigScreen] = useState(true)

  useEffect(()=>{
    function handleResize() {
      if(window.innerWidth < 990){
        setBigScreen(false)
      }
      else{
        setBigScreen(true)
      }
}

window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)   
}
  })


  const handleScroll=(e)=>{
    if (e.target.classList.contains("not-scroll") === true) {
      e.target.classList.remove("not-scroll");
      setTimeout(() => {
        e.target.classList.add("not-scroll");
      }, 1000);
  }
  }

  useEffect(()=>{
    if(window.innerWidth < 990){
      setBigScreen(false)
    }
    else{
      setBigScreen(true)
    }
    
    // var carousal = document.getElementById("carousal")
    // var carousalSecond = document.getElementById("carousalSecond")
    // var carousalThird = document.getElementById("carousalThird")
    // carousal.addEventListener('scroll', handleScroll, true);
    // carousalSecond.addEventListener('scroll', handleScroll, true);
    // carousalThird.addEventListener('scroll', handleScroll, true);
  },[])




  const slidePrevious = () => {
    const lastIndex = props.crousalData.length - 2;
    const resetIndex = crousalSlideIndex === 0;
    if(!resetIndex){
      const index = resetIndex ? lastIndex : crousalSlideIndex - 2;
      hideSlides(()=>{setCrousalSlideIndex(index);},"backward"); 
    }
    
  };
  const slideNext = () => {
    const lastIndex = props.crousalData.length - 2;
    const resetIndex = crousalSlideIndex >= lastIndex;
    
    if(!resetIndex){
      const index = resetIndex ? 0 : crousalSlideIndex + 2;
      hideSlides(()=>{setCrousalSlideIndex(index);},"forward") 
    }
  };

  const closeProductModalCallback = () => {
    handleShow();
    showProductModal(undefined);
  };
  const closeAttatchmentModalCallback = () => {
    handleShow();
    showAttatchmentModal(undefined);
  };

  const handleClose = () => {
    setShow(false);
    props.closeCallback();
  };
  const handleShow = () => setShow(true);

  let first2Slides = props.crousalData.slice(
    crousalSlideIndex,
    crousalSlideIndex + 2
  );


  const hideSlides = (callback,direction) => {
    let elems = document.getElementsByClassName("crousal-slide");
    if(!!elems.length)
    {
      Array.from(elems).forEach((item, index)=>{  
        TweenMax.fromTo(
          item,
          0.15,
          {
            x:direction=="forward"?0:0,
            opacity:1,
          },
          {
            x:direction=="forward"?570:-570,
            opacity:0
          }
        )

      }); 
      window.setTimeout( () => { 
        callback();
        elems = document.getElementsByClassName("crousal-slide");
        Array.from(elems).forEach((item, index)=>{ 
          TweenMax.fromTo(
            item,
            0.15,
            {
              x:direction=="forward"?-570:570,
              opacity:0,
            },
            {
              x:direction=="forward"?0:0,
              opacity:1
            }
          ) 
        });
      }, 150);
    }
  }


  

  

   

  let crousalDots = [];
  for (let i = 0; i < Math.ceil(props.crousalData.length / 2); i++) {
    let indexToCheck =
      crousalSlideIndex == 0 ? crousalSlideIndex : crousalSlideIndex - 1;
      var yellowIndex = 0
    if (i === indexToCheck || indexToCheck===3 && i===2) {
      yellowIndex=i
      crousalDots.push(
        <span
          className="color-yellow m-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
        </span>
      );
    } else {
      crousalDots.push(
        <span
          className="color-white m-1"
          onClick={() => {
            hideSlides(()=>{setCrousalSlideIndex(i == 0 ? 0 : i + 1)},i>yellowIndex?"forward":"backward");  
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          </svg>
        </span>
      );
    }
  }
  //  if (first2Slides.length < 2) {
  //   first2Slides = first2Slides.concat(props.crousalData.slice(0, 2 - first2Slides.length))
  //  } 

  const handleShowContactForm = () => {
    var contactForm = document.querySelector('#lightbox-contact-form');
    contactForm.style.display = 'block';
    handleClose()
  };

  return (
    <>
      <div ref={node}>  
        <Modal size='xl' centered className="special-modal-solution" onHide={()=>handleClose()} show={show} >
        <ModalBody>
          {bigScreen ? <div className="p-3">
            <div className="col-lg-12 productImage" style={{backgroundImage:`url(${props.primaryImage})`,height:imageAdjust.includes(props.infoSpot)?"500px":"501px"}}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <button
                      onClick={handleClose}
                      type="button"
                      className="closes"
                      data-dismiss="modal"
                      // aria-label="Close"
                    >
                      
                        <img src={closeButton} style={{ maxWidth: "200px", justifyContent: "center" }} />
              </button>
            </div>
          </div>
        :
        <div className="row p-3">
            <div className="col-lg-6 minHeight-300-row-1">
            <div className={`productImage ${!bigScreen && 'mt-2'}`} style={{backgroundImage:`url(${props.primaryImage})`,height:imageAdjust.includes(props.infoSpot)?"210px":"211px"}}></div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <button
                    onClick={handleClose}
                    type="button"
                    className="closes"
                    data-dismiss="modal"
                    // aria-label="Close"
                  >
                      <img src={closeButton}/>
                  </button> 
              </div>
            </div>
          </div>  
        
        }
        </ModalBody>
      </Modal>
      </div>
     
    </>
  );
}

export default SolutionModal;
