
import React, { useEffect, useRef, useState,useImperativeHandle,forwardRef } from "react"
// import { useSpring, a } from "@react-spring/three";
import { useGLTF, OrthographicCamera, Html } from 'drei'
import { useThree } from "react-three-fiber"
import lottie from 'lottie-web'
import "./styles.scss"




const model = forwardRef((props, ref) => {
    const group = useRef()
    const { nodes, materials } = useGLTF('Full-Inactive.glb')
    const [hovered, setHovered] = useState({
        Entrance: "#fff",
        Kitchen: "#fff",
        Bathroom: "#fff",
        Office: "#fff",
    })
    const [clicked,setClicked] = useState(false)
    const { viewport } = useThree()
    const [clickOccur,setClickOccur] = useState(false)

    const size = viewport.width > 2000 ? (viewport.width / 400) : (viewport.width / 250)
    const width = viewport.width > 768 ? 0 : viewport.width/300
 const container = {
    containerPin1:useRef(null),
    containerPin2:useRef(null),
    containerPin3:useRef(null),
    containerPin4:useRef(null),
  }

    const timer = ms => new Promise(res => setTimeout(res, ms))


    const closeModal=()=>{
        setHovered({
            Entrance: "#fff",
            Kitchen: "#fff",
            Bathroom: "#fff",
            Office: "#fff",
        })

        group.current.children.map(async(v,k)=>{
            if(v.type=="Mesh" && v.material.name !== "05_Extra-Inactive"){
                if(v.position.y>0){
                    while(v.position.y!=0){
                    v.position.y -= 1  
                    await timer(20)  
                    }
                }
            }
        })
        

        for(var l=0;l<4;l++){
            container[`containerPin${l+1}`].current.style.display="none";
            container[`containerPin${l+1}`].current.style.pointerEvents="none";
        }
        setClicked(false)
        props.closeModal() 
    }

    const change = async (e, s) => {
        if(window.innerWidth>768 && !clicked){
            if (e.object.material.name !== "05_Extra-Inactive") {
                if (s == true) {
                    setHovered({ ...hovered, [e.object.parent.name]: "#0086d9" })
                    document.body.style.cursor = 'pointer'
                }
                else {
                    setHovered({ ...hovered, [e.object.parent.name]: "#fff" })
                    document.body.style.cursor = 'auto'
                }
                for (var i = 0; i < 10; i++) {
                    if (s == true) {
                        e.object.position.y += 0.1
                        await timer(10)
    
                    }
                    else {
                        e.object.position.y -= 0.1  
                        await timer(10)
                    }
                }
            }
        }
    }

    const doAction = async(e,s) => {
        setClicked(true)
            if (e.object.material.name !== "05_Extra-Inactive") {
                if (s == true) {
                    setHovered({
                        Entrance: e.object.parent.name=="Entrance" ? "#0086d9" : "#fff",
                        Kitchen: e.object.parent.name=="Kitchen" ? "#0086d9" : "#fff",
                        Bathroom: e.object.parent.name=="Bathroom" ? "#0086d9" : "#fff",
                        Office: e.object.parent.name=="Office" ? "#0086d9" : "#fff",
                    }) 

                    e.eventObject.children[3].children.map(async(v,i)=>{
                                if(v.type=="Group"){
                                    if(v.children[0].position.y==7.469999999999996 || v.children[0].position.y==9.669999999999996 || v.children[0].position.y==22.150000000000013 || v.children[0].position.y==0.9999999999999999){   
                                    for (var j = 0; j < 10; j++) {
                                        v.children[0].position.y -= 0.1  
                                        await timer(10)
                                        }
                                    }
                                }
                            })

                           
                    Object.keys(hovered).map(async(v,i)=>{
                        if(v==e.object.parent.name){
                            container[`containerPin${i+1}`].current.style.display="block";
                            container[`containerPin${i+1}`].current.style.pointerEvents="all";
                                for (var j = 0; j < 10; j++) {
                                    e.object.position.y += 0.1  
                                    await timer(10)
                                    }
                                }
                        else{
                            container[`containerPin${i+1}`].current.style.display="none";
                            container[`containerPin${i+1}`].current.style.pointerEvents="none";
                        }
                       
                    })
                }
            }


    }


    const changeScene=(i)=>{
        
        setHovered({
            Entrance: "#fff",
            Kitchen: "#fff",
            Bathroom: "#fff",
            Office: "#fff",
        })

        group.current.children[3].children.map(async(v,k)=>{
            if(v.type=="Group"){
                if(v.children[0].position.y==7.469999999999996 || v.children[0].position.y==9.669999999999996 || v.children[0].position.y==22.150000000000013 || v.children[0].position.y==0.9999999999999999){   
                    for (var j = 0; j < 10; j++) {
                        v.children[0].position.y -= 0.1  
                        await timer(10)
                        }
                }
            }
        })
        
        for(var j=0;j<4;j++){
            container[`containerPin${j+1}`].current.style.display="none";
            container[`containerPin${j+1}`].current.style.pointerEvents="none";
        }
        setClicked(false)
        props.modelClick(i)
    }

    
    const openInfoSpot=(i,j)=>{
        
        setHovered({
            Entrance: "#fff",
            Kitchen: "#fff",
            Bathroom: "#fff",
            Office: "#fff",
        })

        group.current.children[3].children.map(async(v,k)=>{
            if(v.type=="Group"){
                if(v.children[0].position.y==7.469999999999996 || v.children[0].position.y==9.669999999999996 || v.children[0].position.y==22.150000000000013 || v.children[0].position.y==0.9999999999999999){   
                    for (var j = 0; j < 10; j++) {
                        v.children[0].position.y -= 0.1 
                        await timer(10)
                        }
                }
            }
        })
        

        for(var l=0;l<4;l++){
            container[`containerPin${l+1}`].current.style.display="none";
            container[`containerPin${l+1}`].current.style.pointerEvents="none";
        }
        setClicked(false)
        props.closeModal()
        props.openInfoSpot(i,j)
    }



    useImperativeHandle(
        ref,
        () => ({
            closeModal(){
                setHovered({
            Entrance: "#fff",
            Kitchen: "#fff",
            Bathroom: "#fff",
            Office: "#fff",
                })
        
                group.current.children[3].children.map(async(v,k)=>{
                    if(v.type=="Group"){
                        if(v.children[0].position.y==7.469999999999996 || v.children[0].position.y==9.669999999999996 || v.children[0].position.y==22.150000000000013 || v.children[0].position.y==0.9999999999999999){   
                            for (var j = 0; j < 10; j++) {
                                v.children[0].position.y -= 0.1   
                                await timer(10)
                                }
                        }
                    }
                })
                
        
                for(var l=0;l<4;l++){
                    container[`containerPin${l+1}`].current.style.display="none";
                    container[`containerPin${l+1}`].current.style.pointerEvents="none";
                }
                setClicked(false)  
            }
        })
    )




    return (
        <group ref={group} {...props} dispose={null}
             onPointerOver={(e) => { e.stopPropagation();change(e, true) }}
            onPointerOut={(e) => { e.stopPropagation();change(e, false) }}
            onPointerDown={(e) => {e.stopPropagation();doAction(e,true)}}
        >
        <group position={[-75.83, 179.41, 125.96]} rotation={[0.32, 0.68, 0.46]}>
          <directionalLight intensity={1} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
        </group>
        <group position={[-31.01, 154.03, 53.49]} rotation={[0.06, 0.8, 0.19]}>
          <directionalLight intensity={3} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
        </group>
        <group position={[-1000.15, 500.63, 600.48]} rotation={[1.38, -0.33, 1.03]}>
          <OrthographicCamera makeDefault={true} far={2500} near={0.1} rotation={[-Math.PI / 2, 0, 0]} />
        </group>
        <group scale={[size, size, size]} position={[60,0,0]}>
<group name="Entrance">
        <mesh
          geometry={nodes.Entrance.geometry}
          material={materials['01_Entrance_Inactive']}
          position={[-16.91, 8.67, 35.54]}
          material-color={hovered.Entrance}
        >
            <Html className="containerPin1">
                <div className="containerHover" ref={container.containerPin1}>
                        <span className="map-head-text" onClick={()=>changeScene(0)}>Home Security</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(2,0)}>Motion Sensor</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(1,0)}>Doorbell Camera</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(0,0)}>Door Lock</span><br/>
                    </div>
                </Html>
        </mesh>
        </group>
        
        <group name="Kitchen">
        <mesh
          geometry={nodes.Kitchen.geometry}
          material={materials['02_Kitchen_Inactive']}
          position={[24.67, 6.47, -31.88]}
          rotation={[0, -Math.PI / 2, 0]}
          material-color={hovered.Kitchen}
        >
            <Html className="containerPin2">
                <div className="containerHover" ref={container.containerPin2}>
                        <span className="map-head-text" onClick={()=>changeScene(1)}>Home Automation</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(3,1)}>LED Bulb</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(6,1)}>Plug</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(5,1)}>Switch</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(4,1)}>Climate Control / HVAC</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(1,1)}>Shades & Blinds</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(0,1)}>Smart Speaker</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(2,1)}>Smoke Sensor</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,1)}>Refrigerator</span>
                    </div>
                </Html>
        </mesh>
        </group>

        <group position={[24.93, 18.27, 9.04]} name="Bathroom">
        <mesh 
        geometry={nodes.Cube026.geometry} 
        material={materials['03_Bathroom-Inactive']}  
        material-color={hovered.Bathroom}>
            <Html className="containerPin3">
                <div className="containerHover" ref={container.containerPin3}>
                        <span className="map-head-text" onClick={()=>changeScene(3)}>Health</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(0,3)}>Smart Toothbrush</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(2,3)}>Blood Glucose Monitoring</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(1,3)}>Insulin Pump</span><br/>
                    </div>
                </Html>
            </mesh>
      </group>

        <group name="Office">
        <mesh
          geometry={nodes.Office.geometry}
          material={materials['04_Office-Inactive']}
          position={[-5.27, 21.15, 35.64]}
          rotation={[Math.PI, 0, 0]}
          material-color={hovered.Office} 
        >
            <Html className="containerPin4">
                <div className="containerHover2" ref={container.containerPin4}>
                <span className="map-head-text" onClick={()=>changeScene(2)}>Protocols & Ecosystems</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(0,2)}>Bluetooth</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(1,2)}>Thread</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(2,2)}>Wi-Fi</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(3,2)}>Zigbee</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(4,2)}>Z-Wave</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(5,2)}>Matter</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(6,2)}>Apple HomeKit</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,2)}>SmartThings</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,2)}>Amazon Sidewalk</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,2)}>Google Assistant</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,2)}>Ring</span><br/>
                        <span className="map-sub-text" onClick={()=>openInfoSpot(7,2)}>Amazon Alexa</span><br/>                 
                    </div>
                </Html>
            </mesh>
        </group>
        <mesh
          geometry={nodes.Extra.geometry}
          material={materials['05_Extra-Inactive']}
          position={[-76.32, 7.42, -15.1]}
          rotation={[Math.PI, 1.57, 0]}
        />
             </group>
      </group>
        // <group ref={group} {...props} dispose={null}
        //     onPointerOver={(e) => { e.stopPropagation();change(e, true) }}
        //     onPointerOut={(e) => { e.stopPropagation();change(e, false) }}
        //     onPointerDown={(e) => {e.stopPropagation();doAction(e,true)}}
        //     scale={[0.4, 0.4, 0.4]}
        // >
        //     <group position={[-320, 291.54, 409.38]} rotation={[1.2, -0.33, 0.7]}>
        //         <OrthographicCamera makeDefault={true} far={2000} near={0.3} rotation={[-Math.PI / 2, 0, 0]} />
        //     </group>
        //     <group position={[-12.65, 42.87, 100.53]} rotation={[0.87, 0.89, 0.05]}>
        //         <directionalLight intensity={2} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
        //     </group>
        //     <group position={[-12.65, 15.53, 121.43]} rotation={[1.08, 0.92, 0.4]}>
        //         <directionalLight intensity={2} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
        //     </group>
        //     <mesh name="1" position={[width*40,0,0]} scale={[size, size, size]} material={materials.Entrance} geometry={nodes.Entrance.geometry} rotation={[Math.PI / 2, 0, 0]} material-color={hovered.Entrance}>
        //         <Html className="containerPin1">
        //             <div className="containerHover" ref={container.containerPin1}>
        //                 <span className="map-head-text" onClick={()=>changeScene(0)}>Store Entrance</span>
        //             </div>
        //         </Html>
        //     </mesh>
        //     <mesh name="2" position={[width*40,0,0]} scale={[size, size, size]} material={materials.Produce} geometry={nodes.Produce.geometry} rotation={[Math.PI / 2, 0, 0]} material-color={hovered.Produce}>
        //         <Html className="containerPin2">
        //         <div className="containerHover2" ref={container.containerPin2}>
        //                 <span className="map-head-text" onClick={()=>changeScene(1)}>Inventory Management</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(0,1)}>Electronic Shelf Labels</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(1,1)}>Loss Prevention</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(2,1)}>Location Services</span>
        //             </div>
        //         </Html>
        //     </mesh>
        //     <mesh name="3" position={[width*40,0,0]} scale={[size, size, size]} material={materials.Electronics} geometry={nodes.Electronics.geometry} rotation={[Math.PI / 2, 0, 0]} material-color={hovered.Electronics}>
        //     <Html className="containerPin3">
        //     <div className="containerPin3">
        //     <div className="containerHover2" ref={container.containerPin3}>
        //                 <span className="map-head-text" onClick={()=>changeScene(3)}>People Tracking</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(0,3)}>Commercial Lightning</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(1,3)}>Wireless Access Points</span>
        //             </div>
        //             </div>
        //         </Html>
        //     </mesh>
        //     <mesh name="4" position={[width*40,0,0]} scale={[size, size, size]} material={materials.Packaged} geometry={nodes.Packaged.geometry} rotation={[Math.PI / 2, 0, 0]} material-color={hovered.Packaged}>
        //     <Html className="containerPin4">
        //             <div className="containerPin4">
        //             <div className="containerHover2" ref={container.containerPin4}>
        //                 <span className="map-head-text" onClick={()=>changeScene(2)}>In-Store Utilities</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(0,2)}>Commercial Lightning</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(1,2)}>Location Services</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(2,2)}>Wireless Access Points</span>
        //             </div>
        //             </div>
        //         </Html>
        //     </mesh>
        //     <mesh name="6" position={[width*40,0,0]} scale={[size, size, size]} material={materials.MergeBaked} geometry={nodes.Path2.geometry} rotation={[Math.PI / 2, 0, 0]} />
        //     <mesh name="6" position={[width*40,0,0]} scale={[size, size, size]} material={materials.MergeBaked} geometry={nodes.Path1.geometry} rotation={[Math.PI / 2, 0, 0]} />
        //     <mesh name="5" position={[width*40,0,0]} scale={[size, size, size]} material={materials.Clothing} geometry={nodes.Clothing.geometry} rotation={[Math.PI / 2, 0, 0]} material-color={hovered.Clothing}>
        //     <Html className="containerPin5">
        //             <div className="containerPin5">
        //             <div className="containerHover2" ref={container.containerPin5}>
        //                 <span className="map-head-text" onClick={()=>changeScene(4)}>Store Security</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(0,4)}>Wireless Access Points</span><br/>
        //                 <span className="map-sub-text" onClick={()=>openInfoSpot(1,4)}>Loss Prevention</span>
        //             </div>
        //             </div>
        //         </Html>
        //     </mesh>
        // </group>
    )
})

export default model