import { React, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import AttatchmentModal from "../attatchmentModal";
import closeButton from '../../assets/images/icons-close.svg'
import "./style.scss";

function ProductModal(props) {
  const [show, setShow] = useState(!!props.show);
  const [attatchmentModal, showAttatchmentModal] = useState(undefined);
  const [detailAdjust, setDetailAdjust] = useState(3)
  const [bigScreen, setBigScreen] = useState(true)
  const modalRef = useRef(null)
  const handleClose = () => {
    setShow(false);
    props.closeCallback();
  };

  const handleShow = () => setShow(true);

  const handleCrossClose = () => {
    setShow(false);
    props.closeCrossCallback();
  }

  const closeAttatchmentModalCallback = () => {
    handleShow();
    showAttatchmentModal(undefined);
  }

  useEffect(() => {
    props.productDetail.length == 1 && setDetailAdjust(12)
    props.productDetail.length == 2 && setDetailAdjust(6)
    props.productDetail.length == 3 && setDetailAdjust(4)
    var modal = document.querySelector(".special-modal-product .modal-dialog")

    if (props.productDetail.length == 1) {
      modal.style.maxWidth = "382px"
    }
    else if (props.productDetail.length == 2) {
      modal.style.maxWidth = "772px"
    }
    else if (props.productDetail.length == 3) {
      modal.style.maxWidth = "1158px"
    }
    if (window.innerWidth < 768) {
      setBigScreen(false)
    }
    else {
      setBigScreen(true)
    }



  })

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setBigScreen(false)
      }
      else {
        setBigScreen(true)
      }
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })




  return (
    <>
      <Modal ref={modalRef} centered className="special-modal-product" show={show} onHide={() => handleClose()}>
        <ModalBody>

          <div className="row mt-1">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-2">
                <div>
                  <span className="breadcrumb-text ml-3 font-14">
                    {props.productDetail[0].breadCrum}
                  </span>
                </div>
                <button
                  onClick={handleCrossClose}
                  type="button"
                  className="close mr-2"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={closeButton} className="closes-product" />
                </button>
              </div>
              <hr className="hr mb-0"></hr>
            </div>



            {/* {bigScreen ? (<div></div>) : } */}

            {bigScreen ? <div>

              <div>
                <div className="row">
                  {
                    props.productDetail.map((v, i) => {
                      return (
                        <div className={`col-${detailAdjust} b-right-black`} key={i}>
                          <div className="margin-4">
                            <div>
                              {/* <div className="w-100 text-center">
                          <img className="productImageLink" src={props.productDetail[i].productImageLink} />
                        </div> */}
                              <div className="product-tagline">
                                <p>{props.productDetail[i].productStatement}</p>
                              </div>
                              <p className="color-blue mt-3 product-title">
                                {props.productDetail[i].title}
                              </p>
                              <p className="product-description">
                                {props.productDetail[i].description}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <div className="row">
                {
                  props.productDetail.map((v, i) => {
                    return (
                      <div className={`col-${detailAdjust} b-right-black`} key={i}>
                        <div className="margin-4">
                          <p className="key-features-heading">
                            Key Features:
                          </p>
                          <span className="product-description">{props.productDetail[i].keyFeatures}</span>
                          <p className="d-flex justify-content-center mt-4"><a href={props.productDetail[i].productPageLink} target="_blank" className="product-link color-blue">
                            <span>
                              View Product Details
                            </span>
                          </a></p>

                        </div>
                      </div>
                    )
                  })
                }
              </div>

              <div className="row margin-style">
                {
                  props.productDetail.map((v, i) => {
                    return (
                      <div className={`col-${detailAdjust} bg-gray b-right`}>
                        <div className="m-lr-13 my-3">
                          {/* <div className="w-100 text-center">
                    <img className="linkToThumbnail" src={props.productDetail[i].linkToThumbnail} />
                </div> */}
                          <p className="material-type">{props.productDetail[i].additionalMaterialType}</p>
                          <p className="video-title mb-0">
                            {props.productDetail[i].additionalMaterialTitle}
                          </p>
                          <br />
                          <div>
                            {
                              (props.productDetail[i].isPdf || props.productDetail[i].isLink) ?
<a className="color-white mb-0 watch-now" href={props.productDetail[i].linkToAsset} target="_blank">
                              <span id="cta-sol" className="mr-1 product-cta" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children}>{props.productDetail[i].cta}</span>
                              <svg id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fill-rule="evenodd">
                                  <g>
                                    <g>
                                      <g>
                                        <g transform="translate(-231 -542) translate(143 74) translate(12 337) translate(16 128)">
                                          <g>
                                            <g fill="#fff" fillRule="nonzero">
                                              <path d="M11.823 3.972L8.011.16c-.214-.207-.554-.204-.764.006-.21.21-.213.55-.007.764l2.883 2.883H.545c-.301 0-.545.244-.545.544 0 .301.244.545.545.545h9.578L7.24 7.785c-.141.137-.198.34-.148.53.05.19.198.339.389.389.19.05.393-.007.53-.149l3.812-3.812c.213-.213.213-.558 0-.77z" transform="translate(60 3) translate(0 1.5)" />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                            :

                              <p className="color-white mb-0 watch-now"
                              onClick={() => {
                                let link = props.productDetail[i].linkToAsset;
                                if (props.productDetail[i].isPdf || props.productDetail[i].isLink) {
                                  window.open(link, '_blank');
                                }
                                else {
                                  setShow(false);
                                  showAttatchmentModal(<AttatchmentModal show={true} isImage={props.productDetail[i].isImage} breadCrumb={props.productDetail[i].breadCrum} isVideo={props.productDetail[i].isVideo} attatchment={link} closeCallback={handleClose} crossCloseCallback={closeAttatchmentModalCallback} />);
                                }
                              }}>
                              <span id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} className="mr-1 product-cta">{props.productDetail[i].cta}</span>
                              <svg data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} id="cta-sol" onClick={() => {
                                let link = props.productDetail[i].linkToAsset;
                                if (props.productDetail[i].isPdf || props.productDetail[i].isLink) {
                                  window.open(link, '_blank');
                                }
                                else {
                                  setShow(false);
                                  showAttatchmentModal(<AttatchmentModal show={true} isImage={props.productDetail[i].isImage} breadCrumb={props.productDetail[i].breadCrum} isVideo={props.productDetail[i].isVideo} attatchment={link} closeCallback={handleClose} crossCloseCallback={closeAttatchmentModalCallback} />);
                                }
                              }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fill-rule="evenodd">
                                  <g>
                                    <g>
                                      <g>
                                        <g transform="translate(-231 -542) translate(143 74) translate(12 337) translate(16 128)">
                                          <g>
                                            <g fill="#fff" fillRule="nonzero">
                                              <path d="M11.823 3.972L8.011.16c-.214-.207-.554-.204-.764.006-.21.21-.213.55-.007.764l2.883 2.883H.545c-.301 0-.545.244-.545.544 0 .301.244.545.545.545h9.578L7.24 7.785c-.141.137-.198.34-.148.53.05.19.198.339.389.389.19.05.393-.007.53-.149l3.812-3.812c.213-.213.213-.558 0-.77z" transform="translate(60 3) translate(0 1.5)" />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </p>}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
              :
              <div>
                {
                  props.productDetail.map((v, i) => {
                    return (
                      <div>
                        <div className="margin-4">
                          <div>
                          {/* <div className="product-tagline">
                                <div>
                                <p>{props.productDetail[i].productStatement}</p>
                                </div>
                              </div> */}
                            <p className="color-blue mt-3 product-title">
                              {props.productDetail[i].title}
                            </p>
                            <p className="product-description">
                              {props.productDetail[i].description}
                            </p>
                          </div>
                        </div>

                        <div className="margin-4">
                          <p className="key-features-heading">
                            Key Features:
                          </p>
                          <span className="product-description">{props.productDetail[i].keyFeatures}</span>
                          <p className="d-flex justify-content-center mt-4"><a href={props.productDetail[i].productPageLink} target="_blank" className="product-link color-blue">
                            <span>
                              View Product Details
                            </span>
                          </a></p>

                        </div>

                        <div className="bg-gray py-3">
                          <div className="m-lr-13 mt-3">

                            {/* <div className="w-100 text-center">
<img className="linkToThumbnail" src={props.productDetail[i].linkToThumbnail} />
</div> */}

                            <p className="material-type">{props.productDetail[i].additionalMaterialType}</p>
                            <p className="video-title mb-0">
                              {props.productDetail[i].additionalMaterialTitle}
                            </p>
                            <br />
                            <div>
                            {
                              (props.productDetail[i].isPdf || props.productDetail[i].isLink) ?
<a className="color-white mb-0 watch-now" target="_blank" href={props.productDetail[i].linkToAsset}>
                              <span id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} className="mr-1 product-cta">{props.productDetail[i].cta}</span>
                              <svg id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fill-rule="evenodd">
                                  <g>
                                    <g>
                                      <g>
                                        <g transform="translate(-231 -542) translate(143 74) translate(12 337) translate(16 128)">
                                          <g>
                                            <g fill="#fff" fillRule="nonzero">
                                              <path d="M11.823 3.972L8.011.16c-.214-.207-.554-.204-.764.006-.21.21-.213.55-.007.764l2.883 2.883H.545c-.301 0-.545.244-.545.544 0 .301.244.545.545.545h9.578L7.24 7.785c-.141.137-.198.34-.148.53.05.19.198.339.389.389.19.05.393-.007.53-.149l3.812-3.812c.213-.213.213-.558 0-.77z" transform="translate(60 3) translate(0 1.5)" />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                            :

                              <p className="color-white mb-0 watch-now"
                              onClick={() => {
                                let link = props.productDetail[i].linkToAsset;
                                if (props.productDetail[i].isPdf || props.productDetail[i].isLink) {
                                  window.open(link, '_blank');
                                }
                                else {
                                  setShow(false);
                                  showAttatchmentModal(<AttatchmentModal show={true} isImage={props.productDetail[i].isImage} breadCrumb={props.productDetail[i].breadCrum} isVideo={props.productDetail[i].isVideo} attatchment={link} closeCallback={handleClose} crossCloseCallback={closeAttatchmentModalCallback} />);
                                }
                              }}>
                              <span id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} className="mr-1 product-cta">{props.productDetail[i].cta}</span>
                              <svg id="cta-sol" data-title={props.productDetail[i].breadCrum} data-carousal-title={props.productDetail[i].additionalMaterialTitle.props.children} onClick={() => {
                                let link = props.productDetail[i].linkToAsset;
                                if (props.productDetail[i].isPdf || props.productDetail[i].isLink) {
                                  window.open(link, '_blank');
                                }
                                else {
                                  setShow(false);
                                  showAttatchmentModal(<AttatchmentModal show={true} isImage={props.productDetail[i].isImage} breadCrumb={props.productDetail[i].breadCrum} isVideo={props.productDetail[i].isVideo} attatchment={link} closeCallback={handleClose} crossCloseCallback={closeAttatchmentModalCallback} />);
                                }
                              }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                <g fill="none" fill-rule="evenodd">
                                  <g>
                                    <g>
                                      <g>
                                        <g transform="translate(-231 -542) translate(143 74) translate(12 337) translate(16 128)">
                                          <g>
                                            <g fill="#fff" fillRule="nonzero">
                                              <path d="M11.823 3.972L8.011.16c-.214-.207-.554-.204-.764.006-.21.21-.213.55-.007.764l2.883 2.883H.545c-.301 0-.545.244-.545.544 0 .301.244.545.545.545h9.578L7.24 7.785c-.141.137-.198.34-.148.53.05.19.198.339.389.389.19.05.393-.007.53-.149l3.812-3.812c.213-.213.213-.558 0-.77z" transform="translate(60 3) translate(0 1.5)" />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </p>}
                            </div>
                          </div>
                        </div>

                      </div>
                    )
                  })
                }
              </div>

            }
          </div>
        </ModalBody>
      </Modal>
      {attatchmentModal}
    </>
  );
}

export default ProductModal;
