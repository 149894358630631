import "./styles.scss"
import mapCross from '../../assets/images/map-cross.svg';
import close from '../../assets/images/filter-icons-close.svg'
import primaryDoorbell from '../../assets/images/Modal Images/Solution Modal/doorbell/hero.webp'
import primaryDoorlock from '../../assets/images/Modal Images/Solution Modal/doorlock/hero.webp'
import { useEffect, useState } from "react";
import panoViews from '../constants/panoViews'


var applications = [
    { image: primaryDoorbell, name: "Doorbell Camera" },
    { image: primaryDoorlock, name: "Door Lock" },
    { image: primaryDoorlock, name: "Door Lock" },
    { image: primaryDoorlock, name: "Door Lock" }
]

var products = [
    { tagline: "Sub-GHz SiP Modules with Z-Wave Long-Range capabilities", title: "ZGM130S Z-Wave 700 Series SiP Module" },
    { tagline: "Low-Energy Zigbee, Thread, & Bluetooth SoC all in one device", title: "Zigbee & Thread EFR32MG13 SoC" },
    { tagline: "Robust RF module that connects to Bluetooth LE & Bluetooth mesh", title: "EFR32BGM22 Series 2 Modules" },
    { tagline: "Certified Wi-Fi Modules Developed for Smart Home Applications", title: "WGM160 Series 1 Wi-Fi Module" },
    { tagline: "Robust RF module that connects to Bluetooth LE & Bluetooth mesh", title: "EFR32BGM22 Series 2 Modules" },
    { tagline: "Certified Wi-Fi Modules Developed for Smart Home Applications", title: "WGM160 Series 1 Wi-Fi Module" },
    { tagline: "Robust RF module that connects to Bluetooth LE & Bluetooth mesh", title: "EFR32BGM22 Series 2 Modules" },
    { tagline: "Certified Wi-Fi Modules Developed for Smart Home Applications", title: "WGM160 Series 1 Wi-Fi Module" },
]


const items = [
    {name:"alexa",title:"Amazon Alexa"},
    {name:"assistant",title:"Google Assistant"},
    {name:"homekit",title:"Apple HomeKit"},
    {name:"smartthings",title:"SmartThings"},
    {name:"sidewalk",title:"Amazon Sidewalk"},
    {name:"ring",title:"Ring"},
    {name:"zigbee",title:"Zigbee"},
    {name:"thread",title:"Thread"},
    {name:"zwave",title:"Z-Wave"},
    {name:"bluetooth",title:"Bluetooth"},
    {name:"wifi",title:"Wi-Fi"},
    {name:"matter",title:"Matter"},]

export default function FilterResults(props) {
const [selected,setSelected] = useState(props.selected)
const [solutionModalResults,setSolutionModalResults] = useState([])
const [productModalResults,setProductModalResults] = useState([])
const [query,setQuery] = useState([])

useEffect(()=>{
var querys = []
var names = []
var solutionModalResult = []
var productModalResult = []
items.map((v, i) => {
    if(selected[v.name]){
        querys.push(v)
        names.push(v.title)
    }
})
setQuery(querys)

panoViews.map((views,i)=>{
views.infoSpots.map((modals,j)=>{
    if(findCommonElements3(modals.solutionModelData.filterLabels,names)){
        modals.solutionModelData.location = [i,j]
        solutionModalResult.push(modals.solutionModelData)   
    }
    if(modals.solutionModelData.productDetail!=="CONTACT SALES"){
    modals.solutionModelData.productDetail.map((details)=>{
        if(findCommonElements3(details.filterLabels,names)){
            details.location = [i,j]
            productModalResult.push(details)
        }
    })
}
})
})
setSolutionModalResults(solutionModalResult)
setProductModalResults(productModalResult)


},[selected])


function openSolutionModal(location){
    props.openInfoSpot(location[1],location[0])
    props.closeFilterResults()
}

function openProductModal(location){
    props.openProductModal(location[1],location[0])
    props.closeFilterResults()
}


function findCommonElements3(arr1, arr2) {
    return arr1.some(item => arr2.includes(item))
}




    return (
        <div className="filter-results" id="filterResults">
            <div className="main-container">
            <img src={mapCross} className="close-icon cursor-pointer" onClick={props.closeFilterResults} />
            <div className="container-div">
                <header className="top-part">
                <div className="header container">
                    <span className="results-heading">Filter Results</span><br />
                    <span className="results-subheading">{solutionModalResults.length+productModalResults.length} Results Returned</span>
                </div>
                <div className="d-flex container justify-content-center mt-3">
                    <div className="row">
                        {query.map((v, i) => {
                            return (
                                <div className="selected-items" key={i}>
                                    <span>{v.title}</span>
                                    <img className="ml-2 cursor-pointer" src={close} onClick={()=>{setSelected({...selected,[v.name]:false})}}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                </header>

                <div className="results">
                    <div className="container">
                    <div className="applications">
                        <p className="applications-heading">APPLICATIONS</p>
                        <div className="row card-container">
                            {solutionModalResults.map((v, i) => {
                                return (
                                    <div className="application-card cursor-pointer" key={i} onClick={()=>openSolutionModal(v.location)}>
                                        <img src={v.primaryImage} className="card-pic" />
                                        <p className="card-text">{v.infoSpot}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="products">
                        <p className="products-heading">PRODUCTS</p>
                        <div className="row card-container">
                            {productModalResults.map((v, i) => {
                                return (
                                    <div className="products-card cursor-pointer" key={i} onClick={()=>openProductModal(v.location)}>
                                       <div className="card-tagline">
                                           <span>{v.productStatement}</span>
                                       </div>
                                        <p className="card-title">{v.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    </div>
                </div>

            </div>
            </div>
    
        </div>
    )
}