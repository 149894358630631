import React, { useEffect, useState, useContext,forwardRef,useImperativeHandle } from "react";
import * as THREE from "three";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import CameraControls from 'camera-controls';
import loadCachedImage from "../../imageCache.js";
import { PanoContext } from "../../contexts/PanoContextContainer";

import panoViews from "../constants/panoViews";
import locationSVG from "../../assets/svg/location.svg";
import tagPNG from '../../assets/images/tag.svg'
import navArrowUpWhite from "../../assets/images/arrows/icons-arrow-up.svg";
import navArrowDownWhite from "../../assets/images/arrows/icons-arrow-down.svg";
import navArrowLeftWhite from "../../assets/images/arrows/icons-arrow-left.svg";
import navArrowRightWhite from "../../assets/images/arrows/icons-arrow-right.svg";
// import navArrowUpYellow from "../../assets/images/nav_arrow_up_yellow.svg";
// import navArrowLeftYellow from "../../assets/images/nav_arrow_left_yellow.svg";
// import navArrowRightYellow from "../../assets/images/nav_arrow_right_yellow.svg";

import SolutionModal from "../solutionModal";
import ProductModal from "../productModal";

import mapIconWhite from '../../assets/svg/map-icon-white.svg'
import mapIconYellow from '../../assets/svg/map-icon-yellow1.svg'
import mapCircle from '../../assets/svg/map-icon-line-white.svg'

import infoInnerWhite from '../../assets/svg/hotspot-inner-white.svg'
import infoOuterWhite from '../../assets/svg/hotspot-outer-white.svg'
import infoInnerYellow from '../../assets/svg/hotspot-inner-yellow.svg'

import "./style.css";

import {TweenMax,Power3,TimelineMax} from 'gsap'

import {useHistory} from 'react-router-dom'

THREE.Cache.enabled = true;
const frustumSize = 500;
const geometry = new THREE.SphereGeometry(500, 60, 40);

let camera, scene, renderer;
let scene2, renderer2, controls, clock, cameraControls;
const target = new THREE.Vector3();


let onPointerDownMouseX = 0,
onPointerDownMouseY = 0,
lon = 0,
onPointerDownLon = 0,
lat = 0,
onPointerDownLat = 0,
phi = 0,
theta = 0,
moveCamera = "";
let infoPositions=[]
let container;
let nre;
let temp;

const PanoView = forwardRef((props, ref) => {
  const [selectedView, setSelectedView] = useState(undefined);
  const [solutionModal, showSolutionModal] = useState(undefined);
  const [productModal, showProductModal] = useState(undefined);
  const {setShowLoader, selected } = useContext(PanoContext);
  const [bigScreen, setBigScreen] = useState(true)
  const [infoSpotPositions,setInfoSpotPositions] = useState([])
  const [leftNumber,setLeftNumber] = useState(0)
  const [rightNumber,setRightNumber] = useState(0)
  const [cookiePopup,setCookiePopup] = useState(false)

  const history = useHistory()


  const acceptCookies=()=>{
    setCookiePopup(false)
}

function changeScene(i){
  console.log(i)
  setSelectedView(panoViews[i]);
  setTimeout(() => {
    while (scene.children.length-1)
    {
        scene.remove(scene.children[0]);
    }
}, 100);
}

function openInfoSpot(i,j){
  setTimeout(() => {
    let info = panoViews[j].infoSpots[i]
    let modal = (
      <SolutionModal
        show={true}
        closeCallback={closeSolutionModalCallback}
        breadCrum={info.solutionModelData.breadCrum}
        title={info.solutionModelData.title}
        primaryContent={info.solutionModelData.primaryContent}
        productHeadline={info.solutionModelData.productHeadline}
        primaryImage={info.solutionModelData.primaryImage}
        crousalData={info.solutionModelData.crousalData}
        productDetail={info.solutionModelData.productDetail}
        carousalHeadline={info.solutionModelData.carousalHeadline}
        icon={info.solutionModelData.icon}
        changeScene={changeScene}
        openInfoSpot={openInfoSpot}
        infoSpot={info.solutionModelData.infoSpot}
      />
    );
    showSolutionModal(modal);
    let overlay = document.querySelector(".welcome-overlay")
    overlay.style.display="block"
    overlay.classList.add("modal-overlay")
  },300)
}


  useImperativeHandle(
    ref,
    () => ({
      changeScene(i) {
          setSelectedView(panoViews[i]);
          setTimeout(() => {
            while (scene.children.length-1)
            {
                scene.remove(scene.children[0]);
            }
          cameraControls.reset(false)
          if(i==0){
            cameraControls.rotate( !bigScreen ? (panoViews[i].initialRotation[0]+25) * THREE.MathUtils.DEG2RAD : (panoViews[i].initialRotation[0]) * THREE.MathUtils.DEG2RAD,0, false )
          }
          else{
          cameraControls.rotate( panoViews[i].initialRotation[0] * THREE.MathUtils.DEG2RAD,panoViews[i].initialRotation[1] * THREE.MathUtils.DEG2RAD, false )
        }
        }, 100);
        },

        openInfoSpot(i,j){
          setTimeout(() => {
            let info = panoViews[j].infoSpots[i]
            let modal = (
              <SolutionModal
                show={true}
                closeCallback={closeSolutionModalCallback}
                breadCrum={info.solutionModelData.breadCrum}
                title={info.solutionModelData.title}
                primaryContent={info.solutionModelData.primaryContent}
                productHeadline={info.solutionModelData.productHeadline}
                primaryImage={info.solutionModelData.primaryImage}
                crousalData={info.solutionModelData.crousalData}
                productDetail={info.solutionModelData.productDetail}
                carousalHeadline={info.solutionModelData.carousalHeadline}
                icon={info.solutionModelData.icon}
                changeScene={changeScene}
                openInfoSpot={openInfoSpot}
                infoSpot={info.solutionModelData.infoSpot}
              />
            );

            showSolutionModal(modal);
            let overlay = document.querySelector(".welcome-overlay")
            overlay.style.display="block"
            overlay.classList.add("modal-overlay")
          },300)
        },


        openProductModal(i,j){
          setTimeout(() => {
            let info = panoViews[j].infoSpots[i].solutionModelData.productDetail
            let modal = (
              <ProductModal
              show={true}
              closeCallback={closeProductModal}
              closeCrossCallback={closeProductModal}
              productDetail={info}
              />
            );

            showProductModal(modal);
            let overlay = document.querySelector(".welcome-overlay")
            overlay.style.display="block"
            overlay.classList.add("modal-overlay")
          },300)
        }

      
     }),
 )





  useEffect(() => {
    if(window.innerWidth<768){
      setBigScreen(false)
    }
    else{
      setBigScreen(true)
    }
    let count = 0;
    init();
    animate();
    panoViews.forEach((view, index) => {
      loadCachedImage(
        view.panoImage,
        () => {
          if (index === selected) {
          setSelectedView(panoViews[selected]);
          cameraControls.reset(false)
          cameraControls.rotate( window.innerWidth < 768 ? (panoViews[selected].initialRotation[0]+25) * THREE.MathUtils.DEG2RAD : (panoViews[selected].initialRotation[0]) * THREE.MathUtils.DEG2RAD,0, false )
          // lat = panoViews[selected].initialLatLong.lat
          // lon = panoViews[selected].initialLatLong.lon
        }
        },
        (event) => {
          if (event.loaded === event.total) {
            count += 1;
            if (count === panoViews.length) {
              setShowLoader(false);
              // if(!history.location.search.includes("aliId=") && !localStorage.getItem("form-submitted")){
              //   setCookiePopup(true);
              // } 
            }
          }
        }
      );
    });
  }, []);

  useEffect(async() => {
    if (selectedView === undefined) return;
    let hotSpots = [];
    scene2.clear();
    await addPanoViewToScene(selectedView.panoImage);
    setTimeout(() => {
    if(selectedView.infoSpots){
      infoPositions=[]
      selectedView.infoSpots.map((v)=>{
          infoPositions.push({
            pos:v.position,
            name:v.solutionModelData.infoSpot})
        })
      setInfoSpotPositions(infoPositions)
    selectedView.infoSpots.forEach(addInfoPoint);
  }
  else{
    setInfoSpotPositions([])
  }
}, 100);

    if (selectedView.links && selectedView.links.length > 0) {
      selectedView.links.forEach((link) => {
        let hotSpot = addLinkPoint(link);
        hotSpot.addEventListener("click", () =>{
          props.setCurrent(link.linkTo)
          setSelectedView(panoViews[link.linkTo])
          
          setTimeout(() => {
            while (scene.children.length-1)
{
    scene.remove(scene.children[0]);
}
            cameraControls.reset(false)
            if(link.linkTo==0){
              cameraControls.rotate( !bigScreen ? (panoViews[link.linkTo].initialRotation[0]+25) * THREE.MathUtils.DEG2RAD : (panoViews[link.linkTo].initialRotation[0]) * THREE.MathUtils.DEG2RAD,0, false )
            }
            else{ 
            cameraControls.rotate( panoViews[link.linkTo].initialRotation[0] * THREE.MathUtils.DEG2RAD,panoViews[link.linkTo].initialRotation[1] * THREE.MathUtils.DEG2RAD, false )
          }
            hotSpots.push(hotSpot);
          }, 100);
         
        });
        

        
      });
    }
    return () =>
      hotSpots.forEach((hotSpot) =>
        hotSpot.removeEventListener("click", setSelectedView(selectedView))
      );
  }, [selectedView]);





  const addPanoViewToScene = (url) => {  
      loadCachedImage(url, (image) => {
        let texture = new THREE.Texture();
        texture.image = image;
        texture.needsUpdate = true;
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = texture
        const material = new THREE.MeshBasicMaterial({ map: texture,transparent: true,opacity:0});
        const mesh = new THREE.Mesh(geometry, material)
        scene.add(mesh)
        TweenMax.to(material, 1.5, { opacity: 1 });
    }); 
    
  };

  const closeSolutionModalCallback = () => {
    showSolutionModal(undefined);
    // let overlay = document.querySelector(".welcome-overlay")
    // overlay.style.display="none"
    // overlay.classList.remove("modal-overlay")
  };

const closeProductModal = () =>{
  showProductModal(undefined);
    let overlay = document.querySelector(".welcome-overlay")
    overlay.style.display="none"
    overlay.classList.remove("modal-overlay")
}

  const addInfoPoint = (info) => {
    let infoSpot = document.createElement("div");
    let namePlate = document.createElement("div")
    let namePlateTag = document.createElement("img")
    let namePlateTag1 = document.createElement("div")
    namePlateTag1.className="name-plate-tag1"
    namePlateTag.src=tagPNG
    namePlateTag.className="name-plate-tag"
    namePlate.className="name-plate"
    let text = document.createElement("p")
    text.innerHTML = info.solutionModelData.infoSpot;
    infoSpot.setAttribute('data-hotspot-type', info.solutionModelData.hotspotType);
    infoSpot.setAttribute('data-hotspot-room', info.solutionModelData.hotspotRoom);
    infoSpot.setAttribute('data-hotspot-product', info.solutionModelData.hotspotProduct);

    text.style.paddingTop="0px"
    text.style.paddingBottom="0px"
    text.innerHTML=info.solutionModelData.infoSpot
    namePlateTag1.appendChild(text)
    infoSpot.className = "info-spot-wrap hotspot";

    var animInner = document.createElement("img")
    animInner.style.zIndex = -50
    animInner.className="infospot-inner-circle"
    animInner.src=infoInnerWhite
    infoSpot.appendChild(animInner)

    var animOuter = document.createElement("img")
    animOuter.className="infospot-outer-circle"
    animOuter.src = infoOuterWhite
    infoSpot.appendChild(animOuter)

    var animHoverInnerWhite = document.createElement("img")
    animHoverInnerWhite.className="infospot-hover-inner-circle-white"
    animHoverInnerWhite.src = infoInnerWhite
    animHoverInnerWhite.classList.add("hide")
    infoSpot.appendChild(animHoverInnerWhite)

    var animHoverInnerYellow = document.createElement("img")
    animHoverInnerYellow.className="infospot-hover-inner-circle-yellow"
    animHoverInnerYellow.src = infoInnerYellow
    animHoverInnerYellow.classList.add("hide")
    infoSpot.appendChild(animHoverInnerYellow)

    var animHoverOuter = document.createElement("img")
    animHoverOuter.className="infospot-hover-outer-circle"
    animHoverOuter.src = infoOuterWhite
    animHoverOuter.classList.add("hide")
    infoSpot.appendChild(animHoverOuter)

    namePlate.style.pointerEvents = "none"

    namePlate.appendChild(namePlateTag1)
    namePlate.appendChild(namePlateTag)
    infoSpot.appendChild(namePlate)

    if(!bigScreen){
      animInner.style.display="none"
      animOuter.style.display="none"
      animHoverInnerWhite.style.display="block"
      animHoverInnerYellow.style.display="block"
      animHoverOuter.style.display="block"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:1,
          x:10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    }
    else{
      animInner.style.display="block"
      animOuter.style.display="block"
      animHoverInnerWhite.style.display="none"
      animHoverInnerYellow.style.display="none"
      animHoverOuter.style.display="none"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:0,
          x:-10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    }

    infoSpot.onmouseenter = () => {
      animInner.style.display="none"
      animOuter.style.display="none"
      animHoverInnerWhite.style.display="block"
      animHoverInnerYellow.style.display="block"
      animHoverOuter.style.display="block"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:1,
          x:10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    };

    infoSpot.onmouseleave = () => {
      animInner.style.display="block"
      animOuter.style.display="block"
      animHoverInnerWhite.style.display="none"
      animHoverInnerYellow.style.display="none"
      animHoverOuter.style.display="none"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:0,
          x:-10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    };
    infoSpot.addEventListener("click", () => {
      let modal = (
        <SolutionModal
          show={true}
          closeCallback={closeSolutionModalCallback}
          breadCrum={info.solutionModelData.breadCrum}
          title={info.solutionModelData.title}
          primaryContent={info.solutionModelData.primaryContent}
          productHeadline={info.solutionModelData.productHeadline}
          primaryImage={info.solutionModelData.primaryImage}
          crousalData={info.solutionModelData.crousalData}
          productDetail={info.solutionModelData.productDetail}
          carousalHeadline={info.solutionModelData.carousalHeadline}
          icon={info.solutionModelData.icon}
          changeScene={changeScene}
          openInfoSpot={openInfoSpot}
          infoSpot={info.solutionModelData.infoSpot}
        />
      );
      showSolutionModal(modal);
      // let overlay = document.querySelector(".welcome-overlay")
            // overlay.style.display="block"
            // overlay.classList.add("modal-overlay")
    });
    let object = new CSS2DObject(infoSpot);
    object.position.copy(info.position);

    if (info.scale) object.scale.copy(info.scale);

    if (info.rotation) {
      object.rotation.x = info.rotation.x;
      object.rotation.y = info.rotation.y;
      object.rotation.z = info.rotation.z;
    }

    scene2.add(object);
    scene2.add(nre)

 

    return infoSpot;
  };

  const addLinkPoint = (link) => {
    let hotSpot = document.createElement("div");
    let namePlate = document.createElement("div")
    let namePlateTag = document.createElement("img")
    namePlateTag.src=tagPNG
    namePlateTag.className="name-plate-tag"
    namePlate.className="name-plate"
    let namePlateTag1 = document.createElement("div")
    namePlateTag1.className="name-plate-tag1"
    let text = document.createElement("p")
    text.style.paddingTop="0px"
    text.style.paddingBottom="0px"
    text.innerHTML = link.name
    namePlateTag1.appendChild(text)
    namePlate.appendChild(namePlateTag1)
    namePlate.appendChild(namePlateTag)
    hotSpot.appendChild(namePlate)
    hotSpot.className = "hotspot";
    hotSpot.setAttribute('data-hotspot-type', 'location')
    hotSpot.setAttribute('data-hotspot-room', link.hotspotRoom)
    var anim = document.createElement("img")
    anim.className="vert-move-white"
    anim.src=mapIconWhite
    hotSpot.appendChild(anim)

    var animHover = document.createElement("img")
    animHover.className="vert-move-yellow hotspot"
    animHover.setAttribute('data-hotspot-type', 'location')
    animHover.setAttribute('data-hotspot-room', link.hotspotRoom)
    animHover.classList.add("hide")
    animHover.src=mapIconYellow
    hotSpot.appendChild(animHover)

    var animHoverCircle = document.createElement("img")
    animHoverCircle.className="map-circle"
    animHoverCircle.classList.add("hide")
    animHoverCircle.src=mapCircle
    hotSpot.appendChild(animHoverCircle)

    if(!bigScreen){
      anim.style.display="none"
      animHover.style.display="block"
      animHoverCircle.style.display="block"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:1,
          x:10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    }
    else{
      anim.style.display="block"
      animHover.style.display="none"
      animHoverCircle.style.display="none"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:0,
          x:-10,
          display:"none",
          ease:Power3.easeOut()
        }
      )
    }

    hotSpot.onmouseenter = async() => {
      anim.style.display="none"
      animHover.style.display="block"
      animHoverCircle.style.display="block"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:1,
          x:10,
          display:"block",
          ease:Power3.easeIn()
        }
      )
    };
    hotSpot.onmouseleave = async() => {
      anim.style.display="block"
      animHover.style.display="none"
      animHoverCircle.style.display="none"
      TweenMax.to(
        namePlate,
        0.4,
        {
          opacity:0,
          x:-10,
          display:"none",
          ease:Power3.easeOut()
        }
      )
     
    };

    let object = new CSS2DObject(hotSpot);
    object.position.copy(link.position);

    if (link.scale) object.scale.copy(link.scale);

    if (link.rotation) {
      object.rotation.x = link.rotation.x;
      object.rotation.y = link.rotation.y;
      object.rotation.z = link.rotation.z;
    }

    scene2.add(object);
    return hotSpot;
  };

  function init() {
    container = document.getElementById("infoContainer");
    camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      1,
      10000
    );
    camera.position.z = 0.01;
    camera.fov = THREE.MathUtils.clamp(100, 10, 75)
    camera.updateProjectionMatrix();

    scene = new THREE.Scene();
    scene2 = new THREE.Scene();

    geometry.scale(-1, 1, 1);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.style.zIndex = 2;
    renderer.domElement.style.flex = "1 1 auto";
    renderer.domElement.style.overflow = "hidden";

    container.appendChild(renderer.domElement);
    container.style.touchAction = "none";
    container.addEventListener("pointerup", onPointerUp, false);
    container.addEventListener("pointerup", onPointerDown, false);

    renderer2 = new CSS2DRenderer();
    renderer2.setSize(window.innerWidth, window.innerHeight);
    renderer2.domElement.style.position = "absolute";
    renderer2.domElement.style.top = 0;
    renderer2.domElement.style.zIndex = 4;
    container.appendChild(renderer2.domElement);
    var test = document.createElement("div")
    test.className = "test-div"
    test.innerHTML = `Bilal`
    nre = new CSS2DObject(test);
    clock = new THREE.Clock();
    const EPS = 1e-5;
    CameraControls.install( { THREE: THREE } );
    cameraControls = new CameraControls( camera, renderer2.domElement );
    cameraControls.azimuthRotateSpeed = - 0.2; // negative value to invert rotation direction
    cameraControls.polarRotateSpeed   = - 0.2; // negative value to invert rotation direction
    cameraControls.truckSpeed = 1 / EPS * 3;
    cameraControls.mouseButtons.right = CameraControls.ACTION.NONE
    cameraControls.mouseButtons.wheel = CameraControls.ACTION.NONE
    document.addEventListener("wheel", onDocumentMouseWheel, false);
    window.addEventListener("resize", onWindowResize, false);
  }

  function onWindowResize() {
    // camera.updateProjectionMatrix();
    // renderer.setSize(window.innerWidth, window.innerHeight);
    // renderer2.setSize(window.innerWidth, window.innerHeight);
    if(window.innerWidth < 768){
      setBigScreen(false)
    }
    else{
      setBigScreen(true)
    }
    camera.aspect = window.innerWidth / window.innerHeight;
camera.updateProjectionMatrix();
renderer2.setSize( window.innerWidth, window.innerHeight );
renderer.setSize( window.innerWidth, window.innerHeight );

  }

  function onPointerDown(event,allPositions) {
    if (event.isPrimary === false)
     return;
    document.addEventListener("pointerup", (e)=>onPointerUp(e,allPositions), false);
    // updateNavigation(allPositions)
  }

  function  onPointerUp(event,allPositions) {
    if (event.isPrimary === false) return;
    document.removeEventListener("pointerup", onPointerUp);
    moveCamera = ""
    // updateNavigation(allPositions)
  }

  function onDocumentMouseWheel(event) {
    let modal = document.querySelector(".special-modal-product.show");
    let attachmentModal = document.querySelector(".special-attachment-modal.show")
    let solutionModal = document.querySelector(".special-modal-solution")
    let mapModal = document.querySelector(".map-modal")
    let overlay = document.querySelector(".welcome-overlay")
    let sidebar = document.getElementById("sidebar1")
    let filterResults = document.getElementById("filterResults")
    let getInTouch = document.getElementById("lightbox-contact-form")
    let filter = document.getElementById("filterModal")

    // if (!!!modal && !!!attachmentModal && !!!solutionModal && mapModal.classList[1]=="fadeOut" && overlay.style.display=='none' && !sidebar.classList.contains("is-open") && filterResults==null && getInTouch.style.display=="none" && filter==null) {
    //   const fov = camera.fov + event.deltaY * 0.05;
    //   if (fov < 50) return;
    //   camera.fov = THREE.MathUtils.clamp(fov, 10, 75);
    //   camera.updateProjectionMatrix();
    // }
  }

  const updateCameraLookAt = () => {
    if (moveCamera === "left") {
      cameraControls.rotate(  1 * THREE.MathUtils.DEG2RAD, 0, true )
    } 
    else if (moveCamera === "right") {
      cameraControls.rotate(  -1 * THREE.MathUtils.DEG2RAD, 0, true )
    } 
    else if (moveCamera === "top") {
      cameraControls.rotate( 0, 1 * THREE.MathUtils.DEG2RAD, true )
    } 
    else if (moveCamera === "down") {
      cameraControls.rotate( 0, -1 * THREE.MathUtils.DEG2RAD, true )
    }
  };

  function animate() {
    requestAnimationFrame(animate);
    updateCameraLookAt();
    updateNavigation(infoPositions);
    const delta = clock.getDelta();
	  cameraControls.update( delta );
    camera.updateMatrix();
    camera.updateMatrixWorld();
    // object.position.set(new THREE.Vector3(-400, -100, 750))
    renderer.render(scene, camera);
    renderer2.render(scene2, camera);
  }

  const updateNavigation = (allPositions) =>{
    camera.updateMatrix();
    camera.updateMatrixWorld();
    var frustum = new THREE.Frustum();
    frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));  
    // Your 3d point to check
    var pos = []
    var left = 0
    var right = 0
    allPositions.map(v=>{
    if (!frustum.containsPoint(v.pos)) {
      temp = v.pos.clone();
      camera.worldToLocal( temp );
      if(temp.x<0){
        left=left+1
      }
      else{
        right=right+1
      }
    }
    setLeftNumber(left)
    setRightNumber(right)
    })
  }


  return (
    <div id="infoContainer">
      <div id="navArrows">
      <img
        src={navArrowUpWhite}
        className="img-arrow img-arrow-up"
        draggable="false"
        onContextMenu={event => event.preventDefault()}
        // onMouseEnter={(e) => {e.target.src=navArrowUpYellow}}
        // onMouseLeave={(e) => {e.target.src=navArrowUpWhite}}
        onTouchStart={(e)=>{e.preventDefault();moveCamera = "top"}}
        onTouchEnd={(e)=>{e.preventDefault();moveCamera = ""}}
        onPointerDown={(e)=>{e.preventDefault();moveCamera = "top"}}
        onPointerUp={(e)=>{e.preventDefault();moveCamera = ""}}
        alt="Nav Arrow Up"
      />
      <img
        src={navArrowDownWhite}
        onContextMenu={event => event.preventDefault()}
        className="img-arrow img-arrow-down"
        draggable="false"
        // onMouseEnter={(e) => {e.target.src=navArrowUpYellow}}
        // onMouseLeave={(e) => {e.target.src=navArrowUpWhite}}
        onTouchStart={(e)=>{e.preventDefault();moveCamera = "down"}}
        onTouchEnd={(e)=>{e.preventDefault();moveCamera = ""}}
        onPointerDown={(e)=>{e.preventDefault();moveCamera = "down"}}
        onPointerUp={(e)=>{e.preventDefault();moveCamera = ""}}
        alt="Nav Arrow Down"
      />
      <img
        src={navArrowLeftWhite}
        onContextMenu={event => event.preventDefault()}
        className="img-arrow img-arrow-left"
        draggable="false"
        // onMouseEnter={(e) => {e.target.src=navArrowLeftYellow}}
        // onMouseLeave={(e) => {e.target.src=navArrowLeftWhite}}
        onTouchStart={(e)=>{e.preventDefault();moveCamera = "left"}}
        onTouchEnd={(e)=>{e.preventDefault();moveCamera = ""}}
        onPointerDown={(e)=>{e.preventDefault();moveCamera = "left"}}
        onPointerUp={(e)=>{e.preventDefault();moveCamera = ""}}
        alt="Nav Arrow Left"
      />
      {infoSpotPositions.length>0 && <p className="img-arrow img-arrow-left-text">{leftNumber} Hotspots</p>}
      <img
        src={navArrowRightWhite}
        className="img-arrow img-arrow-right"
        draggable="false"
        onContextMenu={event => event.preventDefault()}
        // onMouseEnter={(e) => {e.target.src=navArrowRightYellow}}
        // onMouseLeave={(e) => {e.target.src=navArrowRightWhite}}
        onTouchStart={(e)=>{e.preventDefault();moveCamera = "right"}}
        onTouchEnd={(e)=>{e.preventDefault();moveCamera = ""}}
        onPointerDown={(e)=>{e.preventDefault();moveCamera = "right"}}
        onPointerUp={(e)=>{e.preventDefault();moveCamera = ""}}
        alt="Nav Arrow Right"
      />
      {infoSpotPositions.length>0 && <p className="img-arrow img-arrow-right-text">{rightNumber} Hotspots</p>}
      </div>
      {/* {cookiePopup && 
    <div className="cookie-overlay">
    <div className="accept-cookie custom-container">
        <p className="cookie-desc">
        Important information regarding the Hajj Application website: this site uses cookies to improve user experience and stores information on your computer. By continuing to use our site, you consent to our Cookie Policy. If you do not want to enable cookies, review our policy and learn how they can be disabled. Note that disabling cookies will disable some features of the site.
        </p>
        <button className="accept-button" onClick={acceptCookies}>ACCEPT</button>
        </div>
        </div>
        } */}
      {solutionModal}
      {productModal}
    </div>
  );
})

export default PanoView;
