// src/components/Footer.js
import React from 'react';
import './style.scss';
import logo from '../../../assets/images/footer_hajj_logo.png';
import { FaFacebook, FaTwitter, FaInstagram, FaPhone, FaLinkedin } from 'react-icons/fa';
import { red } from '@material-ui/core/colors';
import { TextLeft } from 'react-bootstrap-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo-section">
          <img src={logo} alt="Company Logo" className="footer-logo" />
          <div className="contact-info">
            {/* <p>Contact us at: info@hajjmetaverse.com</p> */}
            <p><a href="mailto:info@hajjmetaverse.com">Contact us at: info@hajjmetaverse.com</a></p>
          </div>
        </div>
        <div className="footer-social-section">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://www.facebook.com/HajjMetaverse" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="social-icon" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p className='copyright-text'>&copy; {new Date().getFullYear()} All rights reserved | Designed by <a href="https://euphoriaxr.com/" target="_blank" className='company-color'>Euphoria XR</a></p>
      </div>
    </footer>
  );
};

export default Footer;
