import React, { useRef, useEffect } from 'react';

const BackgroundSound = ({ isPlaying }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
      if (isPlaying) {
        audioRef.current.play()
         .catch((error) => {
            console.error("Playback failed: ", error);
          });
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <audio ref={audioRef} loop>
      <source src={`${process.env.PUBLIC_URL}/background_sound.mp3`} type="audio/mpeg" />
    </audio>
  );
};

export default BackgroundSound;

