import React, { useEffect, useState, useContext } from 'react';
import Loader from '../components/loader';
import { PanoContext } from '../../contexts/PanoContextContainer';

import Introduction from "../components/introduction";
import Tutorial from "../components/tutorial";
import immersiveLoader from '../../assets/images/immersive_loader.png';
import IntroForm from '../components/introForm'
import FormStatus from '../components/formStatus'
import './style.css';
import { propTypes } from 'react-bootstrap/esm/Image';
import {useHistory} from 'react-router-dom'

function Welcome(props) {
  const { showLoader, showTutorial, setShowTutorial,showIntrudction,setShowIntrudction } = useContext(PanoContext);

  const [show, setShow] = useState(true);
  const [showImmersive, setShowImmersive] = useState(true);
  // const [showForm,setShowForm] = useState(false)
  const [showFormStatus,setShowFormStatus] = useState(false)
  const [formFail,setFormFail] = useState(false)

  useEffect(() => console.log("show ", show), [show]);
  useEffect(() => console.log("showImmersive", showImmersive ), [showImmersive])
  useEffect(() => console.log("showFormStatus", showFormStatus ), [showFormStatus])
  useEffect(() => console.log("formFail", formFail ), [formFail])

  const history = useHistory()

  const handleActionClick = () => {
    var intro = document.querySelector(".welcome-wrap")
    intro.classList.remove("fadeIn-welcome")
    intro.classList.add("fadeOut-welcome")
    setTimeout(() => {
      setShowIntrudction(false);
      if(localStorage.getItem("tutorial-showed")){
        hideModal()
      }
      else{
        localStorage.setItem("tutorial-showed",true)
        setShowTutorial(true)
      }
    }, 1000);
  }

  const handleComponentClick=(i)=>{
    var intro = document.querySelector(".welcome-wrap")
    intro.classList.remove("fadeIn-welcome")
    intro.classList.add("fadeOut-welcome")
    props.changeScene(i)
    setTimeout(() => {
      if(localStorage.getItem("tutorial-showed")){
        setShowIntrudction(false);
        hideModal()
      }
      else{
        localStorage.setItem("tutorial-showed",true)
        setShowIntrudction(false);
        setShowTutorial(true)
      }
    }, 1000);
  }

  const handleSubComponentClick=(i,j)=>{
    var intro = document.querySelector(".welcome-wrap")
    intro.classList.remove("fadeIn-welcome")
    intro.classList.add("fadeOut-welcome")
    setTimeout(() => {
      setShowIntrudction(false);
      hideModal(i,j)
    }, 200);
  }

  const handleFormSubmit=()=>{
    localStorage.setItem("form-submitted",true)
    // setShowForm(false)
    setShowFormStatus(true)
    setFormFail(false)
  }

  const handleContinue=()=>{
    setShowFormStatus(false)
    setShowIntrudction(true);
  }

  const handleTryAgain=()=>{
    setShowFormStatus(false)
    // setShowForm(true)
  }






   const hideModal = (k,l) => {
    var arrows = document.getElementById("navArrows")
    arrows.style.display="block"
    var hotSpots = document.getElementsByClassName("hot-spot-img")
     if(hotSpots.length>0){
       for(var i=0;i<hotSpots.length;i++){
         hotSpots[i].style.opacity=1
       }    
  }

  var infoSpot = document.getElementsByClassName("info-spot-wrap")
  if(infoSpot.length>0){
    for(var i=0;i<infoSpot.length;i++){
      infoSpot[i].style.opacity=1
    }    
}

    var tutorial = document.querySelector("#tutorial")
    if(tutorial){
    tutorial.classList.remove("fadeIn-welcome")
    tutorial.classList.add("fadeOut-welcome")
  }
    setTimeout(() => {
    setShowTutorial(false);
    setShowIntrudction(false);
    setShowImmersive(false);
    setShow(false);
    
    if(l!==undefined){
      props.openInfoSpot(k,l)
    }
  }, 200);
  }

  useEffect(()=>{
    var arrows = document.getElementById("navArrows")
    arrows.style.display="none"
    var hotSpots = document.getElementsByClassName("hot-spot-img")
    if (!showLoader)
      setTimeout(() => {
        if(hotSpots.length>0){
          for(var i=0;i<hotSpots.length;i++){
            hotSpots[i].style.opacity=0
          }    
     }
    
     var infoSpot = document.getElementsByClassName("info-spot-wrap")
     if(infoSpot.length>0){
       for(var i=0;i<infoSpot.length;i++){
         infoSpot[i].style.display=0
       }    
    }
        setShowImmersive(false);
        if(localStorage.getItem("form-submitted")){
          setShowIntrudction(true);
        }
        else{
        if(history.location.search.includes("aliId=")){
          localStorage.setItem("form-submitted",true)
          // setShowForm(false)
          setShowFormStatus(true)
          setFormFail(false)
          }
          else{
            // setShowForm(true)
          }
        }
        
      }, 2000);
  }, [showLoader])

  useEffect(() => {
    if (showTutorial){
      var hotSpots = document.getElementsByClassName("hot-spot-img")
      if(hotSpots.length>0){
        for(var i=0;i<hotSpots.length;i++){
          hotSpots[i].style.opacity=0
        }    
   }
  
   var infoSpot = document.getElementsByClassName("info-spot-wrap")
   if(infoSpot.length>0){
     for(var i=0;i<infoSpot.length;i++){
       infoSpot[i].style.opacity=0
     }    
  }
  setShow(true);
    }
    
  }, [showTutorial])


  
  useEffect(() => {
    if (showIntrudction){
      var hotSpots = document.getElementsByClassName("hot-spot-img")
      if(hotSpots.length>0){
        for(var i=0;i<hotSpots.length;i++){
          hotSpots[i].style.opacity=0
        }    
   }
  
   var infoSpot = document.getElementsByClassName("info-spot-wrap")
   if(infoSpot.length>0){
     for(var i=0;i<infoSpot.length;i++){
       infoSpot[i].style.opacity=0
     }    
  }
  setShow(true);
    }
    
  }, [showIntrudction])



  const renderComponent = () => {
    if (showIntrudction)
      return <Introduction handleActionClick={handleActionClick} handleComponentClick={handleComponentClick} handleSubComponentClick={handleSubComponentClick}/>;

    else if (showTutorial)
      return <Tutorial hideModal={hideModal}/>;

    // else if(showForm)
    // return <IntroForm handleFormSubmit={handleFormSubmit}/>

    else if(showFormStatus)
    return <FormStatus formFail={formFail} handleContinue={handleContinue} handleTryAgain={handleTryAgain}/>

    else
      return null;
  } 

  return (
    <div className={`${showImmersive && 'welcome-overlay'} align-items-center justify-content-center ${ showImmersive && 'weclome-overlay-control'}`} style={{'display': show ? 'flex' : 'none' }}>
      { renderComponent() }
      {
        (showLoader || showImmersive) &&
        <Loader />
      }
    </div>
  );
}

export default Welcome;

