import React, { Suspense, useContext, useState,forwardRef,useRef,useImperativeHandle } from "react"
import {Canvas} from "react-three-fiber"
import {ContactShadows} from "drei"
import Model from './model'
import "./styles.scss"
import { PanoContext } from '../../contexts/PanoContextContainer';


const mapModal = forwardRef((props, ref) => {

  const { setSelected } = useContext(PanoContext);
  var [modelClick,setModelClick] = useState(false)
  const changeScene=(i)=>{
    props.closeModal()
    props.changeScene(i)
  }

  const childRef = useRef();


  useImperativeHandle(
    ref,
    () => ({
        closeModals(){
          childRef.current.closeModal()
        }
    })
)
  



  return (
      <>
      <div className="map-text">
    <p className="map-heading">Hajj Metaverse</p>
        <p className="map-tagline">Hover over different rooms on the map to explore use cases</p>
        </div>  
       <Canvas className="canvas" id="mapmodal" gl={{ antialias: true }} pixelRatio={window.devicePixelRatio} 
       >
        <ambientLight intensity={0.3}/>
        <Suspense fallback={null}>
          <Model className="model"
          ref={childRef}
           modelClick={changeScene} openInfoSpot={props.openInfoSpot} closeModal={props.closeModal}/>
          <ContactShadows rotation-x={Math.PI / 2} position={[0, -0.8, 0]} opacity={0.25} width={10} height={10} blur={2} far={1} />
        </Suspense>
      </Canvas>   
      </>
  );
})

export default mapModal;
