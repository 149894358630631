// src/pages/AboutUs.js
import React, { useState } from 'react';
import './style.scss';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import MenuBar from '../sideMenu'; // Import the MenuBar component

const PrivacyPolicy = () => {

const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="about-us-container">
      <Navbar />
      <div className="content-section" style={{marginTop: 80}}>
        {/* <div className="image-container">
          <img src={uncheckedIcon} alt="About Us" />
        </div> */}
            <div>
        <button className='pop-button' onClick={handleToggleMenu}>Menu</button>
          <MenuBar isOpen={isMenuOpen} onClose={handleCloseMenu} />
        </div>
        <div className="text-container">
          <h1>Standard Terms of Service</h1>
          <h6>August 01, 2024
          </h6>
          <p style={{marginTop: 30}}>
          This app is operated by Global Ocean United. Throughout these Terms of Service, the terms “we,” “us,” and “our” refer to Global Ocean United. By using this app, you agree to comply with these terms and conditions, along with any additional policies referenced or linked here.

          <h6 style={{marginTop: 30}}>Acceptance of Terms</h6>
<p>By visiting our app and/or purchasing from us, you engage in our “Service” and agree to be bound by these Terms of Service (“Terms”), including any additional terms referenced or available by hyperlink. These Terms apply to all users, including browsers, vendors, customers, merchants, and contributors of content.</p>

<h6>Use of Service</h6>
<p>Please read these Terms of Service carefully before accessing or using our app. By accessing or using any part of the site, you agree to these terms. If you do not agree, you may not use the app or services.</p>

<h6>Changes to Terms</h6>
<p>We may update these Terms of Service at any time. It is your responsibility to review this page periodically. Continued use of the app after changes are posted constitutes acceptance of those changes.</p>

<h6>Store Terms</h6>
<p>By agreeing to these terms, you confirm you are of legal age in your residence or have consent from your guardian. You must not use our products for illegal or unauthorized purposes or violate any laws in your jurisdiction.</p>

<h6>General Conditions</h6>
<p>We reserve the right to refuse service to anyone at any time. You agree not to reproduce, duplicate, copy, sell, or exploit any part of the Service without express written permission from us.</p>

<h6>Information Accuracy</h6>
<p>We are not responsible if information on this site is not accurate or current. It is provided for general information only. We reserve the right to modify contents at any time without obligation to update any information.</p>

<h6>Service Modifications</h6>
<p>We reserve the right to modify or discontinue the Service without notice. We are not liable for any changes, suspensions, or discontinuations of the Service.</p>

<h6>Third-Party Links</h6>
<p>Our Service may include materials from third parties. We are not responsible for third-party content or accuracy and do not assume liability for third-party materials or apps.</p>

<h6>User Submissions</h6>
<p>If you send us comments, you agree we may use them without restriction. We are not obligated to maintain any comments in confidence or to pay compensation for any comments.</p>

<h6>Personal Information</h6>
<p>Your submission of personal information is governed by our Privacy Policy.</p>

<h6>Errors and Omissions</h6>
<p>Occasionally there may be information on our site or Service that contains errors. We reserve the right to correct errors and to change or update information without prior notice.</p>

<h6>Prohibited Uses</h6>
<p>You are prohibited from using the site or its content for unlawful purposes, to solicit others to perform unlawful acts, to violate laws, to infringe on our or others' rights, to harass or abuse, to submit false information, to upload viruses, or to interfere with the Service’s security features.</p>

<h6>Disclaimer of Warranties</h6>
<p>We do not guarantee uninterrupted, secure, or error-free use of our Service. The Service and all products are provided “as is” without warranties or conditions of any kind.</p>

<h6>Limitation of Liability</h6>
<p>Global Ocean United is not liable for any injury, loss, claim, or damages arising from your use of the Service or any products procured using the Service.</p>

<h6>Indemnification</h6>
<p>You agree to indemnify and hold Global Ocean United and affiliates harmless from any claims arising out of your breach of these Terms or your violation of any law or rights of a third party.</p>

<h6>Severability</h6>
<p>If any part of these Terms is determined to be unlawful or unenforceable, the remaining provisions remain in effect.</p>

<h6>Termination</h6>
<p>These Terms are effective unless terminated by either you or us. You may terminate these Terms by discontinuing use of our Service. We may terminate these Terms at any time without notice if you fail to comply with any term.</p>

<h6>Entire Agreement</h6>
<p>These Terms and any policies or operating rules posted by us constitute the entire agreement and govern your use of the Service, superseding prior agreements.</p>

<h6>Governing Law</h6>
<p>These Terms and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Oman. The exclusive venue is Oman.</p>

<h6>Contact Information</h6>
<p>For more information or to address concerns, please contact us at hello@pilgrim3d.com or by mail:</p>

<p><strong>Global Ocean United, Oman</strong></p>
          </p>
            <h1 style={{marginTop: 30}}>Privacy Policy</h1>
            <h6>August 01, 2024</h6>
            <h6>Global Ocean United - Privacy & Security Policy</h6>
          <p>

<p>At Global Ocean United, we recognize the importance of protecting the privacy and security of our users' data. Our commitment to handling your data with confidentiality and care is paramount. This privacy policy provides detailed and transparent information about how we process your personal data, which includes any information that can be linked to you personally, such as your name, address, email address, or user behavior.</p>

<h6 >1. Responsible Entity</h6>
<p>The entity responsible for processing personal data in connection with our app, which facilitates Hajj and Umrah services, is:</p>
<p><strong>GLOBAL OCEAN UNITED (Oman)</strong></p>
<p>Referred to in this policy as "Global Ocean United", "us", or "we". For any questions regarding our privacy policy, please contact us at: <a href="mailto:hello@pilgrim3d.com">hello@pilgrim3d.com</a></p>

<h6 >2. Processing of Personal Data</h6>
<p>We process personal data only with your consent or if permitted by law. This approach aligns with data protection principles, which require processing based on a lawful basis.</p>

<h6 >3. Types of Personal Data Processed and Purposes</h6>
<p><strong>(1) Log Files</strong></p>
<p>When using our app, we collect usage data to display the app, ensure data security, and maintain the stability of our IT systems. This data includes session times, operating system details, country, IP address, and more.</p>
<p>Purposes:</p>
<ul>
  <li>Protecting against or tracing hacker and cyber attacks.</li>
  <li>Ensuring the functionality of our IT systems.</li>
  <li>Optimizing our app and its functionality.</li>
</ul>

<p><strong>(2) Usage Data</strong></p>
<p>We collect personal data necessary for app functionality and user experience. This data is stored on your device and not shared with third parties. Upon deleting your user account, all personal data is also deleted.</p>
<p>Data processed:</p>
<ul>
  <li>Usernames</li>
  <li>App session details, progress, and save data</li>
</ul>

<h6 >4. Profiles</h6>
<p>Users can create profiles within the app, which are stored on our servers to enhance user experience.</p>

<h6 >5. Newsletter</h6>
<p>We offer a newsletter to keep users informed about our products and services.</p>
<p>Data processing and consent:</p>
<ul>
  <li>Collect first and last name for personalization, and email address for sending newsletters.</li>
  <li>Consent is obtained through a double opt-in process.</li>
  <li>Users can unsubscribe at any time, which will also delete their personal data from our records.</li>
</ul>

<h6 >6. Disclosure to Third Parties</h6>
<p>We share your data with third parties only as necessary to fulfill our service obligations. We also use external technical service providers, who are carefully selected and monitored by us.</p>

<h6 >7. Protection of Personal Data</h6>
<p>We use technical and organizational measures to secure our app and stored data against unauthorized access, loss, or publication.</p>

<h6 >8. Rights of Data Subjects</h6>
<p>Users have the right to object to the processing of their personal data based on our legitimate interests. Upon receiving a justified objection, we will review and either stop or adjust the processing or provide compelling legitimate grounds for continuing it.</p>

<h6 >9. Objection or Revocation of Consent</h6>
<p>Users can revoke their consent to data processing at any time. This affects the permissibility of future data processing. Objections to data processing for advertising and data analysis can be made using our contact details.</p>

<p>For more information about our privacy and security policies, or if you have any questions or complaints, please contact us at <a href="mailto:hello@pilgrim3d.com">hello@pilgrim3d.com</a>.</p>

<p><strong>Global Ocean United, Oman</strong></p>
          </p>
        </div>
      </div>
      
     
    
      
     
      <Footer  />
    </div>
  );
};

export default PrivacyPolicy;
