import React, { useState, useContext, useEffect,useImperativeHandle,forwardRef } from "react";
import { PanoContext } from "../../../contexts/PanoContextContainer";
// import siliconLab from "../../../assets/images/SiLabs-logo.svg";
import siliconLab from "../../../assets/images/hajjlogo2.png";
import shareIcon from '../../../assets/images/actionbar/icons-share.svg'
import messageIcon from '../../../assets/images/actionbar/icons-message.svg'
import volumeIcon from '../../../assets/images/actionbar/speaker-svgrepo-com.svg'
import muteIcon from '../../../assets/images/actionbar/volume-cross-svgrepo-com.svg'
import aboutUsIcon from '../../../assets/images/actionbar/about-svgrepo-com.svg'
import privacyPolicyIcon from '../../../assets/images/actionbar/policy-svgrepo-com.svg'
import filterIcon from '../../../assets/images/actionbar/icons-filter.svg'
import shareEmailIcon from '../../../assets/images/actionbar/icons-share-email.svg'
import shareTwitterIcon from '../../../assets/images/actionbar/icons-share-twitter.svg'
import shareLinkedIcon from '../../../assets/images/actionbar/icons-share-linked-in.svg'
import { Collapse } from 'reactstrap';
import plusIcon from '../../../assets/images/icons-plus.svg'
import minusIcon from '../../../assets/images/icons-minus.svg'
import checkedIcon from '../../../assets/images/icon-toggle-checkbox-filled.svg'
import uncheckedIcon from '../../../assets/images/icon-toggle-checkbox-outlined-filled.svg'
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory, useLocation, Link } from "react-router-dom";

import "./style.scss";


const ecosystems = [
  {name:"alexa",title:"Amazon Alexa"},
  {name:"assistant",title:"Google Assistant"},
  {name:"homekit",title:"Apple HomeKit"},
  {name:"smartthings",title:"SmartThings"},
  {name:"sidewalk",title:"Amazon Sidewalk"},
  {name:"ring",title:"Ring"}]
const protocols = [
  {name:"zigbee",title:"Zigbee"},
  {name:"thread",title:"Thread"},
  {name:"zwave",title:"Z-Wave"},
  {name:"bluetooth",title:"Bluetooth"},
  {name:"wifi",title:"Wi-Fi"},
  {name:"matter",title:"Matter"},]

  const ActionBar = forwardRef((props, ref) => {
  const [soundOn, setSoundOn] = useState(false);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(props.filter);
  const [ecoSystem,setEcoSystem] = useState(false)
  const [protocol,setProtocol] = useState(false)
  const {setShowIntrudction,setShowTutorial } = useContext(PanoContext);
  const [selected,setSelected] = useState({
    alexa:false,assistant:false,homekit:false,
    smartthings:false,sidewalk:false,ring:false,
    zigbee:false,thread:false,zwave:false,
    bluetooth:false,wifi:false,matter:false})


    const handleCheckChange=(name,status)=>{
      setSelected({...selected,[name]:status})
    }
  const history = useHistory();
  const location = useLocation();


  const handleShowContactForm = () => {
    var contactForm = document.querySelector('#lightbox-contact-form');
    contactForm.style.display = 'block';
  };

  const handleReset=()=>{
    var checkboxes = Array.from(document.getElementsByClassName("check-boxes"))
    checkboxes.map((v)=>{
      v.checked = false
    })
    setSelected({
      alexa:false,assistant:false,homekit:false,
      smartthings:false,sidewalk:false,ring:false,
      zigbee:false,thread:false,zwave:false,
      bluetooth:false,wifi:false,matter:false})
  }

  const handleShowShareBar=()=>{
    setShow(!show)
    var shareWrap = document.getElementById("sharewrap")
    var shareToggler = document.getElementById("shareToggler")
    var filter = document.getElementById("filter")
    if(!show){
    shareWrap.classList.add("share-fadeIn")
    shareWrap.classList.remove("share-fadeOut")
    shareWrap.classList.remove("hidden-wrap")
    shareToggler.style.paddingRight="0px"
    filter.style.marginRight="5px"
    shareToggler.children[1].style.fontWeight = "1000"
  }
  else{
    shareWrap.classList.add("share-fadeOut")
    shareWrap.classList.remove("share-fadeIn")
    shareToggler.children[1].style.fontWeight = "600"
  }
  }

  useEffect(()=>{
    var shareToggler = document.getElementById("shareToggler")
    document.addEventListener('click', function(event) {
      // var isClickInsideElement = shareToggler.contains(event.target);
    //   if (!isClickInsideElement) {
    //     if(show){
    //       var shareWrap = document.getElementById("sharewrap")
    // shareToggler.children[1].style.fontWeight = "600"
    //       shareWrap.classList.add("share-fadeOut")
    //   shareWrap.classList.remove("share-fadeIn")
    //       setShow(false)
    //     }
    //   }
  });

  })


  useImperativeHandle(
    ref,
    () => ({
      closeFilter(){
        setFilter(false)
      }
    }))


    const toggleSound = () => {
      setSoundOn(!soundOn);
      props.handlePlaySound();
    };
    
      const handleRedirect = () => {
      history.push("/index");
      window.location.reload();
    };
  
    useEffect(() => {
      return history.listen((location) => {
        if (location.pathname === "/index") {
          window.location.reload();
        }
      });
    }, [history]);
    
  return (
    <div className="action-bar-wrapper">
      <div className="row w-100">
      <div onClick={handleRedirect} className="ml-3 cursor-pointer">
          <div className="silicon-lab-anchor">
            <img src={siliconLab} className="silicon-lab-logo" alt="Hajj app LOGO" />
          </div>
      </div>
        <div className="actions-wrap">
        {/* <div> */}
          {/* <div className="cursor-pointer filter" id="filter" onClick={()=>setFilter(!filter)}>
      <img className="action-icon" src={filterIcon} />
      <p className="action-text">FILTER</p>
      </div>
      {filter && <div className="text-white filter-modal" id="filterModal">
        <div className="filter-head">
          <span className="filter-heading">Filters</span>
          <span className="cursor-pointer filter-reset" onClick={handleReset}>Reset</span>
        </div>
        <div className="filter-body">
          <div className="toggler cursor-pointer" onClick={()=>{setEcoSystem(!ecoSystem);setProtocol(false)}}>
            <span>ECOSYSTEM</span>
            <img src={ecoSystem ? minusIcon : plusIcon}/>
          </div> */}
        {/* <Collapse isOpen={ecoSystem}>
        {ecosystems.map((v,i)=>{
          return(
          <div className="list-item" key={i}>
            <div className="item-sub">
            <span>{v.title}</span>
            <div className="item-checkbox">
            <input name={v.name} onChange={(e)=>handleCheckChange(v.name,e.target.checked)} type="checkbox" className="check-boxes"/>
            <img src={selected[v.name]?checkedIcon:uncheckedIcon}/>
            </div>
            </div>
            </div>

          )
        })}
      </Collapse> */}
      {/* <div className="toggler cursor-pointer"  onClick={()=>{setProtocol(!protocol);setEcoSystem(false)}}>
            <span>PROTOCOLS</span>
            <img src={protocol ? minusIcon : plusIcon}/>
          </div>
        <Collapse isOpen={protocol}>
        {protocols.map((v,i)=>{
          return(
          <div className="list-item" key={i}>
            <div>
            <div className="item-sub">
            <span>{v.title}</span>
            <div className="item-checkbox">
            <input name={v.name} onChange={(e)=>handleCheckChange(v.name,e.target.checked)} type="checkbox" className="check-boxes"/>
            <img src={selected[v.name]?checkedIcon:uncheckedIcon}/>
            </div>
           
            </div>
            </div>
            </div>

          )
        })}
      </Collapse>
      <center><button className="show-results mt-4" onClick={()=>props.openFilterResults(selected)}>SHOW RESULTS</button></center>
        </div>
        
      </div>}
      </div> */}
      {/* <center className="share-wrap cursor-pointer" id="sharemain" style={{ marginTop:"-20px"}}> */}
        {/* <div id="sharewrap" className="hidden-wrap"> */}
        {/* <a href="mailto:?body=https://solutions.silabs.com/" target="_blank">
      <img className="action-icon" src={shareEmailIcon}/>
      </a><br/> */}

      {/* <a href="https://twitter.com/intent/tweet?text=silabs.com&url=https://solutions.silabs.com/" target="_blank">
      <img className="action-icon" src={shareTwitterIcon}/>
      </a><br/> */}

      {/* <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://solutions.silabs.com/" target="_blank">
      <img className="action-icon" src={shareLinkedIcon}/>
      </a><br/> */}
      {/* </div> */}
      {/* <div id="shareToggler" className="share-toggler" onClick={handleShowShareBar}> */}
      {/* <img className="action-icon" src={shareIcon}/> */}
      {/* <p className="action-text">SHARE</p> */}
      {/* </div> */}
      {/* </center> */}

      {/* <center className="ml-3 cursor-pointer" onClick={() => setShowTutorial(true)}> */}
      {/* <img className="action-icon" src={helpIcon} /> */}
      {/* <p className="action-text">HELP</p> */}
      {/* </center> */}
      <center className="ml-3 cursor-pointer">
        <center>
        <Link to="/about-us"  className="info-text" >
          <img className="action-icon" src={aboutUsIcon} title="About Us" alt="About Us Icon" />
          <p>About Us</p>
        </Link>
        </center>
      </center>

      <center className="ml-3 cursor-pointer">
        <center>
        <Link to="/privacy-policy" className="info-text">
          <img className="action-icon" src={privacyPolicyIcon} title="Privacy Policy" alt="Privacy Policy Icon" />
          <p>Policy</p>
        </Link>
        </center>
      </center>

      <center className="ml-3 cursor-pointer" onClick={toggleSound}>
        <center className="info-text">
          {props.soundOn ? <img className="action-icon" src={volumeIcon} title="Stop sound" alt="Stop sound Icon" /> : <img className="action-icon" src={muteIcon} title="Start sound" alt="Start sound Icon" />}
          <p>Sound</p>
        </center>
      </center>
        </div>
      </div>
      </div>
    // </div>
  );
})

export default ActionBar;
