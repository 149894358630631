import React, { useState, useContext,useRef } from 'react';
import { PanoContext } from '../../contexts/PanoContextContainer';
import { Map } from '../components/svg';
import MapModal from "../mapModal/index";
import floorPlan from '../../assets/icons/floorplan_1.png';

import './style.css';

function MiniMap(props) {
  const { selected, setSelected } = useContext(PanoContext);
  const [ showModal, setShowModal ] = useState(false);
  const childRef = useRef();

  const changeScene=(i)=>{
    props.changeScene(i)
  }

  const closeModal=()=>{
    setShowModal(!showModal)
    childRef.current.closeModals()
  }

  return (
    <div className="mini-map-wrapper">
      <Map currentScene = {props.currentScene} show={showModal} onClick={closeModal}/>
        <div className={`map-modal ${showModal ? 'fadeIn' : 'fadeOut'}`}>
        <MapModal ref={childRef} closeModal = {() => setShowModal(false)} changeScene={changeScene} openInfoSpot={props.openInfoSpot}/>
      </div>
    </div>
  );
}

export default MiniMap;
