import React, { useEffect } from 'react';
import './style.scss';
import minimap from '../../../assets/images/minimap.svg';
import mapCross from '../../../assets/images/map-cross.svg';
// import EntranceMap from '../../../assets/images/Map/Entrance.png'
import KitchenMap from '../../../assets/images/Map/Kitchen.png'
import BathroomMap from '../../../assets/images/Map/Bathroom.png'
import OfficeMap from '../../../assets/images/Map/Office.png'
function Map({onClick, className, show,currentScene}) {
// const [maps,setMaps] = React.useState([EntranceMap,KitchenMap,OfficeMap,BathroomMap])

  return (
    <>
    {/* {show ?
     <img src={mapCross} className={`cross-icon`} onClick={onClick} /> 
    :
    <img src={maps[currentScene]} className='location-icon' onClick={onClick} />
  } */}
    
</>
  );
}

export default Map;
