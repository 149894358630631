import React from "react";
// import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import './style.scss'

const SideBar = ({ isOpen,changeScene,openInfoSpot }) => {
    return(
  <div className={classNames("sidebar", { "is-open": isOpen })} style={{height:(window.innerHeight-87)+"px"}} id="sidebar1">
      <div className="ml-5 sidebar-content">
    <div>
    <div className="mt-4 route-to-experience" onClick={()=>changeScene(0)}>
    <h6 className="sidebar-heading">Home Security</h6>
    </div>
    <p onClick={()=>openInfoSpot(2,0)} className="route-to-infospot mt-2">Motion Sensor</p>
    <p onClick={()=>openInfoSpot(1,0)} className="route-to-infospot">Doorbell Camera</p>
    <p onClick={()=>openInfoSpot(0,0)} className="route-to-infospot">Door Lock</p>
    </div>
    
    <div>
    <div className="mt-4 route-to-experience" onClick={()=>changeScene(1)}>
    <h6 className="sidebar-heading">Home Automation</h6>
    </div>
    <p onClick={()=>openInfoSpot(3,1)} className="route-to-infospot mt-2">LED Bulb</p>
    <p onClick={()=>openInfoSpot(6,1)} className="route-to-infospot">Plug</p>
    <p onClick={()=>openInfoSpot(5,1)} className="route-to-infospot">Switch</p>
    <p onClick={()=>openInfoSpot(4,1)} className="route-to-infospot">Climate Control / HVAC</p>
    <p onClick={()=>openInfoSpot(1,1)} className="route-to-infospot">Shades & Blinds</p>
    <p onClick={()=>openInfoSpot(0,1)} className="route-to-infospot">Smart Speaker</p>
    <p onClick={()=>openInfoSpot(2,1)} className="route-to-infospot">Smoke Sensor</p>
    <p onClick={()=>openInfoSpot(7,1)} className="route-to-infospot">Refrigerator</p>
    </div>

    <div>
    <div className="mt-4 route-to-experience" onClick={()=>changeScene(3)}>
    <h6 className="sidebar-heading">Health</h6>
    </div>
    <p onClick={()=>openInfoSpot(0,3)} className="route-to-infospot mt-2">Smart Toothbrush</p>
    <p onClick={()=>openInfoSpot(2,3)} className="route-to-infospot">Blood Glucose Monitoring</p>
    <p onClick={()=>openInfoSpot(1,3)} className="route-to-infospot">Insulin Pump</p>
    </div>

    <div>
    <div className="mt-4 route-to-experience" onClick={()=>changeScene(2)}>
    <h6 className="sidebar-heading">Protocols & Ecosystems</h6>
    </div>
    <p onClick={()=>openInfoSpot(0,2)} className="route-to-infospot mt-2">Bluetooth</p>
    <p onClick={()=>openInfoSpot(1,2)} className="route-to-infospot">Thread</p>
    <p onClick={()=>openInfoSpot(2,2)} className="route-to-infospot">Wi-Fi</p>
    <p onClick={()=>openInfoSpot(3,2)} className="route-to-infospot">Zigbee</p>
    <p onClick={()=>openInfoSpot(4,2)} className="route-to-infospot">Z-Wave</p>
    <p onClick={()=>openInfoSpot(5,2)} className="route-to-infospot">Matter</p>
    <p onClick={()=>openInfoSpot(6,2)} className="route-to-infospot">Apple HomeKit</p>
    <p onClick={()=>openInfoSpot(7,2)} className="route-to-infospot">SmartThings</p>
    <p onClick={()=>openInfoSpot(8,2)} className="route-to-infospot">Amazon Sidewalk</p>
    <p onClick={()=>openInfoSpot(9,2)} className="route-to-infospot">Google Assistant</p>
    <p onClick={()=>openInfoSpot(10,2)} className="route-to-infospot">Ring</p>
    <p onClick={()=>openInfoSpot(11,2)} className="route-to-infospot">Amazon Alexa</p>
    </div>

    </div>
  </div>
);
}


export default SideBar;
