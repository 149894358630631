import React, { useState } from 'react';
import icon from '../../../assets/images/SL_Logo.svg'
import entrance from '../../../assets/images/Intro/single-01-entrance-inactive.webp'
import kitchen from '../../../assets/images/Intro/single-02-kitchen-inactive.webp'
import bathroom from '../../../assets/images/Intro/single-03-bathroom-inactive.webp'
import office from '../../../assets/images/Intro/single-04-office-inactive.webp'
import entranceHover from '../../../assets/images/Intro/single-01-entrance-active.webp'
import kitchenHover from '../../../assets/images/Intro/single-02-kitchen-active.webp'
import bathroomHover from '../../../assets/images/Intro/single-03-bathroom-active.webp'
import officeHover from '../../../assets/images/Intro/single-04-office-active.webp'
import mapIconYellow from '../../../assets/svg/map-icon-yellow.svg'
import SiLabsLogo from '../../../assets/images//introForm/sil-logo.svg'
import './style.scss';

function Introduction({ handleActionClick, handleComponentClick,handleSubComponentClick }) {
  const [wrapClassName, setWrapClassName] = useState(window.innerWidth < 851 ? 'align-items-center justify-content-between' : 'd-flex align-items-center justify-content-between')
  const [entrancePin, setentrancePin] = useState(false)
  const [kitchenPin, setkitchenPin] = useState(false)
  const [bathroomPin, setbathroomPin] = useState(false)
  const [officePin, setofficePin] = useState(false)


  window.addEventListener("resize", function () {
    setWrapClassName(window.innerWidth < 851 ? 'align-items-center justify-content-between' : 'd-flex align-items-center justify-content-between')
  });

  const onHover = (e, image) => {
    if (e.target.children[0]) {
      e.target.children[0].src = image;
      e.target.children[0].classList.add("fadeIn-use-case")
    }
    else {
      e.target.src = image
      e.target.classList.add("fadeIn-use-case")
    }
  }

  const onMouseLeave = (e, image) => {
    if (e.target.children[0]) {
      e.target.children[0].src = image;
      e.target.children[0].classList.remove("fadeIn-use-case")
    }
    else {
      e.target.src = image;
      e.target.classList.remove("fadeIn-use-case")
    }
  }

  const addPin = (e) => {
    var elem = e.target
    var anim = document.createElement("img")
    anim.className = "vert-move"
    anim.src = mapIconYellow
    elem.appendChild(anim)
  }

  const removePin = (e) => {
    var elem = e.target
    var anim = document.querySelector(".vert-move")
    elem.removeChild(anim)
  }

  return (
    <div className="row intro-root custom-containers welcome-wrap fadeIn-welcome">
      <div className="col-sm-6 content-col">
        <img src={SiLabsLogo}
          className="SIL_Logo-intro"></img>
        <p className="Modernize-Your-Retai ml-3">Develop Smarter Home Products</p>
        <div className="text-container-intro ml-3">
          <p className="Technology-is-changi">Smart Home technology continues to evolve in how it can simplify our lives while also providing security, safety & energy savings. </p>

          <p className="Technology-is-changi">Take this self-guided tour to explore innovative smart home solutions, the various protocols and their unique benefits & how to connect to the ecosystems. Learn how Silicon Labs' simplicity, reliability and robustness products are the solutions of choice for smart home developers, regardless of ecosystem, protocol or application.</p>
        </div>
        <button className="explore-button ml-3" onClick={handleActionClick}>EXPLORE SMART HOME SOLUTIONS</button>
      </div>
      <div className="col-sm-6 explore-col">
        <p className="Explore-by-Use-Case">Explore by Use Case</p>
        <div className="row hover-canvas" onMouseEnter={(e) => { setentrancePin(true) }} onMouseLeave={(e) => {setentrancePin(false) }}>
          <img src={entrancePin ? entranceHover : entrance} className="section-img" />
          <div className="mt-5 ml-5">
          <p className="section-txt" onClick={()=>handleComponentClick(0)}>Home Security</p>
          {entrancePin && <div className="ml-2 hover-text">
            <span className="sub-text" onClick={()=>handleSubComponentClick(2,0)}>Motion Sensor</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(1,0)}>Doorbell Camera</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(0,0)}>Door Lock</span>
          </div>}
          </div>
        </div>

        <div className="row mt-3 hover-canvas" onMouseEnter={(e) => {setkitchenPin(true) }} onMouseLeave={(e) => {setkitchenPin(false) }}>
          <img src={kitchenPin ? kitchenHover : kitchen} className="section-img" />
          <div className="mt-5 ml-5">
          <p className="section-txt" onClick={()=>handleComponentClick(1)}>Home Automation</p>
          {kitchenPin && <div style={{width:"380px"}} className="ml-2 hover-text row">
            <div className="col-7">
            <span className="sub-text" onClick={()=>handleSubComponentClick(3,1)}>LED Bulb</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(6,1)}>Plug</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(5,1)}>Switch</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(4,1)}>Climate Control / HVAC</span>
            </div>
            <div className="col-5">
            <span className="sub-text" onClick={()=>handleSubComponentClick(1,1)}>Shades & Blinds</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(0,1)}>Smart Speaker</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(2,1)}>Smoke Sensor</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(7,1)}>Refrigerator</span>
            </div>
          </div>}
          </div>
        </div>

        <div className="row mt-3 hover-canvas " onMouseEnter={(e) => { setbathroomPin(true) }} onMouseLeave={(e) => {setbathroomPin(false) }}>
          <img src={bathroomPin ? bathroomHover : bathroom} className="section-img" />
          <div className="mt-5 ml-5">
          <p className="section-txt" onClick={()=>handleComponentClick(3)}>Health</p>
          {bathroomPin && <div className="ml-2 hover-text">
            <span className="sub-text" onClick={()=>handleSubComponentClick(0,3)}>Smart Toothbrush</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(2,3)}>Blood Glucose Monitoring</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(1,3)}>Insulin Pump</span>
          </div>}
          </div>
        </div>

        <div className="row mt-3 hover-canvas " onMouseEnter={(e) => {setofficePin(true) }} onMouseLeave={(e) => {setofficePin(false) }}>
          <img src={officePin ? officeHover : office} className="section-img" />
          <div className="mt-5 ml-5">
          <p className="section-txt" onClick={()=>handleComponentClick(2)}>Protocols & Ecosystems</p>
          {officePin && <div style={{width:"400px"}} className="ml-2 hover-text row">
            <div className="col-4">
            <span className="sub-text" onClick={()=>handleSubComponentClick(0,2)}>Bluetooth</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(1,2)}>Thread</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(2,2)}>Wi-Fi</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(3,2)}>Zigbee</span><br/>  
            <span className="sub-text" onClick={()=>handleSubComponentClick(4,2)}>Z-Wave</span><br/>    
            <span className="sub-text" onClick={()=>handleSubComponentClick(5,2)}>Matter</span>   
            </div>
            <div className="col-8">
            <span className="sub-text" onClick={()=>handleSubComponentClick(6,2)}>Apple HomeKit</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(7,2)}>SmartThings</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(8,2)}>Amazon Sidewalk</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(9,2)}>Google Assistant</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(10,2)}>Ring</span><br/>
            <span className="sub-text" onClick={()=>handleSubComponentClick(11,2)}>Amazon Alexa</span>
            
            
            
            </div>
          </div>}
          </div>
        </div>
      </div>

    </div>
    //     <div className="welcome-wrap fadeIn-welcome">
    //       <div className="d-flex">
    //         <img className="icon-image" src={icon} alt="Silicon Lab Icon"/>
    //       </div>

    //       <div className={wrapClassName}>
    //         <div className="add-flex-2 align-self-stretch d-flex flex-column justify-content-between">
    //           <p className="title-text">
    //           Modernize Your <br/>Retail Strategy
    //           </p>

    //           <div className="action-wrap d-flex align-items-center justify-content-between w-50 explore" onClick={handleActionClick} >
    //             <span style={{marginTop:"5px", transform: "translateY(1px)"}}>EXPLORE RETAIL SOLUTIONS</span>
    //             <svg style={{marginTop:"2px"}} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M5.96094 0.816406L5.41406 1.33594C5.27734 1.47266 5.27734 1.69141 5.41406 1.80078L9.65234 6.03906H0.328125C0.136719 6.03906 0 6.20312 0 6.36719V7.13281C0 7.32422 0.136719 7.46094 0.328125 7.46094H9.65234L5.41406 11.7266C5.27734 11.8359 5.27734 12.0547 5.41406 12.1914L5.96094 12.7109C6.07031 12.8477 6.28906 12.8477 6.42578 12.7109L12.1406 6.99609C12.2773 6.85938 12.2773 6.66797 12.1406 6.53125L6.42578 0.816406C6.28906 0.679688 6.07031 0.679688 5.96094 0.816406Z" fill="#555555"/>
    // </svg>

    //           </div>
    //         </div>

    //         <p className="welcome-text add-flex-2">
    //         Technology is changing the way consumers shop, and it can be leveraged to create a seamless customer experience, both in store and online. Having an omnichannel strategy is key for retailers to win in an ever-changing landscape.
    //         <br/>
    //           <br/>
    //           Take this self-guided tour to explore innovative retail solutions, from inventory management to people tracking, that can complete your omnichannel strategy. Learn how Silicon Labs' optimized wireless solutions enable the modernization of retail.        </p>

    //       </div>
    //       <div class="hr-sect">Or Explore By Use Case</div>

    //       <div className="section-container">
    //         <div className="section-img-container">
    //           <img src={inventory} className="section-img" name="inventory" onClick={()=>handleComponentClick(1)} onMouseEnter={(e)=>{onHover(e,inventoryHover);setentrancePin(true)}} onMouseLeave={(e)=>{onMouseLeave(e,inventory);setentrancePin(false)}} alt="Panorama"/>
    //           {entrancePin && <img src={mapIconYellow} className="vert-move"/>}
    //           <p className="section-txt">Inventory Management</p>
    //           {entrancePin && <div className="hover-text">
    //             <p>Electronic Shelf Labels</p>
    //             <p>Loss Prevention</p>
    //             <p>Location Services & Inventory Management</p>
    //             </div>}
    //         </div>

    //         <div className="section-img-container">
    //           <img src={instore} className="section-img" name="instore" onClick={()=>handleComponentClick(2)} onMouseEnter={(e)=>{onHover(e,instoreHover);setkitchenPin(true)}} onMouseLeave={(e)=>{onMouseLeave(e,instore);setkitchenPin(false)}} alt="Panorama Section"/>
    //           {kitchenPin && <img src={mapIconYellow} className="vert-move"/>}
    //           <p className="section-txt">In-Store Utilities</p>
    //           {kitchenPin && <div className="hover-text">
    //             <p>Commercial Lighting</p>
    //             <p>Location Services & Inventory Management</p>
    //             <p>Enterprise / Wireless Access Points</p>
    //             </div>}
    //         </div>

    //         <div className="section-img-container">
    //           <img src={people} className="section-img" name="people" onClick={()=>handleComponentClick(3)} onMouseEnter={(e)=>{onHover(e,peopleHover);setbathroomPin(true)}} onMouseLeave={(e)=>{onMouseLeave(e,people);setbathroomPin(false)}} alt="Preview"/>
    //           {bathroomPin && <img src={mapIconYellow} className="vert-move"/>}
    //           <p className="section-txt">People Tracking</p>
    //           {bathroomPin && <div className="hover-text">
    //             <p>Commercial Lighting</p>
    //             <p>Enterprise / Wireless Access Points</p>
    //             </div>}
    //         </div>

    //         <div className="section-img-container">
    //           <img src={security} className="section-img" name="security" onClick={()=>handleComponentClick(4)} onMouseEnter={(e)=>{onHover(e,securityHover);setofficePin(true)}} onMouseLeave={(e)=>{onMouseLeave(e,security);setofficePin(false)}} alt="Section"/>
    //           {officePin && <img src={mapIconYellow} className="vert-move"/>}
    //           <p className="section-txt">Store Security</p>
    //           {officePin && <div className="hover-text">
    //           <p>Loss Prevention</p>
    //             <p>Enterprise / Wireless Access Points</p>
    //             </div>}
    //         </div>
    //       </div>
    //     </div>
  );
}

export default Introduction;

