import React, { useState, useEffect } from 'react';
import './style.css';
import loadingScreen from '../../../assets/images/hajjlogo2.png';
import { ProgressBar } from 'react-bootstrap';

function Loader() {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => setSelected((prev) => (prev + 20 >= 100 ? 100 : prev + 20)), 500);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="loader">
      <img src={loadingScreen} alt="Loading Screen" width="250px" />
      <ProgressBar now={selected} className="loading-progress" />
    </div>
  );
}

export default Loader;
