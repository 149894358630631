import React from 'react';
import './style.scss';

function Volume({onClick, className, fill}) {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <path className="orange-fill" d="M14.1094 0.640625C13.9219 0.546875 13.6875 0.5 13.5 0.5C13.125 0.5 12.7969 0.640625 12.5156 0.875L6.1875 6.5H2.25C1.03125 6.5 0 7.53125 0 8.75V13.25C0 14.4688 1.03125 15.5 2.25 15.5H6.1875L12.5156 21.125C12.7969 21.3594 13.125 21.5 13.5 21.5C13.6875 21.5 13.9219 21.4531 14.1094 21.3594C14.6719 21.125 15 20.6094 15 20V2C15 1.39062 14.6719 0.875 14.1094 0.640625ZM13.5 20L7.17188 14.375L6.75 14H2.25C1.82812 14 1.5 13.6719 1.5 13.25V8.75C1.5 8.32812 1.82812 8 2.25 8H6.75L7.17188 7.625L13.5 2V20ZM21.5156 3.64062C21.1875 3.40625 20.7188 3.5 20.4844 3.82812C20.25 4.15625 20.3438 4.625 20.7188 4.85938C22.7812 6.26562 24 8.5625 24 11C24 13.4375 22.7812 15.7344 20.7188 17.1406C20.3438 17.375 20.25 17.8438 20.4844 18.1719C20.625 18.4062 20.8594 18.5 21.0938 18.5C21.2812 18.5 21.4219 18.4531 21.5156 18.3594C24 16.7188 25.5 13.9531 25.5 11C25.5 8.04688 24 5.28125 21.5156 3.64062ZM19.0312 7.39062C18.7031 7.15625 18.2344 7.25 18 7.57812C17.7656 7.90625 17.8594 8.375 18.2344 8.60938C19.0312 9.17188 19.5 10.0625 19.5 11C19.5 11.9375 19.0312 12.8281 18.2344 13.3906C17.8594 13.625 17.7656 14.0938 18 14.4219C18.1406 14.6562 18.375 14.75 18.6562 14.75C18.7969 14.75 18.9375 14.7031 19.0312 14.6094C20.25 13.8125 21 12.4531 21 11C21 9.54688 20.25 8.1875 19.0312 7.39062Z" fill={ fill || "white" }/>
    </svg>
  );
}

export default Volume;
