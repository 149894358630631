// src/pages/AboutUs.js
import React, { useState, useEffect } from 'react';
import "../aboutUs/style.scss"
import orientation from '../../../assets/images/about-us-images/Orientation.jpg';
import virtual_experience from '../../../assets/images/about-us-images/Virtual Experience.jpg';
import avatar from '../../../assets/images/about-us-images/Avatar Customization.jpg';
import education from '../../../assets/images/about-us-images/Ritual and education.jpg';
import Navbar from '../../components/navbar';
import ImageSlider from '../../components/imageSlider';
import Footer from '../../components/footer';
import playstore_btn from '../../../assets/images/download_button.png'
import appstore_btn from '../../../assets/images/appstore_btn.png'
import mobile from '../../../assets/images/mobile.png'
import MenuBar from '../sideMenu'

const AboutUs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [bottom, setBottom] = useState(0);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className="about-us-container">
      <div>
        <button className='pop-button' onClick={handleToggleMenu}>Menu</button>
        <MenuBar isOpen={isMenuOpen} onClose={handleCloseMenu} />
      </div>
      <Navbar />
      <div style={{marginTop: 80}}>
        <div className='paragraph-container'>
          <h1 className='heading-container'>Begin Your Spiritual Journey!</h1>
          <div className="main-content">
            <p>
            Experience Hajj and Umrah like never before with Hajj Metaverse - Our Virtual Hajj and Umrah Experience app. Start your journey with a personalized 3D avatar supported by informative pop-ups detailing the historical and spiritual significance
            Perform sacred rituals, explore the holy city, deepen your understanding of Islamic traditions, and have an immersive and educational pilgrimage experience.
            Join us and transform your spiritual practice into a captivating virtual adventure, connecting with the essence of Hajj and Umrah from anywhere in the world!
            </p>
          </div>
        </div>
      </div>
      <div className='about-wrap'>
        <div className='about-tab about-text-wrap'>
          <h1>Welcome and Orientation</h1>
          <p>
          Start your spiritual journey with a <b>warm introduction</b> to the significance of <b>Hajj</b> and <b>Umrah</b>. This phase offers a comprehensive overview of both pilgrimages, detailing their spiritual meanings and historical contexts. 
          You can choose between Hajj and Umrah, each offering unique rituals and experiences. This introduction prepares you mentally and spiritually, helping you understand the importance of each pilgrimage and setting a foundation for your journey.
          </p>
        </div>
        <div className='about-tab about-image-wrap'>
          <img src={orientation} alt="Orientation Image" />
        </div>
      </div>
      <div className='about-wrap'>
        <div className='about-tab about-image-wrap'>
        <img src={virtual_experience} alt="Our Values" />
        </div>
        <div className='about-tab about-text-wrap'>
          <h1>Immersive Virtual Experience</h1>
            <p>
            Immerse yourself in a <b>3D environment</b> created in Mecca, designed to capture its sacred essence with extraordinary detail. 
            Explore iconic landmarks such as the <b>Kaaba</b> and experience the <b>realistic sights and sounds</b> of the holy city. 
            This virtual exploration allows you to walk through the experience of performing <b>Umrah</b> and <b>Hajj</b>, providing a deep sense of connection and devotion. The immersive environment helps you visualise the pilgrimage experience, enhancing your spiritual journey.
            </p>
        </div>
      </div>
      <div className='about-wrap'>
        <div className='about-tab about-text-wrap'>
        <h1>Avatars and Customization</h1>
          <p>
          Personalise your pilgrimage by creating and customising your <b>virtual avatar</b>. Choose from various <b>attire, gender options, and visual elements</b> to customise your experience. 
          Whether you opt for a male or female avatar, you will receive personalized guidance through the pilgrimage, making the experience more relevant and meaningful. 
          This customization increases your immersion, allowing you to engage more deeply with the rituals of Hajj and Umrah.
          </p>
        </div>
        <div className='about-tab about-image-wrap'>
        <img src={avatar} alt="Our Values" />
        </div>
      </div>
      <div className='about-wrap'>
        <div className='about-tab about-image-wrap'>
          <img src={education} alt="Our Values" />
        </div>
        <div className='about-tab about-text-wrap'>
          <h1>Rituals and Educational Knowledge</h1>
            <p>
            Perform the sacred rituals of Hajj and Umrah with detailed, step-by-step instructions. The app guides essential practices such as Tawaf (circumambulation) and Sa’i (walking between Safa and Marwah), using intuitive controls to facilitate your experience. 
            Engage with interactive elements such as high-quality <b>3D graphics, authentic ambient sounds, recitations of Quranic verses</b>, and <b>serene background music</b> to create a rich and immersive spiritual experience. 
            This phase ensures you comprehensively understand the rituals, completing your journey with a deep sense of devotion and connection.
            </p>
        </div>
      </div>
      <div className='paragraph-container' style={{marginTop: 50}}>
          <h1 className='heading-container'>Immerse Yourself In The Spiritual<br/> Journey of Hajj & Umrah</h1>
          <div className="main-content">
            <p>
            Discover the importance of Hajj & Umrah with our app, designed to guide you through each step of these sacred pilgrimage. Our detailed 3D experience allow you to discover iconic landmarks like the Kaaba from the comfort of your home. Customize your journey by creating a personalized avatar and receive step-by-step guidance on performing essential rituals such as Tawaf and Sa’i. With interactive features, educational insights, rich visuals, and authentic sounds, our app offers a meaningful and immersive way to prepare for and connect with the spiritual essence of Hajj and Umrah.
            </p>
          </div>
      </div>
      <div className="content-section" style={{marginBottom: 30}}>
        <ImageSlider />
      </div>
      <div className="content-section app-belt">
        <div className="download-wrapper">
          <div>
            <h3 style={{color: 'white'}}>Get the App Now</h3>
            <p style={{color: 'white'}}>
            Start your pilgrimage experience today. Download our app to explore a detailed 3D model of Mecca and follow easy-to-understand instructions for each ritual. Get the app now and enter an immersive Hajj and Umrah journey.
            </p>
          </div>
          <div className='download-buttons'>
            <div>
              <a href='https://www.google.com' target="_blank">
                <img src={appstore_btn} className='appstore-btn'/>
              </a>
            </div>
            <div className='playstore-div'>
              <a href='https://www.google.com' target="_blank">
                <img src={playstore_btn} className='playstore-btn'/>
              </a>
            </div>
          </div>
        </div>
        <div className="mobile-container">
          <div class="image-wrapper">
            <img src={mobile}  />
          </div>
        </div>
      </div>
      <Footer  />
    </div>
  );
};

export default AboutUs;
