import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import './style.css';

function MovingProgressBar({now,index}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setProgress(progress + 1 >= index*20 ? index*20 : progress + 0.5 ), 125);
    return () => clearTimeout(timer);
  }, [progress]);

  useEffect(() => {
    if (now)
      setProgress(now);
  }, [now])

  return (
    <ProgressBar now={progress} className="tutorial-progress"/>
  );
}

export default MovingProgressBar;
