import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import './style.scss';
import logo from '../../../assets/images/footer_hajj_logo.png';
import menuLogo from "../../../assets/images/hajjlogo.png"


const Navbar = () => {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // const dropdownRef = useRef(null);
  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDropdownOpen(false);
  //   }
  // };

  // const handleDropdownToggle = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const handleRedirect = () => {
    history.push("/index");
    window.location.reload();
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname === "/index") {
        window.location.reload();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <nav className="navbar">
      <div className="logo-responsiveness">
        <img onClick={handleRedirect} src={logo} alt="Hajj Logo" className="logo" />
      </div>
      {/* <div className="dropdown" ref={dropdownRef}>
        <div onClick={handleDropdownToggle} className="hamburger-menu">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        {dropdownOpen && (
        <div>
          <div className="dropdown-menu">
              <div>
                <img src={menuLogo} className="menu-logo" alt="Hajj app LOGO" />
              </div>
            <div className="menu-divider"></div>
              <Link onClick={handleRedirect} className="dropdown-item">Home</Link>
              <div className="menu-divider"></div>
              <Link to="/about-us" className="dropdown-item">About Us</Link>
              <div className="menu-divider"></div>
              <Link to="/privacy-policy" className="dropdown-item">Policy</Link>
              {/* <div className="menu-divider"></div> */}
              {/* <footer className='footer'>© 2024 All rights reserved</footer> */}
          {/* </div> */}
          {/* </div> */}
        {/* )} */}
      {/* </div> */}
    </nav>
  );
};

export default Navbar;
