import React, { useEffect, useState } from 'react'
import SiLabsLogo from '../../../assets/images//introForm/sil-logo.svg'
import IconSuccess from '../../../assets/images//introForm/icons-success.svg'
import IconFail from '../../../assets/images//introForm/icons-fail.svg'
import "./styles.scss"

export default function FormSuccess({handleContinue,handleTryAgain,formFail}){
    return(
        <div className="align-items-center justify-content-center w-100">
        {formFail ? 
          <div className="form-success-root">
          <img src={SiLabsLogo}
 className="SIL_Logo-success"></img>
 <img src={IconFail}
 className="success-icon"></img>
 <p className="Thank-you">Whoops!</p>
 <p className="Weve-successfully-r">We seem to have run into a snag when submitting your form. Please try again.<br/>
If the problem continues, please contact customer service. </p>
<div className="button-div">
<button onClick={handleTryAgain} className="form-status-submit">LET'S TRY THIS AGAIN</button>
</div>
        </div>
        
        
        :
        <div className="form-success-root">
              <img src={SiLabsLogo}
     className="SIL_Logo-success"></img>
     <img src={IconSuccess}
     className="success-icon"></img>
     <p className="Thank-you">Thank you!</p>
     <div className="text-container">
     <p className="Weve-successfully-r">We've successfully recorded your submission & you're ready to enter the Smart Home Virtual Experience.<br/>
     Press the "CONTINUE" button to enter this immersive smart home technology demonstration.</p>
</div>
<div className="button-div">
<button onClick={handleContinue} className="form-status-submit">CONTINUE</button>
</div>
            </div>}
            </div>
    )
}