import React,{useState,useRef} from "react";
import PanoView from "../panoView";
import Welcome from "../welcome";
import MiniMap from "../miniMap";
import ActionBar from "../components/actionBar";
import menuIcon from '../../assets/images/sidebar/icons-menu.svg'
import closeIcon from '../../assets/images/sidebar/icons-close.svg'
import siliconLab from "../../assets/images/SiLabs-logo.svg";
import Sidebar from '../components/sidebar'
import FilterResults from '../filterResults'
import "./style.css";

function Home() {

  const childRef = useRef();
  const [currentScene,setCurrentScene] = useState(0)
  const [isSideBarOpen,setIsSideBarOpen] = useState(false)
  const [isFilterResults,setIsFilterResults] = useState(false)
  const [selected,setSelected] = useState({})
  const actionBarRef = useRef()


  const changeScene=(i)=>{
    childRef.current.changeScene(i)
    setCurrentScene(i)
    setIsSideBarOpen(false)
    setIsFilterResults(false)
    actionBarRef.current.closeFilter()
  }

  const openInfoSpot=(i,j)=>{
    childRef.current.changeScene(j)
    setCurrentScene(j)
    childRef.current.openInfoSpot(i,j)
    setIsSideBarOpen(false)
    setIsFilterResults(false)
    actionBarRef.current.closeFilter()
  }

  const openProductModal=(i,j)=>{
    childRef.current.changeScene(j)
    setCurrentScene(j)
    childRef.current.openProductModal(i,j)
    setIsSideBarOpen(false)
    actionBarRef.current.closeFilter()
  }



  const openFilterResults=(selectedItems)=>{
    setIsFilterResults(true)
    setSelected(selectedItems)
    actionBarRef.current.closeFilter()
  }

  return (
    <div>
      {/* <button className="sidebar-toggle-button" onClick={()=>setIsSideBarOpen(!isSideBarOpen)}>
        {isSideBarOpen ?  
        <img src={closeIcon}/>
        :
        <img src={menuIcon}/>
      }
    
        </button> */}
      <Sidebar isOpen={isSideBarOpen} changeScene={changeScene} openInfoSpot={openInfoSpot}/>
      <MiniMap changeScene={changeScene} currentScene={currentScene} openInfoSpot={openInfoSpot}/>
      <Welcome changeScene={changeScene} openInfoSpot={openInfoSpot}/>
      <PanoView ref={childRef} setCurrent = {setCurrentScene}/>
      {isFilterResults && <FilterResults openProductModal={openProductModal} openInfoSpot={openInfoSpot} selected={selected} closeFilterResults={()=>setIsFilterResults(false)}/>}
      <ActionBar ref={actionBarRef} openFilterResults={openFilterResults}/>
    </div>
  );
}

export default Home;
