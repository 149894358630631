import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import './style.scss';

const PageTransition = ({ children }) => {
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={1000}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default PageTransition;
