import screen from '../../assets/images/Homes 3D/Baab-e-Fahad.jpeg'
import screen1 from "../../assets/images/Homes 3D/Corridor.jpeg";
import screen2 from "../../assets/images/Homes 3D/GreenLight.jpeg";
import screen3 from "../../assets/images/Homes 3D/Green Lights.jpeg";
import screen4 from "../../assets/images/Homes 3D/Marwa.jpeg";
import screen5 from "../../assets/images/Homes 3D/Safa.jpeg";
import screen6 from "../../assets/images/Homes 3D/Kaaba.jpeg";
import screen7 from "../../assets/images/Homes 3D/Hajre Aswad.jpeg";


import doorlock from '../../assets/images/modal-icons/doorlock.png'
import doorbell from '../../assets/images/modal-icons/doorbell.png'
import motionSensor from '../../assets/images/modal-icons/motion-sensor.png'

import glucose from '../../assets/images/modal-icons/glucose.png'
import toothbrush from '../../assets/images/modal-icons/toothbrush.png'
import insulin from '../../assets/images/modal-icons/insulin.png'

import ledBulb from '../../assets/images/modal-icons/led-bulb.png'
import plug from '../../assets/images/modal-icons/plug.png'
import refrigerator from '../../assets/images/modal-icons/refrigerator.png'
import shades from '../../assets/images/modal-icons/shades.png'
import switches from '../../assets/images/modal-icons/switch.png'
import climate from '../../assets/images/modal-icons/climate.png'
import smokeSensor from '../../assets/images/modal-icons/smoke-sensor.png'
import speaker from '../../assets/images/modal-icons/speaker.png'

import homekit from '../../assets/images/modal-icons/homekit.png'
import sidewalk from '../../assets/images/modal-icons/sidewalk.png'
import thread from '../../assets/images/modal-icons/thread.png'
import zigbee from '../../assets/images/modal-icons/zigbee.png'
import zwave from '../../assets/images/modal-icons/zwave.png'
import bluetooth from '../../assets/images/modal-icons/bluetooth.png'
import wifi from '../../assets/images/modal-icons/wifi.png'
import matter from '../../assets/images/modal-icons/matter.png'
import alexa from '../../assets/images/modal-icons/alexa.png'
import assistant from '../../assets/images/modal-icons/assistant.png'
import ring from '../../assets/images/modal-icons/ring.png'
import smartthings from '../../assets/images/modal-icons/smartthings.png'



import primaryDoorlock from '../../assets/images/Modal Images/Solution Modal/doorlock/hero.webp'
import BaabeFahad from '../../assets/images/Modal Images/Solution Modal/doorbell/BaabeFahad.png'
import primaryMotionSensor from '../../assets/images/Modal Images/Solution Modal/motion-sensor/hero.webp'

import primaryGlucose from '../../assets/images/Modal Images/Solution Modal/glucose/hero.webp'
import MaqameIbrahim from '../../assets/images/Modal Images/Solution Modal/MuqamEIbrahim/MuqamEIbrahim.png'
import GreenLight from '../../assets/images/Modal Images/Solution Modal/GreenLight/GreenLight.png'
import primaryInsulin from '../../assets/images/Modal Images/Solution Modal/insulin/hero.webp'

import primaryLedBulb from '../../assets/images/Modal Images/Solution Modal/led-bulb/hero.webp'
import primaryPlug from '../../assets/images/Modal Images/Solution Modal/plug/hero.webp'
import primaryRefrigerator from '../../assets/images/Modal Images/Solution Modal/refrigerator/hero.webp'
import primaryShades from '../../assets/images/Modal Images/Solution Modal/shades/hero.webp'
import primarySwitches from '../../assets/images/Modal Images/Solution Modal/switch/hero.webp'
import primaryClimate from '../../assets/images/Modal Images/Solution Modal/climate/hero.webp'
import primarySpeaker from '../../assets/images/Modal Images/Solution Modal/smart-speaker/hero.webp'
import primarySmokeSensor from '../../assets/images/Modal Images/Solution Modal/smoke-sensor/hero.webp'

import RukaneYamani from '../../assets/images/Modal Images/Solution Modal/RukaneYamani/RukneYamani.png'
import primarySidewalk from '../../assets/images/Modal Images/Solution Modal/sidewalk/hero.png'
import primaryThread from '../../assets/images/Modal Images/Solution Modal/thread/hero.webp'
import Safa from '../../assets/images/Modal Images/Solution Modal/Safa/safa.png'
import primaryAlexa from '../../assets/images/Modal Images/Solution Modal/alexa/hero.png'
import HajreAswad from '../../assets/images/Modal Images/Solution Modal/HajreAswad/HajreAswad.png'
import primaryMatter from '../../assets/images/Modal Images/Solution Modal/matter/hero.webp'
import primaryRing from '../../assets/images/Modal Images/Solution Modal/ring/hero.png'
import primaryWifi from '../../assets/images/Modal Images/Solution Modal/wifi/hero.webp'
import primaryZwave from '../../assets/images/Modal Images/Solution Modal/z-wave/hero.webp'
import primarySmartThings from '../../assets/images/Modal Images/Solution Modal/smartthings/hero.png'
import marwa from '../../assets/images/Modal Images/Solution Modal/Marwa/marwa.png'





import * as THREE from "three";

var width = window.innerWidth


let entrance = {
  solutionModalData1: {
    infoSpot: "Door Lock",
    hotspotType: "product",
    hotspotRoom: "home_security",
    hotspotProduct: "door_lock",
    breadCrum: `HOMES / HOME SECURITY / DOOR LOCK`,
    title: "Access Control with Secure  Door Lock Solutions",
    primaryContent: `Smart door locks enable remote & secure access control for homes. Silicon Labs solutions are specifically designed to help manufacturers deliver long battery life, broad peripheral support capability, & reliable, robust RF even in unfavorable & busy RF environments. Our protocol stacks are built to support the numerous smart home ecosystems to ensure an optimal end user experience.`,
    productHeadline: <span>Learn more about our solutions for Smart Door Locks</span>,
    primaryImage: primaryDoorlock,
    icon: doorlock,
    filterLabels: ["Z-Wave", "Bluetooth", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "IoT Hero Allegion Electrifies Mechanical Products",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-post-iot-hero-allegion-electrifies-mechanical-products-to-strengthen-building-and-home-security.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "OpenThread - Silicon Labs EFR32 Door Lock Example Code",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/openweave-efr32-lock-example-application.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "How to Design a Smart Home Door Lock",
            cta: "EXPLORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/How_to_Design_a_Smart_Home_Door_Lock.pdf",
            isPdf: true
          },
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / HOME SECURITY / DOOR LOCK`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>Fully integrated Z-Wave SiP modules, enabling rapid development. Features a baseband controller, sub-GHz radio transceiver, crystal, decoupling & matching, requiring only an external antenna.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption<li>Up to 512 kB of flash & 64 kB of RAM</li></li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Multiprotocol Support for Zigbee, Thread, & Bluetooth Connectivity",
        breadCrum: `HOMES / HOME SECURITY / DOOR LOCK`,
        title: `Zigbee & Thread EFR32MG13 Module`,
        description: <span>PCB modules for Zigbee, Thread, Bluetooth, or multiprotocol connectivity. These devices minimize development time & costs associated with adding 802.15.4 mesh or multiprotocol connectivity.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Antenna or U.FL variants available</li><li>Wide selection of MCU peripherals, 25 GPIO pins</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg13-series-1-modules/device.mgm13p02f512ga`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4306C: MGM13P02 Zigbee and Thread Module +10 dBm </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4306c-mgm13p02-module-radio-board`,
        filterLabels: ["Zigbee", "Thread", "Bluetooth", "Matter","Apple HomeKit","Google Assistant","Amazon Alexa","SmartThings"],
        isLink: true
      },
      {
        productStatement: "Low Energy & Small Form Factor Module Optimized for Smart Door Locks",
        breadCrum: `HOMES / HOME SECURITY / DOOR LOCK`,
        title: `Bluetooth EFR32BGM13 Module`,
        description: <span>Delivers robust RF performance, low energy consumption, compliance certifications, along with a wide selection of MCU peripherals in a small form factor ideally suited for smart door lock applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Apple HomeKit, Google Assistant & Alexa</li><li>Highest level of IoT Security with Hardware Crypto Accelerator & True Random Number Generator</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg13-series-1-modules/device.bgm13p32f512ga`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4306B: BGM13P32 Bluetooth Module +19 Output Power</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4306b-bgm13p32-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth","Apple HomeKit","Google Assistant","Amazon Alexa"],
        isLink: true
      }, 
    ]
  },

  solutionModalData2: {
    infoSpot: "Bab E Fahad",
    // hotspotType: "product",
    // hotspotRoom: "home_security",
    // hotspotProduct: "doorbell_camera",
    // breadCrum: `HOMES / HOME SECURITY / DOORBELL CAMERA`,
    title: "Enhance Home Security with Smart Doorbell Cameras",
    primaryContent: `Serving as the first interaction point in the home, smart doorbells offer security, convenience, & piece of mind. Silicon Labs offers leading low power & high-performance wireless solutions enabling real-time high-definition video transmission, as well as long range robust mesh network solutions so occupants can both communicate with visitors & open the door, all via their smartphone.`,
    productHeadline: <span>Learn more about our solutions for Smart Doorbells</span>,
    primaryImage: BaabeFahad,
    icon: doorbell,
    filterLabels: ["Bluetooth", "Wi-Fi"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Coming Soon!",
            cta: "",
            assestLink: "comingsoon",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Design Considerations When Developing a Smart Doorbell Camera",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/WxDv-RfM5CA",
            isVideo: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "How To Connect a Smart Doorbell Camera to Other Devices",
            cta: "EXPLORE",
            assestLink: "https://www.youtube.com/embed/nhu5iCb7_OQ",
            isVideo: true
          },
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Certified Wi-Fi Modules Developed for Smart Home Applications",
        breadCrum: `HOMES / HOME SECURITY / DOORBELL CAMERA`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>The fully-certified WGM160 module includes a 72 MHz ARM Cortex-M4 core, providing robust processing capability for running the complete Wi-Fi stack, TCP/IP stack, & your applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["Wi-Fi", "Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      },
      {
        productStatement: "Optimized Wi-Fi System Solution for Battery Life & RF Performance",
        breadCrum: `HOMES / HOME SECURITY / DOORBELL CAMERA`,
        title: `WF200 Series 2 Wi-Fi Transceiver SoC`,
        description: <span>WF200 devices are ideal transceivers for secure IoT Wi-Fi applications. The transceiver contains a 2.4 GHz 802.11 b/g/n radio, optimized for low power & optimal RF performance in crowded RF environments. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Co-existence with other external 2.4 GHz radios supported</li><li>Secure & encrypted host interface communication</li><li>TX power +17 dBm, RX sensitivity -96.3 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wf200-series-2-transceiver-ics/device.wf200`,
        additionalMaterialType: "EXPANSION KIT",
        additionalMaterialTitle: <span>SLEXP8022A: WF200 Wi-Fi </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wf200-wifi-expansion-kit`,
        filterLabels: ["Wi-Fi", "Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      }, {
        productStatement: "Power-Optimized SoC for Wi-Fi & Bluetooth 5 Connectivity",
        breadCrum: `HOMES / HOME SECURITY / DOORBELL CAMERA`,
        title: `Wi-Fi & Bluetooth RS9116 NCP SoC`,
        description: <span>This reliable SoC provides multi-protocol connectivity including 802.11 b/g/n (2.4 GHz), dual-mode Bluetooth 5.  Also featuring high throughput, extended range with power-optimized performance.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple Home Kit & Google Assistant</li><li>The wireless SoCs are FCC, IC, & ETSI/CE certifiable</li><li>Dual external antenna</li><li> Wi-Fi stack, TCP/IP stack, IP modules, Bluetooth stack</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/rs9116-wi-fi-ncp-socs`,
        additionalMaterialType: "DEVELOPMENT KIT",
        additionalMaterialTitle: <span>RS9116X-SB-EVK1: RS9116X Single Band</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/rs9116x-sb-evk-development-kit`,
        filterLabels: ["Wi-Fi", "Bluetooth","Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      },
    ]
  },

  solutionModalData3: {
    infoSpot: "Motion Sensor",
    hotspotType: "product",
    hotspotRoom: "home_security",
    hotspotProduct: "motion_sensor",
    breadCrum: `HOMES / HOME SECURITY / MOTION SENSOR`,
    title: "Motion Sensors for Home Automation & Security",
    primaryContent: `Battery life & transmission range are key considerations when selecting wireless solutions for smart home motion sensors. Our low-power products offer best-in-class battery life & multiprotocol functionality that can connect to the major ecosystems, including Z-Wave Long Range which is accelerating the adoption of connected devices beyond the smart home with its 1 mile range. `,
    productHeadline: <span>Learn more about our solutions for Motion Sensors</span>,
    primaryImage: primaryMotionSensor,
    icon: motionSensor,
    filterLabels: ["Z-Wave", "Zigbee","Thread"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Enabling Secure Home Automation with Ecolink",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/Hodx_mpJSmQ",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Designing Sensors into Battery-Powered IoT Nodes",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/whitepaper-designing-sensors-into-battery-powered-iot-nodes.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "The Anatomy of an IoT Sensor",
            cta: "LEARN MORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/A_Smart_Sensor_Design_and_Anatomy_Lesson.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Sub-GHz SiP Modules with Z-Wave Long-Range Capabilities",
        breadCrum: `HOMES / HOME SECURITY / MOTION SENSOR`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>Fully integrated Z-Wave modules pave the way, for rapid development. A baseband controller, sub-GHz radio transceiver, crystal, decoupling, & matching are included, requiring only an external antenna.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Smart Start, Z-Wave S2 security standard & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Low-Energy Zigbee, Thread, & Bluetooth SoC All in One Device",
        breadCrum: `HOMES / HOME SECURITY / MOTION SENSOR`,
        title: `Zigbee & Thread EFR32MG13 SoC`,
        description: <span>The EFR32MG13 provides an excellent link budget for greater range & reliability. Low-energy techniques, fast wake-up times, & energy saving modes reduce power consumption & maximizes battery life.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Highest level of IoT Security with Hardware Crypto Accelerator & True Random Number Generator<li>Up to 512 kB of flash & 64 kB of RAM</li></li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg13-series-1-socs/device.efr32mg13p733f512gm48`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6005A: EFR32MG12 Dual Band</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/efr32mg12-dual-band-starter-kit`,
        filterLabels: ["Zigbee", "Bluetooth", "Thread", "Matter","Apple HomeKit","Google Assistant","SmartThings","Amazon Alexa"],
        isLink: true
      },
      {
        productStatement: "Robust RF Module That Connects to Bluetooth LE & Bluetooth Mesh",
        breadCrum: `HOMES / HOME SECURITY / MOTION SENSOR`,
        title: `Zigbee & Thread EFR32MG13 SoC`,
        description: <span>Develop fast, cost-effective IoT sensors with the pre-certified, antenna-integrated BGM22 module. Integrated security features provide fast encryption, secure boot loading, & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Apple HomeKit, Google Assistant & Alexa</li><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220sc22hna`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth","Apple HomeKit","Google Assistant","Amazon Alexa"],
        isLink: true
      },
    ]
  }
}


let kitchen = {
  solutionModalData1: {
    infoSpot: "Smart Speaker",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "smart_speaker",
    breadCrum: `HOMES / HOME AUTOMATION / SMART SPEAKER`,
    title: "Handsfree Home Automation with Smart Speakers",
    primaryContent: `Smart speakers are controlled by spoken commands & capable of streaming audio content, answering questions, & communicating with other devices in a smart home network. Silicon Labs multiprotocol solutions are compatible with a broad range of smart home devices & ecosystems so developers can accelerate project timelines & reduce costs.`,
    productHeadline: <span>Learn more about our solutions for Smart Speakers</span>,
    primaryImage: primarySpeaker,
    icon: speaker,
    filterLabels: ["Bluetooth", "Wi-Fi", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Market Opportunity for Smart Speakers",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/MNrjKRuzSo0",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Benefits to Have a Smart Speaker in a Smart Home Ecosystem",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/1nwTt6TJ_Dk",
            isVideo: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Design Considerations for Developing a Smart Speaker",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/BGIadV70gVk",
            isVideo: true
          },
          {
            title: "Choosing the Right Protocols & Ecosystems for a Smart Speaker",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/tmIWgNL9u68",
            isVideo: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / SMART SPEAKER`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>Featuring an 80 MHz ARM Cortex-M33 core, these SoCs bring plenty of processing capability as well as reliable communications for Zigbee, Thread, & multiprotocol applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Optimized Wi-Fi System Solution for Battery Life & RF Performance",
        breadCrum: `HOMES / HOME AUTOMATION / SMART SPEAKER`,
        title: `WF200 Series 2 Wi-Fi Transceiver SoC`,
        description: <span>WF200 devices are ideal transceivers for secure IoT Wi-Fi applications. Featuring a 2.4 GHz 802.11 b/g/n radio, these SoCs are optimized for low power & optimal RF performance in crowded RF environments. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant.</li><li>Co-existence with other external 2.4 GHz radios supported</li><li>Secure & encrypted host interface communication</li><li>TX power +17 dBm, RX sensitivity -96.3 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wf200-series-2-transceiver-ics/device.wf200`,
        additionalMaterialType: "EXPANSION KIT",
        additionalMaterialTitle: <span>SLEXP8022A: WF200 Wi-Fi </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wf200-wifi-expansion-kit`,
        filterLabels: [ "Amazon Alexa", "Wi-Fi","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      }, {
        productStatement: "Power-optimized SoC Provides Wi-Fi & Bluetooth 5 Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / SMART SPEAKER`,
        title: `Wi-Fi & Bluetooth RS9116 NCP SoC`,
        description: <span>These reliable SoCs provides multiprotocol wireless connectivity including 802.11 b/g/n (2.4 GHz) dual-mode Bluetooth 5. Featuring high throughput & extended range with power-optimized performance. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple Home Kit & Google Assistant</li><li>The wireless SoCs are FCC, IC, & ETSI/CE certifiable</li><li>Dual external antenna; Wi-Fi stack, TCP/IP stack, IP modules, Bluetooth stack</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/rs9116-wi-fi-ncp-socs`,
        additionalMaterialType: "DEVELOPMENT KIT",
        additionalMaterialTitle: <span>RS9116X-SB-EVK1: RS9116X Single Band Wi-Fi + Bluetooth</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/rs9116x-sb-evk-development-kit`,
        filterLabels: [ "Bluetooth","Amazon Alexa", "Wi-Fi","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },

  solutionModalData2: {
    infoSpot: "Shades & Blinds",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "shades_and_blinds",
    breadCrum: `HOMES / HOME AUTOMATION / SHADES & BLINDS`,
    title: "Save Energy with Smart Shades & Blinds",
    primaryContent: `Smart shades & blinds enable automation, leading to higher comfort, privacy & energy savings. Silicon Labs addresses the design challenges for battery powered shades/blinds by providing leading low-power performance for long battery life & low maintenance devices.`,
    productHeadline: <span>Learn more about our solutions for Shades & Blinds</span>,
    primaryImage: primaryShades,
    icon: shades,
    filterLabels: ["Z-Wave", "Wi-Fi", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Smart Shades and Blinds Market Overview & Use Case",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/smart-shades-and-blinds-overview-and-use-case.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Smart Shades and Blinds Benefits & Ecosystem Integration",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blinds-and-shades-benefits-and-ecosystem-integration.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Designing for the Smart Home",
            cta: "READ NOW",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Designing-for-the-Smart-Home.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / HOME AUTOMATION / SHADES & BLINDS`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>This baseband controller features an integrated sub-GHz radio transceiver & crystal, offering decoupling & matching to provide a complete Z-Wave SiP module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / SHADES & BLINDS`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs features an integrated sub-GHz radio transceiver & crystal, offering decoupling & matching to provide a complete SiP module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption, secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      }, {
        productStatement: "Certified Wi-Fi Modules Developed for Smart Home Applications",
        breadCrum: `HOMES / HOME AUTOMATION / SHADES & BLINDS`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>The fully-certified WGM160 module includes a 72 MHz ARM Cortex-M4 core, providing robust processing capability for running the complete Wi-Fi stack, TCP/IP stack, & your applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["Amazon Alexa", "Wi-Fi","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },

  solutionModalData3: {
    infoSpot: "Smoke Sensor",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "smoke_sensor",
    breadCrum: `HOMES / HOME AUTOMATION / SMOKE SENSOR`,
    title: "Improve Home Safety with Smart Smoke Sensors",
    primaryContent: `A smart home with safety installations such as smoke detection provides peace of mind. Silicon Labs addresses the design requirements in the sensor segment through product offerings that feature secure robust connectivity, small form factors, low power consumption, & ease of use. `,
    productHeadline: <span>Learn more about our solutions for Smoke Sensors</span>,
    primaryImage: primarySmokeSensor,
    icon: smokeSensor,
    filterLabels: ["Z-Wave", "Bluetooth", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Smart Smoke Sensor Market Overview & Use Case",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/smart-smoke-sensor-overview-and-use-case.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Designing Sensors into Battery-Powered IoT Nodes",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/whitepaper-designing-sensors-into-battery-powered-iot-nodes.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "The Anatomy of an IoT Sensor",
            cta: "LEARN MORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/A_Smart_Sensor_Design_and_Anatomy_Lesson.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / HOME AUTOMATION / SMOKE SENSOR`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>This integrated baseband controller features a sub-GHz transceiver & crystal to provide decoupling & matching in a complete Z-Wave SiP module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Low Energy SoC for Zigbee, Thread, & Bluetooth in One Device",
        breadCrum: `HOMES / HOME AUTOMATION / SMOKE SENSOR`,
        title: `Zigbee & Thread EFR32MG13 SoC`,
        description: <span>This SoC provides an excellent link budget for greater range & reliable RF communications. Also features low energy & fast wake-up modes to reduce overall power consumption & maximize battery life.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Highest level of IoT Security with Hardware Crypto Accelerator & True Random Number Generator</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg13-series-1-socs/device.efr32mg13p733f512gm48`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6005A: EFR32MG12 Dual Band</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/efr32mg12-dual-band-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter","Bluetooth"],
        isLink: true
      }, {
        productStatement: "Secure RF Module for Bluetooth LE & Bluetooth Mesh",
        breadCrum: `HOMES / HOME AUTOMATION / SMOKE SENSOR`,
        title: `EFR32BGM22 Series 2 Modules`,
        description: <span>Develop fast, cost-effective IoT sensors with this pre-certified, antenna-integrated BGM22 module. Featuring fast encryption, secure boot loading, & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Apple HomeKit, Google Assistant & Alexa</li><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,        
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220sc22hna`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Amazon Alexa","Google Assistant","Apple Homekit","Bluetooth"],
        isLink: true
      },
    ]
  },

  solutionModalData4: {
    infoSpot: "LED Bulb",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "led_bulb",
    breadCrum: `HOMES / HOME AUTOMATION / LED BULB`,
    title: "Secure, High-Temperature Lighting Solutions",
    primaryContent: `In the smart home, connected, energy-efficient LED lighting can generate huge savings & personalize living spaces. Take advantage of our secure wireless platform that features high-performance modules & a broad portfolio of certified wireless protocol stacks, including Zigbee, Thread, Bluetooth, & Z-Wave.`,
    productHeadline: <span>Learn more about our solutions for LED Bulbs</span>,
    primaryImage: primaryLedBulb,
    icon: ledBulb,
    filterLabels: ["Z-Wave", "Bluetooth", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "IoT Hero Nanoleaf Breaks Down Barriers Through Smart Lighting",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-post-iot-hero-nanoleaf-breaks-down-barriers-between-doing-good-looking-good-and-feeling-good.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Flipping the Switch on Smart Connected Lighting",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/referenced/white-papers/smart-connected-lighting.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "How to Build a Smart LED Bulb Design",
            cta: "EXPLORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/How_to_Build_a_Smart_LED_Bulb_Design.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Bluetooth Low Energy Solution optimized for LED Bulbs",
        breadCrum: `HOMES / HOME AUTOMATION / LED BULB`,
        title: `EFR32BG21 Series 2 Bluetooth SoC`,
        description: <span>Extended temperature rating & small form factor optimized for the unique needs of smart LED lightbulbs. The module delivers unparalleled RF performance & energy consumption compliance with CA Title 20.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Apple HomeKit, Google Assistant & Alexa</li><li>Highest level of IoT Security - PSA L2 certified Secure Vault</li><li>Compatible with Bluetooth 5, Bluetooth 5.1 & Bluetooth mesh specification</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg21-series-2-modules/device.bgm210la22jif`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6023A: EFR32xG21 Bluetooth</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-bluetooth-starter-kit`,
        filterLabels: ["Amazon Alexa", "Bluetooth", "Google Assistant", "Apple Homekit"],
        isLink: true
      },
      {
        productStatement: "Zigbee, Thread & Bluetooth Multiprotocol Solution for LED Bulbs",
        breadCrum: `HOMES / HOME AUTOMATION / LED BULB`,
        title: `EFR32MG21 Series 2 Multiprotocol SoC`,
        description: <span>Extended temperature rating & small form factor makes the Series 2 well suited for the unique needs of smart LED lightbulbs. The module delivers unparalleled RF performance & energy consumption compliance with CA Title 20.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Highest level of IoT Security - PSA L2 certified Secure Vault</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-modules/device.mgm210la22jif`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6102A: xGM210P Wireless Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/xgm210p-wireless-starter-kit`,
        filterLabels: ["Matter", "Bluetooth", "Thread", "Zigbee","Google Assistant", "Apple Homekit","SmartThings","Amazon Alexa"],
        isLink: true
      }, {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / HOME AUTOMATION / LED BULB`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>A baseband controller, sub-GHz radio transceiver, crystal, decoupling & matching are included to provide a complete Z-Wave system-in-package module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings </li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700 </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
    ]
  },

  solutionModalData5: {
    infoSpot: "Climate Control/HVAC",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "climate_control",
    breadCrum: `HOMES / HOME AUTOMATION / CLIMATE CONTROL/HVAC`,
    title: "Climate Control with Wireless HVAC Solutions",
    primaryContent: `Smart HVAC systems provide users with remote climate control capabilities & energy-saving value. Users can access these benefits by using thermostats that learn user habits & sensor-detected heating zones to avoid inefficiencies. Silicon Labs provides Wi-Fi, 15.4 & sub-GHz solutions for all HVAC systems & integration with any ecosystem.`,
    productHeadline: <span>Learn more about our solutions for Smart HVAC Systems</span>,
    icon:climate,
    primaryImage: primaryClimate,
    filterLabels: ["Bluetooth", "Wi-Fi", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Leading Companies in HVAC Market",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/2d3o98d9Thw",
            isVideo: true
          },
          {
            title: "Overview of Market Opportunity for Smart HVAC Systems",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/MaslQlcA8zk",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Benefits of an HVAC Climate Control System in a Smart Home",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/juWxqn-2d5k",
            isVideo: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Design Considerations in HVAC System Development",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/sw6STTTh3wY",
            isVideo: true
          },
          {
            title: "Protocols & Ecosystems for Smart HVAC System",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/CzjIXzKYLeA",
            isVideo: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
         breadCrum: `HOMES / HOME AUTOMATION / CLIMATE CONTROL/HVAC`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>Versatile SoCs include an 80 MHz ARM Cortex-M33 for outstanding processing along with reliable communications for Zigbee, Thread, & multiprotocol applications. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption, secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "SmartThings", "Thread", "Zigbee","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
        {
          productStatement: "Fully-Integrated & Certified Module Supporting Multiple Protocols",
           breadCrum: `HOMES / HOME AUTOMATION / CLIMATE CONTROL/HVAC`,
          title: `Zigbee & Thread EFR32MG12 Module`,
          description: <span>This module is optimized for multiprotocol applications. The fully-certified modules integrate the crystal, passive RF components, & an antenna required for a system-level implementation of Zigbee, Thread, & Bluetooth networks.</span>,
          keyFeatures: <ul class="color-gray pl-20"><li>Works with Matter, Apple HomeKit & Google Assistant</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
          productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
          additionalMaterialType: "RADIO BOARD",
          additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
          cta: `BUY NOW`,
          linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
          filterLabels: ["Amazon Alexa", "SmartThings","Bluetooth", "Thread", "Zigbee","Google Assistant","Apple Homekit"],
          isLink: true
        }, {
        productStatement: "Power-Optimized SoC Provides Wi-Fi & Bluetooth 5 Connectivity",
         breadCrum: `HOMES / HOME AUTOMATION / CLIMATE CONTROL/HVAC`,
        title: `Wi-Fi & Bluetooth RS9116 NCP SoC`,
        description: <span>Reliable, power-friendly SoCs provides multiprotocol wireless connectivity including 802.11 b/g/n (2.4 GHz) & dual-mode Bluetooth 5. Featuring high throughput, extended range with power-optimized performance.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple Home Kit & Google Assistant</li><li>The wireless SoCs are FCC, IC, & ETSI/CE certifiable</li><li>Dual external antenna</li><li>Wi-Fi stack, TCP/IP stack, IP modules, Bluetooth stack</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/rs9116-wi-fi-ncp-socs`,
        additionalMaterialType: "DEVELOPMENT KIT",
        additionalMaterialTitle: <span>RS9116X-SB-EVK1: RS9116X Single Band Wi-Fi + Bluetooth</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/rs9116x-sb-evk-development-kit`,
        filterLabels: ["Amazon Alexa", "SmartThings","Bluetooth","Google Assistant","Apple Homekit","Matter","Wi-Fi"],
        isLink: true
      },
    ]
  },

  solutionModalData6: {
    infoSpot: "Switch",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "switch",
    breadCrum: `HOMES / HOME AUTOMATION / SWITCH`,
    title: "Wireless Solutions for Smart Switches",
    primaryContent: `Silicon Labs provides switch-specific solutions for all types of wireless switches, including plug-in, in-wall, remotes, & battery-powered appliance switches with on/off, dimming, metering, & lighting control features. These solutions offer easy device commissioning & robust RF performance with range to the home's edge, broad peripheral support, & integration into any smart home ecosystem.`,
    productHeadline: <span>Learn more about our solutions for Smart Switches</span>,
    primaryImage: primarySwitches,
    icon: switches,
    filterLabels: ["Z-Wave", "Wi-Fi", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Smart Switch Market Overview & Use Case",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/smart-switch-overview-and-use-case.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Building a Light Switch with Zigbee Green Power",
            cta: "READ NOW",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Energy_Harvesting_Switch_Reference_Design.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Considerations for Smart Light Switch Development",
            cta: "EXPLORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Considerations_for_Smart_Light_Switch_Development.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long-Range Module",
        breadCrum: `HOMES / HOME AUTOMATION / SWITCH`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>The ZGM130S is a baseband controller with an integrated sub-GHz radio transceiver with decoupling & matching capabilities to provide a complete Z-Wave SiP module requiring only an external antenna.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / SWITCH`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs include an 80 MHz ARM Cortex-M33 core, providing outstanding processing capability & reliable communications for Zigbee, Thread, & multiprotocol applications. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Matter, Apple HomeKit & Google Assistant</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["SmartThings", "Amazon Alexa", "Thread", "Zigbee", "Google Assistant","Apple Homekit","Matter"],
        isLink: true
      }, {
        productStatement: "Certified Wi-Fi Modules Developed for Smart Home Applications",
        breadCrum: `HOMES / HOME AUTOMATION / SWITCH`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>The fully-certified WGM160 module includes a 72 MHz ARM Cortex-M4 core, providing robust processing capability for running the complete Wi-Fi stack, TCP/IP stack, & your applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["SmartThings", "Amazon Alexa","Google Assistant","Apple Homekit","Matter","SmartThings","Wi-Fi"],
        isLink: true
      },
    ]
  },

  solutionModalData7: {
    infoSpot: "Plug",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "plug",
    breadCrum: `HOMES / HOME AUTOMATION / PLUG`,
    title: "Bring Connectivity to Unconnected Devices",
    primaryContent: `Smart Plugs make it possible to add smart remote control & automation capabilities to any product that uses a standard home power outlet. Our small form factor SoCs & modules are pre-certified for all major Smart Home protocols & removes the burden of certification to reduce development time & get to market quicker. `,
    productHeadline: <span>Learn more about our solutions for Plugs</span>,
    primaryImage: primaryPlug,
    icon: plug,
    filterLabels: ["Z-Wave", "Wi-Fi", "Thread", "Zigbee"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Smart Plug Market Overview & Use Case",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/smart-plug-overview-and-use-case.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Smart Plug Benefits & Ecosystem Integration",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/smart-plug-benefits-and-ecosystem-integration.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Considerations for Smart Light Switch Development",
            cta: "EXPLORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Considerations_for_Smart_Light_Switch_Development.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long-Range Module",
        breadCrum: `HOMES / HOME AUTOMATION / PLUG`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>The ZGM130S offers a sub-GHz radio transceiver & crystal, & enables decoupling & matching to provide a complete Z-Wave SiP module requiring only an external antenna.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "SmartThings","Ring"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / PLUG`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs include an 80 MHz ARM Cortex-M33, providing outstanding processing capability, reliable communications for Zigbee, Thread, & multiprotocol applications, as well as greater range & reliability.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Matter, Apple HomeKit & Google Assistant</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "SmartThings","Ring", "Thread", "Zigbee","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      }, {
        productStatement: "Certified Wi-Fi Modules Developed for Smart Home Applications",
        breadCrum: `HOMES / HOME AUTOMATION / PLUG`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>The fully-certified WGM160 module includes a 72 MHz ARM Cortex-M4 core, providing robust processing capability for running the complete Wi-Fi stack, TCP/IP stack, & your applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["Amazon Alexa", "SmartThings","Google Assistant","Apple Homekit","Matter","Wi-Fi"],
        isLink: true
      },
    ]
  },

  solutionModalData8: {
    infoSpot: "Refrigerator",
    hotspotType: "product",
    hotspotRoom: "home_automation",
    hotspotProduct: "refrigerator",
    breadCrum: `HOMES / HOME AUTOMATION / REFRIGERATOR`,
    title: "Smart Inventory with Connected Refrigerators",
    primaryContent: `Connected refrigerators offer a wide range of benefits, ranging from notifications when the door has been left open to procurement of disposables like replacement of the water filter. Silicon Labs addresses these requirements with its small form-factor & multiprotocol SoCs or modules. Offered products stand out through best-in-class security & unbeatable RF performance.`,
    productHeadline: <span>Learn more about our solutions for Smart Refrigerators</span>,
    primaryImage: primaryRefrigerator,
    icon: refrigerator,
    filterLabels: ["Wi-Fi", "Bluetooth"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Coming Soon!",
            cta: "",
            assestLink: "comingsoon",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Benefits of a Connected Refrigerator in a Smart Home",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/jrJO8LQ_s7M ",
            isVideo: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Design Considerations When Developing a Smart Refrigerator",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/FryXED82aNE",
            isVideo: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Fully-Certified Wi-Fi Module Developed for Smart Home Applications",
        breadCrum: `HOMES / HOME AUTOMATION / REFRIGERATOR`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>Fully-certified WGM160 modules include a 72 MHz ARM Cortex-M4 core to efficiently run complete Wi-Fi, TCP/IP, & application stacks. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["SmartThings", "Amazon Alexa", "Google Assistant","Apple Homekit","Matter","Wi-Fi"],
        isLink: true
      },
      {
        productStatement: "Optimized Wi-Fi System Solution for Battery Life & RF Performance",
        breadCrum: `HOMES / HOME AUTOMATION / REFRIGERATOR`,
        title: `WF200 Series 2 Wi-Fi Transceiver SoC`,
        description: <span>WF200 devices are ideal transceivers for secure IoT Wi-Fi applications. Features a 2.4 GHz 802.11 b/g/n radio & optimized for low power performance in crowded RF environments.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Co-existence with other external 2.4 GHz radios supported</li><li>Secure & encrypted host interface communication</li><li>TX power +17 dBm, RX sensitivity -96.3 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wf200-series-2-transceiver-ics/device.wf200`,
        additionalMaterialType: "EXPANSION KIT",
        additionalMaterialTitle: <span>SLEXP8022A: WF200 Wi-Fi </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wf200-wifi-expansion-kit`,
        filterLabels: ["Wi-Fi", "Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      },  {
        productStatement: "Power-optimized SoC Provides Wi-Fi & Bluetooth 5 Connectivity",
        breadCrum: `HOMES / HOME AUTOMATION / REFRIGERATOR`,
        title: `Wi-Fi & Bluetooth RS9116 NCP SoC`,
        description: <span>These reliable SoCs provide multiprotocol wireless connectivity including 802.11 b/g/n (2.4 GHz) dual-mode Bluetooth 5. Featuring high throughput & extended range with power-optimized performance. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple Home Kit & Google Assistant</li><li>The wireless SoCs are FCC, IC, & ETSI/CE certifiable</li><li>Dual external antenna; Wi-Fi stack, TCP/IP stack, IP modules, Bluetooth stack</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/rs9116-wi-fi-ncp-socs`,
        additionalMaterialType: "DEVELOPMENT KIT",
        additionalMaterialTitle: <span>RS9116X-SB-EVK1: RS9116X Single Band Wi-Fi + Bluetooth</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/rs9116x-sb-evk-development-kit`,
        filterLabels: [ "Bluetooth","Amazon Alexa", "Wi-Fi","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  }
}

let office = {
  solutionModalData1: {
    infoSpot: "Marwa",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "bluetooth",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / BLUETOOTH`,
    title: "Works With Bluetooth",
    primaryContent: `Our portfolio includes the latest Bluetooth low energy (LE) & Bluetooth Mesh standards for a variety of applications. We offer an extensive range of SoCs, modules, & software tools designed specifically for ease of use. We developed the world's smallest Bluetooth system-in-package (SiP), enabling you to add Bluetooth to designs quickly while meeting all regulatory certifications.`,
    productHeadline: <span>Learn more about our solutions for Bluetooth</span>,
    primaryImage: marwa,
    icon: bluetooth,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Bluetooth Car Seat Sensor Increases Safety",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/bluetooth-car-seat-sensor-increases-safety.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Features & Benefits of Bluetooth Connectivity",
            cta: "READ NOW",
            assestLink: "http://pages.silabs.com/rs/634-SLU-379/images/silicon-labs-features-and-benefits-of-bluetooth-connectivity.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Bluetooth SoC & Module Selector Guide",
            cta: "LEARN MORE",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Bluetooth_Chipset_Selector_Guide-Silicon_Labs.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Low Energy SoC Optimized for Battery Powered IoT Applications",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / BLUETOOTH`,
        title: `EFR32BG22 Series 2 Bluetooth SoC`,
        description: <span>The BG22 SoCs include a 76.8 MHz ARM Cortex-M33 core, providing plenty of processing capability, along with integrated security features including fast encryption, secure boot loading, & debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 352 kB of flash & 32 kB of RAM</li><li>Energy-efficient radio core with Bluetooth 5.2 low active & sleep currents</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-socs/device.efr32bg22c222f352gm32`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      },
      {
        productStatement: "Speed-Up Development of IoT Devices with Pre-Certified Modules",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / BLUETOOTH`,
        title: `EFR32BGM22 Series 2 Modules`,
        description: <span>Develop fast, cost-effective, & portable medical devices with the pre-certified, antenna-integrated BGM22 module. Security features provide fast encryption, secure boot loading & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Up to 8 dBm TX power & -98.9 dBm BLE RX sensitivity at 1 Mbps</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220pc22hna`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz +6 Output Power</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      }
    ]
  },

  solutionModalData2: {
    infoSpot: "Thread",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "thread",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / THREAD`,
    title: "Works With Thread",
    primaryContent: `Thread solves the challenges of IP connectivity from the edge to the cloud by leveraging self-healing mesh features, native IPv6-based connectivity & state-of-the art security with a multitude of application-layer choices for connected devices. We help accelerate time-to-market with proven mesh networking hardware & certified software solutions using the OpenThread open-source implementation.`,
    productHeadline: <span>Learn more about our solutions for Thread</span>,
    primaryImage: primaryThread,
    icon: thread,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Ushering in a New Era with Thread Networking Protocol",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/whitepaper-ushering-in-a-new-era-of-internet-connectivity-with-thread-networking-protocol.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Hands-On Tutorial with OpenThread",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/training/wireless/hands-on-tutorial-with-openthread.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Silicon Labs OpenThread QuickStart Guide",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/quick-start-guides/qsg170-openthread-sdk-quick-start-guide.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Fully-Integrated, Certified Module Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / THREAD`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>Optimized for multiprotocol applications. It comes fully-certified & featuring the integrated components required for implementation of Zigbee, Thread, & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Matter","Bluetooth", "Thread", "Zigbee"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / THREAD`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs include an 80 MHz ARM Cortex-M33  core & are ideally suited for Zigbee, Thread, & multiprotocol applications requiring longer range & reliability.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },
  solutionModalData3: {
    infoSpot: "Wi-Fi",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "wifi",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / WI-FI`,
    title: "Works With Wi-Fi",
    primaryContent: `Our Wi-Fi solutions address the challenge of providing secure IP & cloud connectivity on existing wireless home networks. With built-in advanced security features, our low-power ICs & pre-certified modules are designed to extend battery life for a wide variety of applications.`,
    productHeadline: <span>Learn more about our solutions for Wi-Fi</span>,
    primaryImage: primaryWifi,
    icon: wifi,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Using Wi-Fi for Low Power IoT Applications",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/using-wi-fi-for-low-power-iot-applications.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Driving Wi-Fi Based Connectivity for Low-Power IoT Applications",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/whitepaper-driving-wi-fi-based-connectivity-for-low-power-iot-applications.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Overview of Silicon Labs Wi-Fi Solutions",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/tech-talks-overview-of-silicon-labs-wi-fi-solutions.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Certified Wi-Fi Module for Smart Home Application Development",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS /  WI-FI`,
        title: `WGM160 Series 1 Wi-Fi Module`,
        description: <span>The fully-certified WGM160 module includes a 72 MHz ARM Cortex-M4 core, providing robust processing capability for running the complete Wi-Fi stack, TCP/IP stack, & your applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Up to 2048 kB of flash & 512 kB of RAM</li><li>Available with integrated chip antenna or an RF pin</li><li>TX power +16 dBm, RX sensitivity -95.5 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wgm160-series-1-modules`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6121A: WGM160P Wi-Fi Module</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wgm160p-wifi-module-starter-kit`,
        filterLabels: ["Wi-Fi", "Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      },
      {
        productStatement: "Optimized Wi-Fi SoC for Extended Battery Life & RF Performance",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS /  WI-FI`,
        title: `WF200 Series 2 Wi-Fi Transceiver SoC`,
        description: <span>WF200 devices are ideal transceivers for secure IoT Wi-Fi applications. The transceiver features a 2.4 GHz 802.11 b/g/n radio, optimized for low power & optimal RF performance in crowded RF environments. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple HomeKit & Google Assistant</li><li>Co-existence with other external 2.4 GHz radios supported</li><li>Secure & encrypted host interface communication</li><li>TX power +17 dBm, RX sensitivity -96.3 dBm</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/wf200-series-2-transceiver-ics/device.wf200`,
        additionalMaterialType: "EXPANSION KIT",
        additionalMaterialTitle: <span>SLEXP8022A: WF200 Wi-Fi </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/wf200-wifi-expansion-kit`,
        filterLabels: ["Wi-Fi", "Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      }, {
        productStatement: "Power-Optimized SoC for Wi-Fi & Bluetooth 5 Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS /  WI-FI`,
        title: `Wi-Fi & Bluetooth RS9116 NCP SoC`,
        description: <span>This reliable SoC provides multi-protocol connectivity including 802.11 b/g/n (2.4 GHz), dual-mode Bluetooth 5.  Also featuring high throughput, extended range with power-optimized performance.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works With Matter, Apple Home Kit & Google Assistant</li><li>The wireless SoCs are FCC, IC, & ETSI/CE certifiable</li><li>Dual external antenna</li><li> Wi-Fi stack, TCP/IP stack, IP modules, Bluetooth stack</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/wi-fi/rs9116-wi-fi-ncp-socs`,
        additionalMaterialType: "DEVELOPMENT KIT",
        additionalMaterialTitle: <span>RS9116X-SB-EVK1: RS9116X Single Band Wi-Fi + Bluetooth</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/wi-fi/rs9116x-sb-evk-development-kit`,
        filterLabels: ["Wi-Fi", "Bluetooth","Matter", "Apple HomeKit", "SmartThings","Google Assistant","Amazon Alexa"],
        isLink: true
      },
    ]
  },
  solutionModalData4: {
    infoSpot: "Safa",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "zigbee",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / ZIGBEE`,
    title: "Works With Zigbee",
    primaryContent: `We are the vendor of choice for IoT innovators developing Zigbee networking into their smart home & connected building ecosystems. We offer the largest selection of highly-integrated, & feature-rich Zigbee solutions available, easing development for OEMs.`,
    productHeadline: <span>Learn more about our solutions for Zigbee</span>,
    primaryImage: Safa,
    icon: zigbee,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Lights On, Volume Up with Sengled",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/solutions/case-studies/lights-on-volume-up-with-sengled.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Introduction of Zigbee Basic",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/introduction-of-zigbee-basic.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Building a Light Switch with Zigbee Green Power",
            cta: "READ NOW",
            assestLink: "https://pages.silabs.com/rs/634-SLU-379/images/Energy_Harvesting_Switch_Reference_Design.pdf",
            isPdf: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / ZIGBEE`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These capable SoCs include an 80 MHz ARM Cortex-M33 to bring powerful processing coupled with reliable communications for Zigbee, Thread, & multiprotocol applications for  greater range & reliability. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Enable Energy-Friendly Multiprotocol Networks for IoT Devices",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / ZIGBEE`,
        title: `Zigbee EFR32MG22 SoC`,
        description: <span>The SoC combines a 76.8 MHz MHz ARM Cortex-M33 with a high performance 2.4 GHz radio to provide an industry-leading, energy efficient, wireless SoC for IoT connected applications that support Zigbee</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, SmartThings & Alexa</li><li>Secure Boot with Root of Trust & Secure Loader (RTSL)</li><li>Up to 512 kB of flash & 32 kB of RAM </li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg22-series-2-socs`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40 </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Matter","Bluetooth", "Zigbee","Thread"],
        isLink: true
      },
      {
        productStatement: "Fully Integrated & Certified Module Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / ZIGBEE`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>The fully certified modules integrate all components (crystals, RF passives, & antenna) required for a system-level implementation of Zigbee, Thread & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Bluetooth", "Zigbee"],
        isLink: true
      },
    ]
  },
  solutionModalData5: {
    infoSpot: "Z-Wave",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "zwave",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / Z-WAVE`,
    title: "Works With Z-Wave & Z-Wave Long Range",
    primaryContent: `Z-Wave enables IoT product developers by leveraging an easy-to-use, secure & interoperable ecosystem of products. Z-Wave is backward compatible, regardless of product type, brand, manufacturer or version of the Z-Wave protocol stack. Our solutions provide industry-leading S2 security capabilities & improvements in energy efficiency, allowing a sensor to last 10 years on a coin-cell battery.`,
    productHeadline: <span>Learn more about our solutions for Z-Wave</span>,
    primaryImage: primaryZwave,
    icon: zwave,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Smart Home Alarm Meets Strict Standards with Z-Wave",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home-alarm-meets-strict-standards-with-z-wave.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Z-Wave 700: Unlocking Smart Home Potential",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/whitepaper-z-wave-700-unlocking-smart-home-potential.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Get Started with Z-Wave Long Range",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/440o2pujBOw",
            isVideo: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilites with Z-Wave Long-Range Module",
          breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / Z-WAVE`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>The ZGM130S is a baseband controller with a sub-GHz radio transceiver & crystal. Decoupling & matching are included to provide a complete Z-Wave SiP module requiring only an external antenna.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption<li>Up to 512 kB of flash & 64 kB of RAM</li></li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "SoC for IoT Gateways & Controllers Supporting Z-Wave Long Range",
          breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / Z-WAVE`,
        title: `EFR32ZG14 Z-Wave 700 Series SoC`,
        description: <span>This SoC features excellent radio sensitivity & features support for Z-Wave Long Range, enabling connected devices beyond the smart home with a range of up to 1 mile.  </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings </li><li>High processing power with 32-bit ARM® Cortex®-M4 core at 39 MHz</li><li>TX power +14 dBm, RX sensitivity -106 dBm at 100 kbps</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modem-soc/device.efr32zg14p231f256gm32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
    ]
  },
  solutionModalData6: {
    infoSpot: "Matter",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "matter",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / MATTER`,
    title: "Works With Matter",
    primaryContent: `Matter leverages existing IP technologies including Wi-Fi & Thread to build its connectivity ecosystem. IP-based networking simplifies development for manufacturers & increases compatibility for consumers. Silicon Labs offers a range of Matter compatible silicon, as well as development tools & expertise to bring high-performance Matter products to market quickly.`,
    productHeadline: <span>Learn more about our solutions for Matter</span>,
    primaryImage: primaryMatter,
    icon: matter,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Silicon Labs Backs New Matter Standard to Unify IoT Connectivity",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/silicon-labs-backs-new-matter-standard-to-unify-iot-connectivity.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Quick Start Guide Matter",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/quick-start-guides/matter-quick-start-guide.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Curriculum Series - Training Matter (former CHIP)",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/support/training/connected-home-over-ip-intro",
            isLink: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Fully-Integrated, Certified Modules Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / MATTER`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>Optimized for multiprotocol applications, these fully-certified modules integrate all components (crystals, RF passives, & antenna) required for a system-level implementation of Zigbee, Thread, & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["Amazon Alexa", "SmartThings", "Thread", "Zigbee","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / MATTER`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs include an 80 MHz ARM Cortex-M33  core & provide reliable communications for Zigbee, Thread, & multiprotocol applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread","Bluetooth", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },
  solutionModalData7: {
    infoSpot: "Rukn e yemeni",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "apple_homekit",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / APPLE HOMEKIT`,
    title: "Works With Apple HomeKit",
    primaryContent: `HomeKit is the Apple IoT ecosystem that lets you control your home automation devices from a single app on your iPhone. You can even use it to control your devices remotely. Users can connect to HomeKit via their iPhone, iPad, AppleTV, HomePod & HomePod Mini. HomeKit supports technologies like Homekit over WIFI, Homekit over BLE & Homekit over Thread.`,
    productHeadline: <span>Learn more about our solutions for Apple Homekit</span>,
    primaryImage: RukaneYamani,
    icon: homekit,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Works With 2020: HomeKit Technical Track",
            cta: "LEARN MORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/works-with-2020-homekit-technical-track.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Learn More About Thread",
            cta: "VIEW",
            assestLink: [1,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: <span>Learn More About <br/>Wi-Fi</span>,
            cta: "VIEW",
            assestLink: [2,2],
            isModal: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / APPLE HOMEKIT`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>Featuring an 80 MHz ARM Cortex-M33 core, these SoCs bring plenty of processing capability as well as reliable communications for Zigbee, Thread, & multiprotocol applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Enable Energy-Friendly Multiprotocol Networks for IoT Devices",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / APPLE HOMEKIT`,
        title: `Zigbee EFR32MG22 SoC`,
        description: <span>These SoCs combine a 76.8 MHz MHz ARM Cortex-M33 with a high-performance 2.4 GHz radio for industry-leading energy efficiency & an ideal platform for IoT applications that support Zigbee. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, SmartThings & Alexa</li><li>Secure Boot with Root of Trust & Secure Loader (RTSL)</li><li>Up to 512 kB of flash & 32 kB of RAM </li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg22-series-2-socs`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40 </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Bluetooth", "Zigbee"],
        isLink: true
      }, {
        productStatement: "Fully-Integrated & Certified Module Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / APPLE HOMEKIT`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>Optimized for applications that require multiple protocols, the fully certified EFR32MG12 integrates the necessary components for system-level implementation of Zigbee, Thread, & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["Amazon Alexa", "SmartThings","Bluetooth", "Thread", "Zigbee","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },
  solutionModalData8: {
    infoSpot: "SmartThings",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "smartthings",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / SMARTTHINGS`,
    title: "Works With Samsung SmartThings",
    primaryContent: `Samsung SmartThings is an open interface that allows wireless connection to a whole range of smart devices that can then work together. The SmartThings platform has one of the largest coverages of competitive IoT devices on the market that covers most smart home applications. The SmartThings Hub connects products with each other that support either Z-Wave, Zigbee or Wi-Fi.`,
    productHeadline: <span>Learn more about our solutions for Samsung SmartThings</span>,

    primaryImage: primarySmartThings,
    icon: smartthings,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Works With 2020: Samsung SmartThings Ecosystem",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/works-with-2020-samsung-smart-things-ecosystem.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Learn More About Z-Wave",
            cta: "VIEW",
            assestLink: [4,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Learn More About Zigbee",
            cta: "VIEW",
            assestLink: [3,2],
            isModal: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / SMARTTHINGS`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>This integrated baseband controller features a sub-GHz transceiver & crystal to provide decoupling & matching in a complete Z-Wave SiP module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / SMARTTHINGS`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs boast an 80 MHz ARM Cortex-M33  core, which provides plenty of processing capability & offers reliable communications for Zigbee, Thread & multiprotocol applications.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      }, {
        productStatement: "Enable Energy-Friendly Multiprotocol Networks for IoT Devices",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / SMARTTHINGS`,
        title: `Zigbee EFR32MG22 SoC`,
        description: <span>The SoC combines a 76.8 MHz MHz ARM Cortex-M33 with a high performance 2.4 GHz radio to provide an industry-leading, energy efficient, wireless SoC for IoT connected applications that support Zigbee.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, SmartThings & Alexa</li><li>Secure Boot with Root of Trust & Secure Loader (RTSL)</li><li>Up to 512 kB of flash & 32 kB of RAM </li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg22-series-2-socs`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40 </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["SmartThings","Alexa","Google Assistant","Apple Homekit","Bluetooth", "Zigbee"],
        isLink: true
      },
    ]
  },
  solutionModalData9: {
    infoSpot: "Amazon Sidewalk",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "amazon_sidewalk",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / AMAZON SIDEWALK`,
    title: "Works With Amazon Sidewalk",
    primaryContent: `Amazon Sidewalk is a shared network that helps devices like Amazon Echo, Ring Security Cameras, outdoor lights, & motion sensors work better.  Supports light & sensing applications as well as trackers & other emerging product categories. Supported products are all Amazon Echo & Ring products as well as devices from other brands that can run the Sidewalk BLE or Sub GHz FSK protocol.`,
    productHeadline: <span>Learn more about our solutions for Amazon Sidewalk</span>,
    primaryImage: primarySidewalk,
    icon: sidewalk,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Connect Your Home Beyond Your Front Door with Silicon Labs & Amazon Sidewalk",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-connect-your-home-beyond-your-front-door-with-silicon-labs-and-amazon-sidewalk.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Learn More About Bluetooth",
            cta: "VIEW",
            assestLink: [0,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Get In Touch",
            cta: "VIEW",
            assestLink: "https://www.silabs.com/about-us/contact-us",
            isLink: true
          }
        ]
      }
    ],
    productDetail:"CONTACT SALES"
  },
  solutionModalData10: {
    infoSpot: "Hajre Aswad",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "google_assistant",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / GOOGLE ASSISTANT`,
    title: "Works With Google Assistant",
    primaryContent: `The Google Assistant platform is designed to enable device manufacturers to build IoT devices to operate in their Smart Home Ecosystem, with a focus on making the home more connected & secure. Users can connect to all Google Home & Nest products that are available for several different smart home applications. Supported technologies are Wi-Fi, Thread and Bluetooth (BLE & BT Mesh).`,
    productHeadline: <span>Learn more about our solutions for Google Assistant</span>,

    primaryImage: HajreAswad,
    icon: assistant,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Works With 2020: CHIP Development with Thread & the Google Ecosystem",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/quick-start-guides/qsg170-openthread-sdk-quick-start-guide.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Learn More About Thread",
            cta: "VIEW",
            assestLink: [1,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: <span>Learn More About <br/>Wi-Fi</span>,
            cta: "VIEW",
            assestLink: [2,2],
            isModal: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Fully Integrated, Pre-Certified Module Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / GOOGLE ASSISTANT`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>These modules are optimized for multiprotocol applications & come fully certified with the necessary integrated components  (crystals, RF passives, & antenna)  for a system-level implementation of Zigbee, Thread & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["Amazon Alexa", "SmartThings","Bluetooth", "Thread", "Zigbee","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / GOOGLE ASSISTANT`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>These SoCs include a powerhouse 80 MHz ARM Cortex-M33 core & provides reliable communications for Zigbee, Thread, & multiprotocol applications that benefit from greater range & reliable RF communication.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["Amazon Alexa", "Thread", "Zigbee","SmartThings","Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
    ]
  },
  solutionModalData11: {
    infoSpot: "Maqam E Ibrahim",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "ring",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / RING`,
    title: "Works With Amazon Ring",
    primaryContent: `Ring has a program that allows 3rd party product vendors to connect to their Alarm & Security ecosystem. The Ring ecosystem predominantly connects to security devices like sensors, door locks or doorbell cameras that support either the Z-Wave protocol or Amazon Sidewalk. `,
    productHeadline: <span>Learn more about our solutions for Amazon Ring</span>,
    primaryImage: MaqameIbrahim,
    icon: ring,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Ring CEO Jamie Siminoff Joins the Works With House Party",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/ring-founder-jamie-siminoff-look-at-the-future-of-smart-home-development.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Learn More About Z-Wave",
            cta: "VIEW",
            assestLink: [4,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Get In Touch",
            cta: "VIEW",
            assestLink: "https://www.silabs.com/about-us/contact-us",
            isLink: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Extended Range Capabilities with Z-Wave Long Range Module",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / RING`,
        title: `ZGM130S Z-Wave 700 Series SiP Module`,
        description: <span>This integrated baseband controller features a sub-GHz transceiver & crystal to provide decoupling & matching in a complete Z-Wave SiP module requiring only an external antenna. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings</li><li>Highest level of IoT Security with Z-Wave S2 security & AES-128 bit encryption</li><li>Up to 512 kB of flash & 64 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modules/device.zgm130s037hgn`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
      {
        productStatement: "SoC for IoT Gateways & Controllers Enabling Z-Wave Long Range",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / RING`,
        title: `EFR32ZG14 Z-Wave 700 Series SoC`,
        description: <span>This SoC features excellent radio sensitivity & features support for Z-Wave Long Range, enabling connected devices beyond the smart home with a range of up to 1 mile.  </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Ring & Samsung SmartThings </li><li>High processing power with 32-bit ARM® Cortex®-M4 core at 39 MHz</li><li>TX power +14 dBm, RX sensitivity -106 dBm at 100 kbps</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/z-wave/700-series-modem-soc/device.efr32zg14p231f256gm32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6050B: Z-Wave Long Range 700</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/z-wave/z-wave-long-range-700-starter-kit`,
        filterLabels: ["Z-Wave", "Ring", "SmartThings"],
        isLink: true
      },
    ]
  },
  solutionModalData12: {
    infoSpot: "Green Light",
    hotspotType: "product",
    hotspotRoom: "protocols_and_ecosystems",
    hotspotProduct: "amazon_alexa",
    breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / AMAZON ALEXA`,
    title: "Works With Alexa",
    primaryContent: `Works with Alexa is a certification program designed to raise the bar for home IoT & provide the best smart home experience. Amazon Alexa enables the connection to all Amazon Echo products as well as other smart home devices that support either Zigbee, BLE or Amazon Sidewalk.`,
    productHeadline: <span>Learn more about our solutions for Alexa</span>,
    primaryImage: GreenLight,
    icon: alexa,
    filterLabels: [],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Works With 2020: Amazon Alexa Zigbee & Bluetooth Development",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/alexa-tools-for-device-developers.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: <span>Learn More About <br/>Zigbee</span>,
            cta: "VIEW",
            assestLink: [3,2],
            isModal: true
          }
        ]
      },
      {
        headline: "How To Get Started",
        content: [
          {
            title: <span>Learn More About <br/>Wi-Fi</span>,
            cta: "VIEW",
            assestLink: [2,2],
            isModal: true
          }
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Versatile SoC for Zigbee, Thread, & Multiprotocol Connectivity",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / AMAZON ALEXA`,
        title: `Zigbee & Thread EFR32MG21 SoCs`,
        description: <span>SoCs include an 80 MHz ARM Cortex-M33 to deliver all the processing power needed to develop reliable Zigbee, Thread, & multiprotocol applications. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alexa</li><li>Dedicated security core for faster encryption,  secure boot loading & debug access control</li><li>Up to 1024 kB of flash & 96 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg21-series-2-socs/device.efr32mg21a020f1024im32`,
        additionalMaterialType: "STARTER KIT",
        additionalMaterialTitle: <span>SLWSTK6006A: EFR32xG21 Wireless Gecko</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/efr32xg21-wireless-starter-kit`,
        filterLabels: ["SmartThings", "Amazon Alexa", "Thread", "Zigbee", "Google Assistant","Apple Homekit","Matter"],
        isLink: true
      },
      {
        productStatement: "Enable Energy-Friendly Multiprotocol Networks for IoT Devices",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / AMAZON ALEXA`,
        title: `Zigbee EFR32MG22 SoC`,
        description: <span>The SoC combines a 76.8 MHz MHz ARM Cortex-M33 with a high performance 2.4 GHz radio to provide an industry-leading, energy efficient, wireless SoC for IoT connected applications that support Zigbee</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, SmartThings & Alexa</li><li>Secure Boot with Root of Trust & Secure Loader (RTSL)</li><li>Up to 512 kB of flash & 32 kB of RAM </li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg22-series-2-socs`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40 </span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Bluetooth", "Zigbee"],
        isLink: true
      }, {
        productStatement: "Fully Integrated & Certified Module Supporting Multiple Protocols",
        breadCrum: `HOMES / PROTOCOLS & ECOSYSTEMS / AMAZON ALEXA`,
        title: `Zigbee & Thread EFR32MG12 Module`,
        description: <span>These fully certified modules integrate all components (crystals, RF passives, & antenna) required for a system-level implementation of Zigbee, Thread, & Bluetooth networks.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Works with Apple HomeKit, Google Assistant & Alex</li><li>Robust peripheral set & up to 25 GPIO</li><li>12-channel Peripheral Reflex System</li><li>Up to 1024 kB of flash & 256 kB of RAM</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/zigbee/efr32mg12-series-1-modules`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4304A: MGM12P Zigbee and Thread Module +17 dBm</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/zigbee/slwrb4304a-mgm12p-module-radio-board`,
        filterLabels: ["SmartThings","Amazon Alexa","Google Assistant","Apple Homekit","Matter","Bluetooth", "Thread", "Zigbee"],
        isLink: true
      },
    ]
  },
}


let Kabah = {
  solutionModalData1: {
    infoSpot: "GreenLight",
    hotspotType: "product",
    hotspotRoom: "health",
    hotspotProduct: "smart_toothbrush",
    breadCrum: `HOMES / HEALTH / SMART TOOTHBRUSH`,
    title: "Improve Oral Health with Smart Toothbrushes",
    primaryContent: `Smart Toothbrushes connect to an App to track, monitor & alarm users about their brushing habits. Users require an easy to set-up device that can connect seamlessly to multiple different phones in the same household. Silicon Labs products address the need for low power & small form factor SoCs & modules that establish a reliable Bluetooth connection.`,
    productHeadline: <span>Learn more about our solutions for Smart Toothbrushes</span>,
    primaryImage: GreenLight,
    icon: toothbrush,
    filterLabels: ["Bluetooth", "Stand-Alone"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "IoT Hero Develops Smart Toothbrush",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-post-iot-hero-develops-smart-toothbrush-to-improve-dental-health-and-lower-insurance-premiums.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Benefits of a Smart Toothbrush",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/vqdzzCkRWuw",
            isVideo: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Key Design Considerations When Developing a Smart Toothbrush",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/rCbo6YmGl7o",
            isVideo: true
          },
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Bluetooth Low Energy SoC Connects Small Appliances to Smartphone Apps",
        breadCrum: `HOMES / HEALTH / SMART TOOTHBRUSH`,
        title: `EFR32BG22 Series 2 Bluetooth SoC`,
        description: <span>Wireless BG22 SoCs include a 76.8 MHz ARM Cortex-M33 core & integrated security features for fast encryption, secure boot loading, & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 352 kB of flash & 32 kB of RAM</li><li>Energy-efficient radio core with Bluetooth 5.2 low active & sleep currents</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-socs/device.efr32bg22c222f352gm32`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      },
      {
        productStatement: "Pre-certified Modules Speed Development of Smart Toothbrushes",
        breadCrum: `HOMES / HEALTH / SMART TOOTHBRUSH`,
        title: `EFR32BGM22 Series 2 Modules`,
        description: <span>Develop fast, cost-effective smart toothbrushes with the pre-certified, antenna-integrated BGM22 module. Integrated security features provide fast encryption, secure boot loading & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Up to 8 dBm TX power & -98.9 dBm BLE RX sensitivity at 1 Mbps</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220pc22hna`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz +6 Output Power</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      }
    ]
  },

  solutionModalData2: {
    infoSpot: "Insulin Pump",
    hotspotType: "product",
    hotspotRoom: "health",
    hotspotProduct: "insulin_pump",
    breadCrum: `HOMES / HEALTH / INSULIN PUMP`,
    title: "Low Power Connected Insulin Pumps",
    primaryContent: `Portable & wireless insulin pumps require robust IoT device security, a small form-factor, & high energy-efficiency to enable accurate & safe operation. The layout design of our EFR32 Series 2 chipset minimizes the power consumption while providing a secure, PSA level 3 certified Bluetooth connectivity to the smart-phone with Secure Vault.`,
    productHeadline: <span>Learn more about our solutions for Insulin Pumps</span>,
    primaryImage: primaryInsulin,
    icon: insulin,
    filterLabels: ["Bluetooth", "Stand-Alone"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Innovative Leaders in the Smart Insulin Pump Market",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/Vxaz812zqtI",
            isVideo: true
          },
          {
            title: "Market Opportunity for Smart Insulin Pumps",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/NtI1BOlbMvo",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Smart Medical Devices are Here to Stay – Securing them is Critical",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-smart-medical-devices-are-here-to-stay.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Design Secure Bluetooth Connected Insulin Pumps with BG22",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/insulin-pump-overview.pdf",
            isPdf: true
          },
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Bluetooth Low Energy SoC Connects Insulin Pumps to Smartphone Apps",
        breadCrum: `HOMES / HEALTH / INSULIN PUMP`,
        title: `EFR32BG22 Series 2 Bluetooth SoC`,
        description: <span>The BG22 SoC features  a 76.8 MHz ARM Cortex-M33 along with superior integrated security features for fast encryption, secure boot loading, & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 352 kB of flash & 32 kB of RAM</li><li>Energy-efficient radio core with Bluetooth 5.2 low active & sleep currents</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-socs/device.efr32bg22c222f352gm32`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      },
      {
        productStatement: "Pre-certified Modules Speed up Development of Connected Insulin Pumps",
        breadCrum: `HOMES / HEALTH / INSULIN PUMP`,
        title: `EFR32BGM22 Series 2 Modules`,
        description: <span>Develop fast, cost-effective insulin pumps with the pre-certified, antenna-integrated BGM22 module. Robust security features provide fast encryption, secure boot loading, & a debug access control. </span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Up to 8 dBm TX power & -98.9 dBm BLE RX sensitivity at 1 Mbps</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220pc22hna`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz +6 Output Power</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      }
    ]
  },
  solutionModalData3: {
    infoSpot: "Blood Glucose Monitoring",
    hotspotType: "product",
    hotspotRoom: "health",
    hotspotProduct: "blood_glucose_monitoring",
    breadCrum: `HOMES / HEALTH / BLOOD GLUCOSE MONITORING`,
    title: "Accurate, Secure, & Low-Power Glucose Monitoring",
    primaryContent: `Blood glucose monitoring devices track a patient’s physiological conditions continuously & share health data via a Bluetooth connection to a smart-phone app. This makes security a critical design consideration & Silicon Labs Bluetooth solution is secured at all levels to safeguard devices against logical, physical, & wireless attacks.`,
    productHeadline: <span>Learn more about our solutions for Blood Glucose Monitoring</span>,
    primaryImage: primaryGlucose,
    icon: glucose,
    filterLabels: ["Stand-Alone", "Bluetooth"],
    crousalData: [
      {
        headline: "Use Case",
        content: [
          {
            title: "Market Opportunity for Portable Blood Glucose Monitoring",
            cta: "WATCH NOW",
            assestLink: "https://www.youtube.com/embed/hZEOTpo8i4Q",
            isVideo: true
          }
        ]
      },
      {
        headline: "Benefits",
        content: [
          {
            title: "Smart Medical Devices are Here to Stay – Securing them is Critical",
            cta: "READ NOW",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blog-smart-medical-devices-are-here-to-stay.pdf",
            isPdf: true
          }
        ]
      },
      {
        headline: "How to Get Started",
        content: [
          {
            title: "Design Secure Bluetooth Connected BGM/CGM with BG22",
            cta: "EXPLORE",
            assestLink: "https://www.silabs.com/documents/public/miscellaneous/smart-home/blood-glucose-monitoring-overview.pdf",
            isPdf: true
          },
        ]
      }
    ],
    productDetail: [
      {
        productStatement: "Bluetooth Low Energy SoC Connects BGM Devices to Smartphone Apps",
        breadCrum: `HOMES / HEALTH / BLOOD GLUCOSE MONITORING`,
        title: `EFR32BG22 Series 2 Bluetooth SoC`,
        description: <span>Wireless BG22 SoCs include a 76.8 MHz ARM Cortex-M33 core & integrated security features for fast encryption, secure boot loading, & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 352 kB of flash & 32 kB of RAM</li><li>Energy-efficient radio core with Bluetooth 5.2 low active & sleep currents</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-socs/device.efr32bg22c222f352gm32`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4182A: EFR32xG22 Wireless Gecko 2.4 GHz +6 dBm 5x5, QFN40</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/slwrb4182a-efr32xg22-wireless-gecko-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      },
      {
        productStatement: "Pre-certified Modules Accelerate Development of Bluetooth Applications",
        breadCrum: `HOMES / HEALTH / BLOOD GLUCOSE MONITORING`,
        title: `EFR32BGM22 Series 2 Modules`,
        description: <span>Develop fast, cost-effective smart toothbrushes with the pre-certified, antenna-integrated BGM22 module. Integrated security features provide fast encryption, secure boot loading & a debug access control.</span>,
        keyFeatures: <ul class="color-gray pl-20"><li>Up to 512 kB of flash & 32 kB of RAM</li><li>Built-in antenna</li><li>Up to 8 dBm TX power & -98.9 dBm BLE RX sensitivity at 1 Mbps</li><li>Highest level of IoT Security - PSA L3 certified Secure Vault</li></ul>,
        productPageLink: `https://www.silabs.com/wireless/bluetooth/efr32bg22-series-2-modules/device.bgm220pc22hna`,
        additionalMaterialType: "RADIO BOARD",
        additionalMaterialTitle: <span>SLWRB4312A: BGM220SC22 Bluetooth SiP Module 2.4 GHz +6 Output Power</span>,
        cta: `BUY NOW`,
        linkToAsset: `https://www.silabs.com/development-tools/wireless/bluetooth/slwrb4312a-bgm220sc22-bluetooth-module-radio-board`,
        filterLabels: ["Bluetooth", "Stand-Alone"],
        isLink: true
      }
    ]
  }
}


const panoViews = [
  {
    panoImage: screen,
    name: "Entrance",
    initialRotation: [90, 0],
    infoSpots: [
      // { position: new THREE.Vector3(-100, -28, -21), solutionModelData: entrance.solutionModalData1 },
      { position: new THREE.Vector3(-200, 15, 146), solutionModelData: entrance.solutionModalData2 },
      // { position: new THREE.Vector3(20, -10, 500), solutionModelData: entrance.solutionModalData3 },
    ],
    links: [
      {
        name: "Corridor",
        hotspotRoom: "home_automation",
        linkTo: 1,
        position: new THREE.Vector3(-200, 0, 0),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      }
    ],
  },
  {
    panoImage: screen1,
    name: "Corridor",
    initialRotation: [90, 0],
    infoSpots: [
      // { position: new THREE.Vector3(75, -120, 300), solutionModelData: kitchen.solutionModalData1 },
      // { position: new THREE.Vector3(-150, 50, 400), solutionModelData: kitchen.solutionModalData2 },
      // { position: new THREE.Vector3(-600, 160, 130), solutionModelData: kitchen.solutionModalData3 },
      // { position: new THREE.Vector3(-200, 20, -15), solutionModelData: kitchen.solutionModalData4 },
      // { position: new THREE.Vector3(-65, -7, -22), solutionModelData: kitchen.solutionModalData5 },
      // { position: new THREE.Vector3(35, -40, -300), solutionModelData: kitchen.solutionModalData6 },
      // { position: new THREE.Vector3(600, -90, -165), solutionModelData: kitchen.solutionModalData7 },
      // { position: new THREE.Vector3(600, -130, -30), solutionModelData: kitchen.solutionModalData8 },
    ],
    links: [
      {
        name: "Bab E Fahad",
        hotspotRoom: "home_security",
        linkTo: 0,
        position: new THREE.Vector3(1000, 0, -20),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
      {
        name: "Green light",
        hotspotRoom: "protocols_and_ecosystems",
        linkTo: 3,
        position: new THREE.Vector3(-960, 0, 0),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
    ],
  },
  {
    panoImage: screen3,
    name: "Green Lights Safa",
    initialFov: THREE.MathUtils.clamp(100, 10, 75),
    initialRotation: [-100, -10],
    infoSpots: [
      // { position: new THREE.Vector3(-400, -100, 120), solutionModelData: office.solutionModalData1 },
      // { position: new THREE.Vector3(-400, -70, 70), solutionModelData: office.solutionModalData2 },
      // { position: new THREE.Vector3(-400, -105, 30), solutionModelData: office.solutionModalData3 },
      // { position: new THREE.Vector3(-400, -77, -15), solutionModelData: office.solutionModalData4 },
      // { position: new THREE.Vector3(-400, -117, -65), solutionModelData: office.solutionModalData5 },
      // { position: new THREE.Vector3(-400, -90, -115), solutionModelData: office.solutionModalData6 },
      // { position: new THREE.Vector3(23, -8, -175), solutionModelData: office.solutionModalData7 },
      // { position: new THREE.Vector3(100, -15, -300), solutionModelData: office.solutionModalData8 },
      // { position: new THREE.Vector3(300, -28, -540), solutionModelData: office.solutionModalData9 },
      // { position: new THREE.Vector3(6, -11.5, -50), solutionModelData: office.solutionModalData10 },
      // { position: new THREE.Vector3(17.5, -13.5, -53), solutionModelData: office.solutionModalData11 },
      // { position: new THREE.Vector3(31, -15, -54), solutionModelData: office.solutionModalData12 },
    ],
    links: [
      {
        name: "Green Light",
        hotspotRoom: "health",
        linkTo: 3,
        position: new THREE.Vector3(120, 0, -60),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
      {
        name: "Safa",
        hotspotRoom: "home_security",
        linkTo: 5,
        position: new THREE.Vector3(200, 5, -10),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },

            {
        name: "Marwa",
        hotspotRoom: "home_security",
        linkTo: 4,
        position: new THREE.Vector3(-1200, 40, 20),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
    ],
  },

  {
    panoImage: screen2,
    name: "Green Light",
    initialFov: THREE.MathUtils.clamp(300, 10, 75),
    initialRotation: [100, 0],
    infoSpots: [
      { position: new THREE.Vector3(100, 12, 190), solutionModelData: Kabah.solutionModalData1 },
      // { position: new THREE.Vector3(-420, -280, -450), solutionModelData: bathroom.solutionModalData2 },
      // { position: new THREE.Vector3(-420, -370, -700), solutionModelData: bathroom.solutionModalData3 }
    ],
    links: [
      {
        name: "Green Lights Safa",
        hotspotRoom: "protocols_and_ecosystems",
        linkTo: 2,
        position: new THREE.Vector3(-150, 0, -800),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
      {
        name: "Bab E Fahad",
        hotspotRoom: "home_security",
        linkTo: 0,
        position: new THREE.Vector3(-950, 40, 550),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },

      // {
      //   name: "Safa",
      //   hotspotRoom: "home_security",
      //   linkTo: 5,
      //   position: new THREE.Vector3(-150, 0, -800),
      //   scale: new THREE.Vector3(3, 3, 1),
      //   rotation: new THREE.Vector3(0, 0, 0),
      // },

      {
        name: "Mataaf",
        hotspotRoom: "home_security",
        linkTo: 7,
        position: new THREE.Vector3(-1000, -100, 270),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
    ],
  },

  {
    panoImage: screen4,
    name: "Marwa",
    initialFov: THREE.MathUtils.clamp(100, 10, 75),
    initialRotation: [90, 0],
    infoSpots: [
      { position: new THREE.Vector3(-470, -20, 10), solutionModelData: office.solutionModalData1 },
      // { position: new THREE.Vector3(-420, -280, -450), solutionModelData: office.solutionModalData2 },
      // { position: new THREE.Vector3(-420, -370, -700), solutionModelData: office.solutionModalData3 }
    ],
    links: [
      {
        name: "Green Lights Safa",
        hotspotRoom: "protocols_and_ecosystems",

        linkTo: 2,
        position: new THREE.Vector3(-20, 0, 150),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      }
    ],
  },
    
  {
    panoImage: screen5,
    name: "Safa",
    initialFov: THREE.MathUtils.clamp(100, 10, 75),
    initialRotation: [90, 0],
    infoSpots: [
      { position: new THREE.Vector3(-470, 0, 270), solutionModelData: office.solutionModalData4 },
      // { position: new THREE.Vector3(-420, -280, -450), solutionModelData: office.solutionModalData5 },
      // { position: new THREE.Vector3(-420, -370, -700), solutionModelData: office.solutionModalData6 }
    ],
    links: [
      {
        name: "Green Lights Safa",
        hotspotRoom: "protocols_and_ecosystems",
        linkTo: 2,
        position: new THREE.Vector3(900, 50, -600),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      }
    ],
  },

  {
    panoImage: screen6,
    name: "Rukn e yemeni",
    initialFov: THREE.MathUtils.clamp(100, 10, 75),
    initialRotation: [90, 0],
    infoSpots: [
      { position: new THREE.Vector3(-1000, -150, -10), solutionModelData: office.solutionModalData7  },
      // { position: new THREE.Vector3(-420, -280, -450), solutionModelData: office.solutionModalData8 },
      // { position: new THREE.Vector3(-420, -370, -700), solutionModelData: office.solutionModalData9 }
    ],
    links: [
      {
        name: "Mataaf",
        hotspotRoom: "home_security",
        linkTo: 7,
        position: new THREE.Vector3(-1000, -100, 900),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
    ],
  },

  {
    panoImage: screen7,
    name: "Mataaf",
    initialFov: THREE.MathUtils.clamp(100, 10, 75),
    initialRotation: [90, 0],
    infoSpots: [
      { position: new THREE.Vector3(-950, -40, 10), solutionModelData: office.solutionModalData10  },
      { position: new THREE.Vector3(-1300, -90, -1100), solutionModelData: office.solutionModalData11 },
      // { position: new THREE.Vector3(150, 10, -80), solutionModelData: office.solutionModalData12 }
    ],
    links: [
      {
        name: "Rukn e yemeni",
        hotspotRoom: "home_security",
        linkTo: 6,
        position: new THREE.Vector3(-1200, 0, -450),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
      {
        name: "Kabah (Extended View)",
        hotspotRoom: "health",
        linkTo: 3,
        position: new THREE.Vector3(150, -15, -80),
        scale: new THREE.Vector3(3, 3, 1),
        rotation: new THREE.Vector3(0, 0, 0),
      },
    ],
  }
];

export default panoViews;
