// src/components/ImageSlider.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from '../modal';
import './style.scss';
import image1 from '../../../assets/images/slider-images/image_011_0000.jpg';
import image2 from '../../../assets/images/slider-images/image_013_0000.jpg';
import image3 from '../../../assets/images/slider-images/image_027_0000.jpg';
import image4 from '../../../assets/images/slider-images/image_018_0000.jpg';
import image5 from '../../../assets/images/slider-images/image_033_0000.jpg';
import image6 from '../../../assets/images/slider-images/image_044_0000.jpg';
import image7 from '../../../assets/images/slider-images/image_035_0000.jpg';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', backgroundColor: '',color:'red', right: '-40px', zIndex: 0 }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', backgroundColor: '', left: '-40px', zIndex: 0 }}
      onClick={onClick}
    />
  );
};

const ImageSlider = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
  ];

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage(null);
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide" onClick={() => handleImageClick(image)}>
            <img src={image} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
      <Modal isOpen={isModalOpen} image={currentImage} onClose={closeModal} />
    </div>
  );
};

export default ImageSlider;
